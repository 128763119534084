<template>
  <div class="InAnalysis InAnalysis-isreport">
    <div class="InAnalysis-top">
      <div class="InAnalysis-title">
        <p class="p1">跟评信息 综合分析</p>
        <p class="p2">{{timersArr[0]}} 至 {{timersArr[1]}}</p>
      </div>
      <div class="InAnalysis-trend" v-loading="echarts1Load">
        <div class="InAnalysis-trend-new" v-if="echartsList.length > 0">
          <div class="InAnalysis-top-title-new">
            <img src="../../assets/img/views/topqq.png" />
            <!-- <span>统计周期内每日跟评走势及方案信息数量图</span> -->
            <div style="cursor: pointer;">
              <!-- <img style="margin-left:24px" src="../../assets/img/views/monitoringScheme/xiazai.png" /> -->
              <!-- <span style="font-size: 14px;font-weight: bold;color: #2E59EC;" >下载</span> -->
            </div>
          </div>
          <div id="echarts1"></div>
        </div>
        <div class="analyse-container" v-else>
          <div class="analyse-container-img">
            <img src="../../assets/img/views/analyse-img.png" />
            <p>暂无数据</p>
          </div>
        </div>
      </div>
    </div>
    <div class="InAnalysis-trend" v-loading="echarts2Load">
      <div class="InAnalysis-trend-new" v-if="echartsList2.length>0">
        <div class="InAnalysis-top-title-new">
          <img src="../../assets/img/views/topqq.png" />
        </div>
        <div id="echarts2"></div>
      </div>
      <div class="analyse-container" v-else style="height: 300px;">
        <div class="analyse-container-img">
          <img src="../../assets/img/views/analyse-img.png" />
          <p>暂无数据</p>
        </div>
      </div>
    </div>

    <div class="InAnalysis-trend" id="isReport" v-loading="echarts3Load">
      <div style="justify-content: space-between;" class="InAnalysis-top-title">
        <div style="display: flex;align-items: center;">
          <img src="../../assets/img/views/topqq.png" />
          <span>【敏感信息】方案统计周期内发评人发布数量TOP10</span>
          <div style="font-size: 14px;color: #666666;margin-left:24px">
            平台数量
            <span style="color:#2E59EC">{{tableHeadData&&tableHeadData.length>=2 ? tableHeadData.length-2:"0"}}</span> 个
          </div>
        </div>
        <div style="font-size: 14px;color: #333333;">
          监测方案
          <el-select
            style="width:126px;margin:0 0 0 20px"
            v-model="value"
            @change="selectmonitorId"
            placeholder="请选择"
          >
            <el-option
              v-for="item in options"
              :key="item.monitorId"
              :label="item.projectName"
              :value="item.monitorId"
            ></el-option>
          </el-select>
          <!-- <el-button
            type="primary"
            size="medium"
            :disabled="downLoad"
            @click="ExportTable"
          >{{downLoad?"正在导出...":"导出报告"}}</el-button>-->
        </div>
      </div>
      <el-table v-if="tableData&&tableData.length>0" stripe :row-class-name="xxxTableRowClassName" :header-cell-style="headerStyle" :data="tableData" style="width: 100%;margin-top:25px">
        <el-table-column width="80px" align="center" type="index" label="序号"></el-table-column>
        <el-table-column v-for="(item,index) in tableHeadData" :prop="item.prop" :label="item.head" align="center" :key="index"></el-table-column>
      </el-table>
      <div class="analyse-container" v-else style="height: 300px;">
        <div class="analyse-container-img">
          <img src="../../assets/img/views/analyse-img.png" />
          <p>暂无数据</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import html2canvas from "html2canvas";
// import { jsPDF } from "jspdf"; //pdf插件
import {
  gpDayTrendAndNum,
  gpDayPlatformAndNum,
  gpDayPlatformReviewer,
  gpMonitorList
} from "../../api/analyse/index";
export default {
  props: {
    timetype: {
      //时间类型  近三天/近七天..
      type: Number
    },
    timers: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      picInfoArr: [], // pDF数组
      timersArr: [],
      organId: this.$store.state.user.organizationId,
      value: null,
      options: [
        {
          value: "1",
          label: "敏感信息"
        }
      ],
      tableData: [],
      tableHeadData: [],
      headerStyle: {
        background: "#F5F6FA",
        color: "#333333",
        fontSize: "14px"
      },
      echartsList: [],
      echartsList2: [],
      // 控制导出样式
      downLoad: false,
      echarts1Load: false,
      echarts2Load: false,
      echarts3Load: false
    };
  },
  created() {
    this.gpMonitorList();
  },
  methods: {
    allFunction() {
      this.echarts1();
      this.echarts2();
      this.gpDayPlatformReviewer();
    },
    // 时间函数
    timersFn() {
      this.timersArr = this.timers;
    },
    xxxTableRowClassName({ rowIndex }) {
      if (rowIndex % 2 == 0) {
        return "statistics-warning-row";
      } else {
        return "";
      }
    },
    // 判断时间 公共函数
    addObjtime(data) {
      data.startTimeStr = this.timers[0];
      data.endTimeStr = this.timers[1];
      return data;
    },
    //周期内每日跟评走势及方案信息数量
    async echarts1() {
      this.echarts1Load = true;
      let item = {
        organId: this.organId
      };
      let res = await gpDayTrendAndNum(this.addObjtime(item));
      let data = [];
      if (res.data.data && res.data.data.length != 0) {
        data = res.data.data;
        this.echartsList = res.data.data;
        setTimeout(() => {
          this.echarts1Data(data);
        }, 500);
      } else {
        if (document.getElementById("echarts1")) {
          echarts.dispose(document.getElementById("echarts1"));
        }
      }
      this.echarts1Load = false;
    },
    echarts1Data(data) {
      let myChart = echarts.init(document.getElementById("echarts1"));
      let option = {
        title: {
          text: "统计周期内每日跟评走势及方案信息数量图",
          left: "2%",
          textStyle: {
            fontSize: 16
          }
        },
        tooltip: {
          trigger: "axis"
        },
        toolbox: {
          feature: {
            saveAsImage: {
              iconStyle: {
                // color:'red',
                borderColor: " #2E59EC"
              }
            }
          },
          top: "-1%",
          left: "23%"
        },
        dataZoom: [
          {
            type: "inside"
          },
          {
            left: "7%",
            right: "8%"
          }
        ],
        legend: {
          show: true,
          top: 16,
          itemHeight: 6,
          itemWidth: 6,
          itemGap: 60,
          data: [
            {
              name: "方案信息",
              icon: "circle",
              textStyle: {
                color: "#5A6474",
                fontSize: 14
              }
            },
            {
              name: "每日跟评走势",
              icon: "circle",
              textStyle: {
                color: "#5A6474",
                fontSize: 14
              }
            }
          ]
        },
        grid: {
          left: 40,
          right: "1%",
          bottom: 50,
          containLabel: true
        },

        xAxis: {
          type: "category",
          data: data.map(v => v.showTime),
          borderColor: "#D8D8D8",
          axisLine: {
            lineStyle: {
              color: "#D8D8D8"
            }
          },
          axisTick: {
            show: false
          }
        },
        yAxis: {
          type: "value",
          axisLine: {
            lineStyle: {
              color: "#CCCCCC",
              fontSize: 12
            }
          },
          splitLine: {
            lineStyle: {
              color: "#E5E5E5",
              type: "dashed"
            }
          },
          minInterval: 1, //将刻度的最小间距设置为1
          boundaryGap: ["0%", "50%"]
        },
        series: [
          {
            name: "每日跟评走势",
            type: "bar",
            symbol: "none",
            barWidth: 6,
            itemStyle: {
              color: "#FF5670"
            },
            data: data.map(v => v.dayGpNum)
          },
          {
            name: "方案信息",
            type: "bar",
            symbol: "none",
            stack: "正",
            itemStyle: {
              color: "#5BE6D9"
            },
            barWidth: 6,
            data: data.map(v => v.dayFaaNum)
          }
        ]
      };
      myChart.setOption(option);
      let picInfo;
      let _this = this;
      myChart.on("finished", function() {
        picInfo = myChart.getDataURL();
        _this.picInfoArr[0] = picInfo;
      });
    },
    // 周期内各监测平台跟评数量及方案信息数量图
    async echarts2() {
      this.echarts2Load = true;
      let item = {
        organId: this.organId
      };
      let res = await gpDayPlatformAndNum(this.addObjtime(item));
      let data = [];
      if (res.data.data && res.data.data.length != 0) {
        data = res.data.data;
        this.echartsList2 = res.data.data;
        setTimeout(() => {
          this.echarts2Data(data);
        }, 500);
      } else {
        if (document.getElementById("echarts2")) {
          echarts.dispose(document.getElementById("echarts2"));
        }
      }
      this.echarts2Load = false;
    },
    echarts2Data(data) {
      let myChart = echarts.init(document.getElementById("echarts2"));
      let option = {
        title: {
          text: "统计周期内各监测平台跟评数量及方案信息数量图",
          left: "2%",
          textStyle: {
            fontSize: 16
          }
        },
        tooltip: {
          trigger: "axis"
        },
        toolbox: {
          feature: {
            saveAsImage: {
              iconStyle: {
                borderColor: " #2E59EC"
              }
            }
          },
          top: "-1%",
          left: "26%"
        },
        grid: {
          left: 16,
          right: "1%",
          bottom: 10,
          containLabel: true
        },
        legend: {
          show: true,
          top: 16,
          itemHeight: 6,
          itemWidth: 6,
          itemGap: 60,
          data: [
            {
              name: "每日跟评数量",
              icon: "circle",
              textStyle: {
                color: "#5A6474",
                fontSize: 14
              }
            },
            {
              name: "方案信息",
              icon: "circle",
              textStyle: {
                color: "#5A6474",
                fontSize: 14
              }
            }
          ]
        },
        xAxis: {
          type: "category",
          data: data.map(v => v.mediaPlatform),
          borderColor: "#D8D8D8",
          axisLine: {
            lineStyle: {
              color: "#D8D8D8"
            }
          },
          axisTick: {
            show: false
          }
        },
        yAxis: {
          type: "value",
          axisLine: {
            lineStyle: {
              color: "#CCCCCC",
              fontSize: 12
            }
          },
          splitLine: {
            lineStyle: {
              color: "#E5E5E5",
              type: "dashed"
            }
          },
          minInterval: 1, //将刻度的最小间距设置为1
          boundaryGap: ["0%", "50%"]
        },
        series: [
          {
            name: "每日跟评数量",
            type: "bar",
            symbol: "none",
            stack: "正",
            itemStyle: {
              color: "#FFAE45"
            },
            barWidth: 6,
            data: data.map(v => v.dayGpNum)
          },
          {
            name: "方案信息",
            type: "bar",
            symbol: "none",
            stack: "中",
            itemStyle: {
              color: "#2EC5B5"
            },
            barWidth: 6,
            data: data.map(v => v.dayFaaNum)
          }
        ]
      };
      myChart.setOption(option);
      let picInfo;
      let _this = this;
      myChart.on("finished", function() {
        picInfo = myChart.getDataURL();
        _this.picInfoArr[1] = picInfo;
      });
    },
    // 方案统计周期内发评人发布数量Top10
    async gpDayPlatformReviewer(value) {
      this.echarts3Load = true;
      let item = {
        monitorId: value ? value : this.value
      };
      let res = await gpDayPlatformReviewer(this.addObjtime(item));
      if(res.data.data&&res.data.data[0]){
        let data = [];
        let length = Object.keys(res.data.data).length;
        for(let i=0;i<length;i++){
          data[i] = res.data.data[i];
        }
        let tableHeadData = [];
        let tableData = [];
        data[0].forEach((x,index)=>{
          tableHeadData[index] = {
            head:x,
            prop:"value"+index
          }
        });
        for(let i=1;i<data.length;i++){
          let json = {};
          let num = 0;
          let length = 0;
          data[i].forEach((x,index)=>{
            json["value"+index] = x;
            if(index){
              num += x;
            }
            length++;
          });
          json["value"+length] = num;
          tableData.push(json);
        }
        this.tableHeadData = tableHeadData;
        this.tableData = tableData;
      }
      this.echarts3Load = false;
    },
    // 监测方案筛选下拉列表
    async gpMonitorList() {
      let res = await gpMonitorList({ organId: this.$store.state.user.organizationId});
      if (res.data.data.length > 0) {
        this.options = res.data.data;
        this.value = this.options[0].monitorId;
        this.gpDayPlatformReviewer(this.value);
      }
    },
    // 把图片导出为PDF
    getPdf() {
      let obj = {
        url: "api/msgquery/analysisBySynthesis/generateEvaluationReportStream",
        method: "post",
        data: {
          organizationId: this.$store.state.user.organizationId, //机构id
          organizationName: this.$store.state.user.mechanism, //机构名称
          startTime: this.timersArr[0], //开始时间
          endTime: this.timersArr[1], //结束时间
          reportType: "跟评",
          reportName: "跟评报告"
        },
        responseType: "blob"
      };
      let textName = "跟评报告.docx";
      if (this.picInfoArr[0]) {
        obj.data.viewPicture1 = this.picInfoArr[0];
      }
      if (this.picInfoArr[1]) {
        obj.data.viewPicture2 = this.picInfoArr[1];
      }
      if (this.tableData && this.tableData.length > 0) {
        this.$nextTick(() => {
          html2canvas(document.querySelector("#isReport")).then(resolve => {
            let imgUrl = resolve.toDataURL("image/png");
            obj.data.viewPicture3 = imgUrl;
            this.$parent.$parent.downloadFun(obj, textName);
          });
        });
      } else {
        this.$parent.$parent.downloadFun(obj, textName);
      }
    },
    // 监测方案切换表格
    selectmonitorId(value) {
      this.gpDayPlatformReviewer(value);
    },
    // 导出报告
    ExportTable() {
      this.downLoad = true;
      let obj = {
        method: "post",
        url: "/api/msgquery/analysisBySynthesis/gpDayPlatformReviewerExport",
        data: {
          monitorId: this.value
        },
        responseType: "blob"
      };
      let textName = "导出报告.xlsx";
      this.$parent.$parent.downloadFun(obj, textName);
      this.downLoad = false;
    }
  },
  mounted() {
    this.allFunction();
  }
};
</script>

<style lang='scss' scoped>
.InAnalysis {
  margin-top: 16px;
}

.InAnalysis-top {
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
  padding: 24px;
}

.InAnalysis-title {
  width: 100%;
  height: 122px;
  background: #f6fafe;
  border-radius: 4px;
  padding: 24px;
}

.InAnalysis-title .p1 {
  font-size: 24px;
  font-weight: bold;
  color: #333333;
  text-align: center;
}

.InAnalysis-title .p2 {
  font-size: 18px;
  color: #666;
  text-align: center;
  margin-top: 16px;
  font-weight: normal;
}

.InAnalysis-statistical-table {
  width: 100%;
  margin-top: 24px;
}

.InAnalysis-trend-new {
  position: relative;
}

.InAnalysis-top-title-new {
  position: absolute;
  top: 5px;
}

.InAnalysis-top-title {
  display: flex;
  align-items: center;
}

.InAnalysis-top-title img {
  position: relative;
  top: 2px;
  margin-right: 5px;
}

.InAnalysis-top-title span {
  font-size: 16px;
  color: #333333;
  font-weight: bold;
}

.InAnalysis-tab {
  width: 100%;
  padding: 20px;
  display: flex;
  cursor: pointer;
  font-weight: bold;
}

.InAnalysis-tab-card {
  padding: 7px 19px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #e1e1e1;
  font-size: 16px;
  color: #333333;
  margin-right: 24px;
}

.InAnalysis-tab-card.active {
  background: #e9f4ff;
  border-radius: 4px;
  border-color: transparent;
  color: #2e59ec;
}

.InAnalysis-link {
  padding-left: 20px;
  font-size: 14px;
  color: #666666;
}

.InAnalysis-link-span {
  font-size: 14px;
  color: #2e59ec;
}

.InAnalysis-table {
  width: 976px;
  height: 420px;
  margin-left: 20px;
  margin-top: 16px;
  border: 1px solid #d8d8d8;
  font-size: 14px;
  font-weight: bold;
  color: #333333;
  float: left;
}

.InAnalysis-table-header {
  width: 100%;
  height: 81px;
  display: flex;
}

.InAnalysis-table-body {
  width: 100%;
  height: 335px;
}

.InAnalysis-table-color {
  background: #f5f6fa;
}

.InAnalysis-1-2 {
  width: 120px;
  height: 81px;
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
  text-align: center;
  line-height: 81px;
}

.InAnalysis-4-1 {
  width: 488px;
  height: 41px;
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
  border-left: 1px solid #fff;
  text-align: center;
  line-height: 41px;
}

.InAnalysis-4-2 {
  width: 488px;
  height: 82px;
  text-align: center;
}

.InAnalysis-1-1 {
  width: 122px;
  height: 40px;
  border-right: 1px solid #fff;
}

.InAnalysis-1-1 + .InAnalysis-1-1 {
  border-left: 1px solid #fff;
}

.InAnalysis-row .InAnalysis-1-1:last-child {
  border-right: none;
}

.InAnalysis-3-2 {
  width: 366px;
  height: 82px;
  text-align: center;
}

.InAnalysis-3-1 {
  width: 366px;
  height: 41px;
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
  border-left: 1px solid #fff;
  text-align: center;
  line-height: 41px;
}

.InAnalysis-row {
  width: 100%;
  height: 42px;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  text-align: center;
  line-height: 40px;
  display: flex;
}

#echarts1 {
  width: 100%;
  height: 550px;
  margin-top: 16px;
}

.InAnalysis-trend {
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
  margin-top: 16px;
  padding: 24px;
}

#echarts2 {
  width: 100%;
  height: 400px;
  position: relative;
}

.InAnalysis-echarts {
  width: 100%;
  height: 490px;
  margin-top: 24px;
  padding: 24px;
  display: flex;
  background: #ffffff;
  border-radius: 4px;
}

.InAnalysis-echarts-div {
  width: 50%;
  height: 100%;
}

.InAnalysis-echarts-grey {
  width: 760px;
  height: 380px;
  padding: 16px;
  background: #f5f6fa;
  border-radius: 4px;
  margin-top: 32px;
  margin-left: 20px;
}

.InAnalysis-fenlei-content-right {
  width: 49%;
  margin-top: 52px;
}

::v-deep .el-table--striped .el-table__body tr.el-table__row--striped td {
  background: #f5f6fa;
}

.analyse-container {
  width: 100%;
  height: 500px;
  background: #ffffff;
  border-radius: 4px;
  margin-top: 16px;
  text-align: center;
}

.analyse-container-img {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.analyse-container-img p {
  font-size: 14px;
  color: #999999;
}
</style>
