<template>
  <div class="InAnalysis jnYq">
    <div class="InAnalysis-top">
      <div class="InAnalysis-title">
        <p class="p1">境内舆情 综合分析</p>
        <p class="p2">{{ timersArr[0] }} 至 {{ timersArr[1] }}</p>
      </div>
      <!-- 综合数据统计表 -->
      <div class="InAnalysis-statistical-table">
        <div class="InAnalysis-top-title">
          <img src="../../assets/img/views/topqq.png" />
          <span>综合数据统计表</span>
        </div>
        <!-- 切换 -->
        <div class="InAnalysis-tab">
          <div
            :class="{
              'InAnalysis-tab-card': true,
              active: activeTab == val.type,
            }"
            v-for="(val, index) in tab"
            :key="index"
            @click="
              tabCard(index);
              TabjnAnalysisDataStatistics(index + 1);
            "
          >{{ val.name }}</div>
        </div>
        <!-- 环节 -->
        <p class="InAnalysis-link">
          <span v-if="activeTab == 0">全部环节：</span>
          <span v-if="activeTab == 1">全部属性：</span>
          <span v-if="activeTab == 2">全部级别：</span>
          <span
            class="InAnalysis-link-span pointer"
            v-for="(val, index) in slectAddTableArr"
            @click="
              slectremoveTable(
                slectAddTableArr[index - 1]
                  ? slectAddTableArr[index - 1]
                  : { activeTab },
                index
              )
            "
            :key="index"
          >
            {{ val.name }}
            <span
              v-if="
                slectAddTableArr.length > 0 &&
                index < slectAddTableArr.length - 1
              "
            >/</span>
          </span>
        </p>
        <div class="flex" style="justify-content: space-between">
          <!-- tab 表格 -->
          <div class="InAnalysis-table">
            <table class="table" ref="table">
              <thead>
                <tr>
                  <!-- 环节开头 -->
                  <template v-if="activeTab == 0">
                    <th rowspan="2">环节</th>
                    <th rowspan="2" v-if="slectAddTableArr.length > 0">属性</th>
                    <th rowspan="2" v-if="slectAddTableArr.length > 1">级别</th>
                  </template>
                  <!-- 属性开头 -->
                  <template v-if="activeTab == 1">
                    <th rowspan="2">属性</th>
                    <th rowspan="2" v-if="slectAddTableArr.length > 0">级别</th>
                    <th rowspan="2" v-if="slectAddTableArr.length > 1">环节</th>
                  </template>
                  <!-- 环节开头 -->
                  <template v-if="activeTab == 2">
                    <th rowspan="2">级别</th>
                    <th rowspan="2" v-if="slectAddTableArr.length > 0">环节</th>
                    <th rowspan="2" v-if="slectAddTableArr.length > 1">属性</th>
                  </template>
                  <th colspan="4">舆情数据</th>
                  <th colspan="3">预警数据</th>
                </tr>
                <tr>
                  <th style="background: #ffffff">负面</th>
                  <th style="background: #ffffff">中性</th>
                  <th style="background: #ffffff">正面</th>
                  <th style="background: #ffffff">合计</th>
                  <th style="background: #ffffff">负面</th>
                  <th style="background: #ffffff">非负面</th>
                  <th style="background: #ffffff">合计</th>
                </tr>
              </thead>
              <tbody>
                <!-- 新增的表头 -->
                <template v-if="slectAddTableArr.length > 0">
                  <th
                    :rowspan="tableDatacom.length + 1"
                    v-for="(item, index) in slectAddTableArr"
                    :key="item.name"
                    class="pointer"
                    @click="
                      slectremoveTable(
                        slectAddTableArr[index - 1]
                          ? slectAddTableArr[index - 1]
                          : { activeTab },
                        index
                      )
                    "
                  >{{ item.name }}</th>
                </template>
                <tr
                  v-for="(item, index) in tableDatacom"
                  :key="index"
                  :class="{ tr: index % 2 === 0 }"
                >
                  <!-- 环节开头  -->
                  <template v-if="activeTab == 0">
                    <th
                      class="pointer"
                      v-if="slectAddTableArr.length == 0"
                      @click="slectAddTable(item)"
                    >{{ item.mediaLink }}</th>
                    <th
                      v-if="slectAddTableArr.length == 1"
                      class="pointer"
                      @click="slectAddTable(item)"
                    >{{ item.mediaAttribute }}</th>
                    <th v-if="slectAddTableArr.length == 2" class="pointer">{{ item.mediaLevel }}</th>
                  </template>
                  <!-- 属性开头  -->
                  <template v-if="activeTab == 1">
                    <th
                      class="pointer"
                      v-if="slectAddTableArr.length == 0"
                      @click="slectAddTable(item)"
                    >{{ item.mediaAttribute }}</th>
                    <th
                      v-if="slectAddTableArr.length == 1"
                      class="pointer"
                      @click="slectAddTable(item)"
                    >{{ item.mediaLevel }}</th>
                    <th v-if="slectAddTableArr.length == 2" class="pointer">{{ item.mediaLink }}</th>
                  </template>
                  <!-- 级别开头  -->
                  <template v-if="activeTab == 2">
                    <th
                      class="pointer"
                      v-if="slectAddTableArr.length == 0"
                      @click="slectAddTable(item)"
                    >{{ item.mediaLevel }}</th>
                    <th
                      v-if="slectAddTableArr.length == 1"
                      class="pointer"
                      @click="slectAddTable(item)"
                    >{{ item.mediaLink }}</th>
                    <th
                      v-if="slectAddTableArr.length == 2"
                      class="pointer"
                    >{{ item.mediaAttribute }}</th>
                  </template>
                  <td @click="handel(item,'负',item.yqNum2)" class="pointor">{{ item.yqNum2 }}</td>
                  <td @click="handel(item,'中',item.yqNum1)" class="pointor">{{ item.yqNum1 }}</td>
                  <td @click="handel(item,'正',item.yqNum0)" class="pointor">{{ item.yqNum0 }}</td>
                  <td
                    @click="handel(item,'合',item.yqNum0 + item.yqNum1 + item.yqNum2)"
                    class="pointor"
                  >{{ item.yqNum0 + item.yqNum1 + item.yqNum2 }}</td>
                  <td @click="handel(item,'预警负',item.yjNumfm)" class="pointor">{{ item.yjNumfm }}</td>
                  <td @click="handel(item,'预警非负',item.yjNumffm)" class="pointor">{{ item.yjNumffm }}</td>
                  <td
                    @click="handel(item,'预警合',item.yjNumfm + item.yjNumffm)"
                    class="pointor"
                  >{{ item.yjNumfm + item.yjNumffm }}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- echarts 饼图 -->
          <div v-if="dataShow" id="echarts1"></div>
          <div class="analyse-container" v-else>
            <div class="analyse-container-img dataShowImg">
              <img src="../../assets/img/views/analyse-img.png" />
              <p>综合数据统计图</p>
            </div>
          </div>
          <!-- 自己写表格 -->
        </div>
      </div>
    </div>

    <!-- 舆情走势 -->
    <div class="InAnalysis-trend">
      <div class="InAnalysis-top-title">
        <img src="../../assets/img/views/topqq.png" />
        <span>舆情走势</span>
      </div>
      <div id="echarts2"></div>
      <div class="echarts-legend">
        <div class="echarts-legend-line"></div>

        <div style="display: flex; flex-direction: column">
          <div class="legend-top">
            <span class="noYujing">正</span>
            <span class="noYujing" style="margin-left: 12px">中</span>
            <span class="noYujing" style="margin-left: 12px">负</span>
          </div>
          <div style="display: flex; text-align: right; margin-top: 7px">
            <div
              style="
                display: flex;
                flex-direction: column;
                color: #5a6474;
                font-size: 12px;
              "
            >
              <span>非预警</span>
              <span>预警</span>
            </div>
            <div style="display: flex; flex-direction: column; margin-left: 8px">
              <span style="width: 16px; height: 16px; background: #a8faf2"></span>
              <span style="width: 16px; height: 16px; background: #5be6d9"></span>
            </div>
            <div style="display: flex; flex-direction: column; margin-left: 8px">
              <span style="width: 16px; height: 16px; background: #b0cbff"></span>
              <span style="width: 16px; height: 16px; background: #6398ff"></span>
            </div>
            <div style="display: flex; flex-direction: column; margin-left: 8px">
              <span style="width: 16px; height: 16px; background: #ffc2dd"></span>
              <span style="width: 16px; height: 16px; background: #ff70af"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 各环节负面数据占比 各环节非负面数据占比 -->
    <div class="InAnalysis-echarts">
      <div class="InAnalysis-echarts-div">
        <div class="InAnalysis-top-title">
          <img src="../../assets/img/views/topqq.png" />
          <span>各环节负面数据占比</span>
        </div>
        <div class="InAnalysis-echarts-grey">
          <div v-if="echarts3Data.length!=0" class="InAnalysis-echarts-grey-div" id="echarts3"></div>
          <div
            style="height:348px;margin-top:0"
            class="analyse-container"
            v-if="echarts3Data.length == 0"
          >
            <div style="margin-top:50px" class="analyse-container-img">
              <img src="../../assets/img/views/analyse-img.png" />
              <p>负面各环节数据占总数据饼图</p>
            </div>
          </div>
          <div
            v-if="echarts3twoData.length!=0"
            class="InAnalysis-echarts-grey-div"
            id="echarts3two"
          ></div>
          <div
            style="height:348px;margin-top:0"
            class="analyse-container"
            v-if="echarts3twoData.length == 0"
          >
            <div style="margin-top:50px" class="analyse-container-img">
              <img src="../../assets/img/views/analyse-img.png" />
              <p>各环节负面预警数据占总预警数据饼图</p>
            </div>
          </div>
        </div>
      </div>
      <div class="InAnalysis-echarts-div">
        <div class="InAnalysis-top-title">
          <img src="../../assets/img/views/topqq.png" />
          <span>各环节非负面数据占比</span>
        </div>
        <div class="InAnalysis-echarts-grey">
          <div v-if="echarts4Data.length!=0" class="InAnalysis-echarts-grey-div" id="echarts4"></div>
          <div
            style="height:348px;margin-top:0"
            v-if="echarts4Data.length == 0"
            class="analyse-container"
          >
            <div style="margin-top:50px" class="analyse-container-img">
              <img src="../../assets/img/views/analyse-img.png" />
              <p>各环节非负面数据占总数据饼图</p>
            </div>
          </div>
          <div
            v-if="echarts4twoData.length!=0"
            class="InAnalysis-echarts-grey-div"
            id="echarts4two"
          ></div>
          <div
            style="height:348px;margin-top:0"
            v-if="echarts4twoData.length == 0"
            class="analyse-container"
          >
            <div style="margin-top:50px" class="analyse-container-img">
              <img src="../../assets/img/views/analyse-img.png" />
              <p>各环节非负面预警数据占总预警数据饼图</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 预警数据事件类型分类 -->
    <div class="InAnalysis-fenlei">
      <div class="InAnalysis-top-title">
        <img src="../../assets/img/views/topqq.png" />
        <span>预警数据事件类型分类</span>
      </div>
      <div class="InAnalysis-fenlei-div">
        <div v-if="yjTotalFlag" class="InAnalysis-fenlei-small" id="echarts5"></div>
        <div class="analyse-container" v-else>
          <div class="analyse-container-img analyse-container-img-flex">
            <img src="../../assets/img/views/analyse-img.png" />
            <p>预警数据事件类型分类</p>
          </div>
        </div>
        <div class="InAnalysis-fenlei-small">
          <div style="font-weight: bold; color: #333333">{{ PoliticsName }}{{ PoliticsTotal }}</div>
          <el-table
            :cell-style="{cursor: 'pointer'}"
            @row-click="rowClick"
            :header-cell-style="{ background: '#F5F6FA', color: '#333333' }"
            :data="tableData"
            max-height="385"
            style="
              width: 100%;
              margin-top: 17px;
              border: 1px solid #d8d8d8;
              border-bottom: none;
            "
          >
            <el-table-column prop="eventClass" label="预警类型" align="center"></el-table-column>
            <el-table-column prop="yjTypeNum" label="数量" align="center"></el-table-column>
            <el-table-column prop="total" label="数据占比" align="center">
              <template slot-scope="scope">
                <span>
                  {{
                  ((scope.row.yjTypeNum / PoliticsTotal) * 100).toFixed(2)
                  }}%
                </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>

    <div class="InAnalysis-fenlei">
      <div class="InAnalysis-top-title">
        <img src="../../assets/img/views/topqq.png" />
        <span>舆情高发平台统计（TOP 10）</span>
      </div>
      <div class="InAnalysis-fenlei-content">
        <div class="InAnalysis-fenlei-content-left">
          <ul class="InAnalysis-fenlei-content-left-top">
            <li
              :class="item == num ? 'item-Li' : ''"
              @click="assignment(item, inx)"
              v-for="(item, inx) in tab2"
              :key="inx"
            >{{ item }}</li>
          </ul>
          <el-table
            :header-cell-style="{
              background: '#fafafa',
              padding: '0px',
              height: '40px',
              color: '#333',
            }"
            :cell-style="{ padding: '0px', height: '40px' }"
            :data="tableData1"
            stripe
            style="width: 100%; border: 1px solid #000; cursor: pointer"
            @row-click="jnAnalysisHighIncidencePlatformAuthor"
          >
            <el-table-column prop="source" label="发布平台" align="center">
              <template slot-scope="scope">
                <el-tooltip class="item" effect="dark" :content="scope.row.source" placement="top">
                  <div class="sourceCell">{{scope.row.source}}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column label="发布总数" align="center">
              <template slot-scope="scope">
                <span
                  @click="fbTotalNumData(scope.row,'',scope.row.fbTotalNum)"
                  class="fbTotalNum"
                >{{scope.row.fbTotalNum}}</span>
              </template>
            </el-table-column>
            <el-table-column label="负面数据" align="center">
              <template slot-scope="scope">
                <span
                  @click="fbTotalNumData(scope.row,'2',scope.row.fmNum)"
                  class="fbTotalNum"
                >{{scope.row.fmNum}}</span>
              </template>
            </el-table-column>
            <el-table-column label="非负面数据" align="center">
              <template slot-scope="scope">
                <span
                  @click="fbTotalNumData(scope.row,'0,1',scope.row.ffmNum)"
                  class="fbTotalNum"
                >{{scope.row.ffmNum}}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="InAnalysis-fenlei-content-right">
          <div
            style="color: #2e59ec; font-weight: bold; margin-bottom: 20px"
          >{{ AnalysSource }}账号发布本排行（TOP20）</div>
          <el-table
            :header-cell-style="{
              background: '#fafafa',
              padding: '0px',
              height: '40px',
              color: '#333',
            }"
            :cell-style="{ padding: '0px', height: '40px'}"
            :data="tableData2"
            max-height="482"
            stripe
            style="width: 100%; border: 1px solid #000"
          >
            <el-table-column prop="author" label="账户/账号" align="center">
              <template slot-scope="scope">
                <el-tooltip class="item" effect="dark" :content="scope.row.author" placement="top">
                  <div class="sourceCell">{{scope.row.author}}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column label="发布总数" align="center">
              <template slot-scope="scope">
                <span
                  @click="authorNumData(scope.row,'',scope.row.fbTotalNum)"
                  class="fbTotalNum"
                >{{scope.row.fbTotalNum}}</span>
              </template>
            </el-table-column>
            <el-table-column label="负面数据" align="center">
              <template slot-scope="scope">
                <span
                  @click="authorNumData(scope.row,'2',scope.row.fmNum)"
                  class="fbTotalNum"
                >{{scope.row.fmNum}}</span>
              </template>
            </el-table-column>
            <el-table-column label="非负面数据" align="center">
              <template slot-scope="scope">
                <span
                  @click="authorNumData(scope.row,'0,1',scope.row.ffmNum)"
                  class="fbTotalNum"
                >{{scope.row.ffmNum}}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div class="InAnalysis-fenlei">
      <div class="InAnalysis-top-title">
        <img src="../../assets/img/views/topqq.png" />
        <span>舆情发布热力分布</span>
      </div>
      <div class="InAnalysis-top-logo"></div>
      <ul class="InAnalysis-fenlei-content-left-top">
        <li
          :class="item == num1 ? 'item-Li' : ''"
          @click="assignment1(item, inx + 1)"
          v-for="(item, inx) in tab3"
          :key="inx"
        >{{ item }}</li>
      </ul>
      <div
        id="echarts6"
        v-loading="maploading"
        element-loading-text="地图数据正在拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(228, 227, 227, 0.4)"
      ></div>

      <img class="map" src="../../assets/img/views/map.png" />
    </div>
  </div>
</template>

<script>
import html2canvas from "html2canvas";
import * as echarts from "echarts";
// import { jsPDF } from "jspdf"; //pdf插件
import map from "../../assets/js/China1.json";
import {
  // getPolitics,
  getPoliticsList,
  jnYuQTrend,
  jnAnalysisHighIncidencePlatform,
  jnAnalysisHighIncidencePlatformAuthor,
  jnAnalysisDataStatistics,
  yqFbHotList
} from "../../api/analyse/index";
export default {
  props: {
    timetype: {
      //时间类型  近三天/近七天..
      type: Number
    },
    timers: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      timeThird: [], // 时间范围数组
      maploading: true, // 地图的等待展示
      mapdata1: [], //地图数组1
      mapdata2: [], //地图数组2
      picInfoArr: [], //echarts的图片组给PDF
      timersArr: [], //时间数组
      organId: this.$store.state.user.organizationId,
      AnalysSource: "", // 舆情作者
      tab: [
        {
          name: "媒体环节",
          type: 0
        },
        {
          name: "媒体属性",
          type: 1
        },
        {
          name: "媒体级别",
          type: 2
        }
      ],
      tab2: ["整体数据", "负面", "非负面"],
      tab3: ["管辖地", "归属地"],
      num: "整体数据",
      num1: "管辖地",
      activeTab: 0,
      InAnalysisLink: ["电视", "政务"],
      tableData: [], // 涉政事件数据
      PoliticsTotal: null, // 涉政事件数量
      PoliticsName: "", // 涉政时间名字
      PoliticsNames: "",
      tableData1: [
        //舆情高发平台统计（TOP 10
      ],
      tableData2: [
        //舆情高发平台作者统计
      ],
      tableDatacom: [], //综合数据表
      slectAddTableArr: [], // 综合数据表新增的表头
      tabactiveObj: {
        hj: true
      }, // 环节/属性/ 级别
      mediaLinkname: "", //环节名字
      mediaAttributename: "", //属性明字
      mediaSpeed: 0, //进度  0:环节 1:属性 2： 级别
      sourt: 0,
      // 是否展示echarts3的数据
      echarts3Data: [],
      echarts3twoData: [],
      echarts4Data: [],
      echarts4twoData: [],
      dataShow: false,
      yjTotalFlag: false,
      // 用来存放name事件类型分类的参数name
      warningName: "",
      // 存放第一个name事件类型分类的参数name
      warningNameFirst: "",
      // 用来存地图的切换按钮
      mapInedx: 1,
      //   默认环节
      mediaLinkList: [
        {
          mediaLink: "电视",
          yjNumffm: 0,
          yjNumfm: 0,
          yqNum0: 0,
          yqNum1: 0,
          yqNum2: 0
        },
        {
          mediaLink: "纸媒",
          yjNumffm: 0,
          yjNumfm: 0,
          yqNum0: 0,
          yqNum1: 0,
          yqNum2: 0
        },
        {
          mediaLink: "网站",
          yjNumffm: 0,
          yjNumfm: 0,
          yqNum0: 0,
          yqNum1: 0,
          yqNum2: 0
        },
        {
          mediaLink: "微博",
          yjNumffm: 0,
          yjNumfm: 0,
          yqNum0: 0,
          yqNum1: 0,
          yqNum2: 0
        },
        {
          mediaLink: "微信公众号",
          yjNumffm: 0,
          yjNumfm: 0,
          yqNum0: 0,
          yqNum1: 0,
          yqNum2: 0
        },
        {
          mediaLink: "移动客户端",
          yjNumffm: 0,
          yjNumfm: 0,
          yqNum0: 0,
          yqNum1: 0,
          yqNum2: 0
        },
        {
          mediaLink: "互动栏目",
          yjNumffm: 0,
          yjNumfm: 0,
          yqNum0: 0,
          yqNum1: 0,
          yqNum2: 0
        },
        {
          mediaLink: "视频",
          yjNumffm: 0,
          yjNumfm: 0,
          yqNum0: 0,
          yqNum1: 0,
          yqNum2: 0
        },
        {
          mediaLink: "合计",
          yjNumffm: 0,
          yjNumfm: 0,
          yqNum0: 0,
          yqNum1: 0,
          yqNum2: 0
        }
      ],
      mediaAttributeList: [
        {
          mediaAttribute: "官媒",
          yjNumffm: 0,
          yjNumfm: 0,
          yqNum0: 0,
          yqNum1: 0,
          yqNum2: 0
        },
        {
          mediaAttribute: "商媒",
          yjNumffm: 0,
          yjNumfm: 0,
          yqNum0: 0,
          yqNum1: 0,
          yqNum2: 0
        },
        {
          mediaAttribute: "政务",
          yjNumffm: 0,
          yjNumfm: 0,
          yqNum0: 0,
          yqNum1: 0,
          yqNum2: 0
        },
        {
          mediaAttribute: "机构",
          yjNumffm: 0,
          yjNumfm: 0,
          yqNum0: 0,
          yqNum1: 0,
          yqNum2: 0
        },
        {
          mediaAttribute: "个人",
          yjNumffm: 0,
          yjNumfm: 0,
          yqNum0: 0,
          yqNum1: 0,
          yqNum2: 0
        },
        {
          mediaAttribute: "重点账号",
          yjNumffm: 0,
          yjNumfm: 0,
          yqNum0: 0,
          yqNum1: 0,
          yqNum2: 0
        },
        {
          mediaAttribute: "其他",
          yjNumffm: 0,
          yjNumfm: 0,
          yqNum0: 0,
          yqNum1: 0,
          yqNum2: 0
        },
        {
          mediaAttribute: "合计",
          yjNumffm: 0,
          yjNumfm: 0,
          yqNum0: 0,
          yqNum1: 0,
          yqNum2: 0
        }
      ],
      mediamediaLevel: [
        {
          mediaLevel: "全国",
          yjNumffm: 0,
          yjNumfm: 0,
          yqNum0: 0,
          yqNum1: 0,
          yqNum2: 0
        },
        {
          mediaLevel: "省级",
          yjNumffm: 0,
          yjNumfm: 0,
          yqNum0: 0,
          yqNum1: 0,
          yqNum2: 0
        },
        {
          mediaLevel: "市级",
          yjNumffm: 0,
          yjNumfm: 0,
          yqNum0: 0,
          yqNum1: 0,
          yqNum2: 0
        },
        {
          mediaLevel: "区县级",
          yjNumffm: 0,
          yjNumfm: 0,
          yqNum0: 0,
          yqNum1: 0,
          yqNum2: 0
        },
        {
          mediaLevel: "其他",
          yjNumffm: 0,
          yjNumfm: 0,
          yqNum0: 0,
          yqNum1: 0,
          yqNum2: 0
        },
        {
          mediaLevel: "合计",
          yjNumffm: 0,
          yjNumfm: 0,
          yqNum0: 0,
          yqNum1: 0,
          yqNum2: 0
        }
      ]
    };
  },
  methods: {
    // 表格跳详情
    handel(item, text, num) {
      if (num == 0) {
        return false;
      }
      let obj = {};
      if (this.slectAddTableArr.length > 0) {
        this.slectAddTableArr.forEach(item => {
          obj[Object.keys(item)[0]] = Object.values(item)[0];
        });
      }
      if (Object.values(item)[0] != "合计") {
        obj[Object.keys(item)[0]] = Object.values(item)[0];
      }
      if (text == "正") {
        obj.tonalState = "0";
      } else if (text == "中") {
        obj.tonalState = "1";
      } else if (text == "负") {
        obj.tonalState = "2";
      } else if (text == "预警负") {
        obj.tonalState = "2";
        obj.isEarlyWarn = 1;
      } else if (text == "预警非负") {
        obj.tonalState = "1,0";
        obj.isEarlyWarn = 1;
      } else if (text == "预警合") {
        obj.isEarlyWarn = 1;
      }
      obj.startTimeStr = this.timers[0].replace(" ", "T") + "+08:00";
      obj.endTimeStr = this.timers[1].replace(" ", "T") + "+08:00";
      let routeUrl = this.$router.resolve({
        path: "/sentiment/index",
        query: {
          info: encodeURIComponent(JSON.stringify(obj))
        }
      });
      window.open(routeUrl.href, "_blank");
    },
    // 事件类型分类
    rowClick(row) {
      if (row.yjTypeNum == 0) {
        return false;
      }
      let routeUrl = this.$router.resolve({
        path: "/sentiment/index",
        query: {
          info: encodeURIComponent(
            JSON.stringify({
              eventClass: row.eventClass,
              mediaLink: this.PoliticsNames,
              startTimeStr: this.timers[0].replace(" ", "T") + "+08:00",
              endTimeStr: this.timers[1].replace(" ", "T") + "+08:00",
              isEarlyWarn: 1
            })
          )
        }
      });
      window.open(routeUrl.href, "_blank");
    },
    // 高发平台
    fbTotalNumData(item, text, num) {
      if (num == 0) {
        return false;
      }
      let obj = {
        startTimeStr: this.timers[0].replace(" ", "T") + "+08:00",
        endTimeStr: this.timers[1].replace(" ", "T") + "+08:00"
      };
      if (text) {
        obj.tonalState = text;
      }

      if (item.source == "合计") {
        let arr = [];
        this.tableData1.forEach(item => {
          if (item.source != "合计") {
            arr.push(item.source);
          }
        });
        obj.source = arr.join(",");
      } else {
        obj.source = item.source;
      }
      let routeUrl = this.$router.resolve({
        path: "/sentiment/index",
        query: {
          info: encodeURIComponent(JSON.stringify(obj))
        }
      });
      window.open(routeUrl.href, "_blank");
    },
    authorNumData(item, text, num) {
      if (num == 0) {
        return false;
      }
      let obj = {
        source: this.AnalysSource,
        startTimeStr: this.timers[0].replace(" ", "T") + "+08:00",
        endTimeStr: this.timers[1].replace(" ", "T") + "+08:00"
      };
      if (item.author) {
        obj.author = item.author;
      }
      if (text) {
        obj.tonalState = text;
      }
      let routeUrl = this.$router.resolve({
        path: "/sentiment/index",
        query: {
          info: encodeURIComponent(JSON.stringify(obj))
        }
      });
      window.open(routeUrl.href, "_blank");
    },
    allFunction() {
      this.num1 = "管辖地";
      this.thirdData();
      this.getinfo();
      this.jnAnalysisHighIncidencePlatform();
      this.getPolitics();
      this.createMap();
    },
    thirdData() {
      let timeEnd = this.timers[1].split(" ")[0];
      let timeStar = this.timers[0].split(" ")[0];
      let numDate = this.$calcu.getDays(timeEnd, timeStar);
      let data = new Date(timeEnd + " " + new Date().getHours() + ":00:00");
      this.timeThird = this.$calcu.getTimeSentiment(
        numDate,
        numDate + 1,
        1,
        data
      );
      this.sentmentTrend();
    },
    async sentmentTrend() {
      let dataList = []; //存放后台返回的数据
      let obj = {
        organId: this.organId,
        type: this.timetype
      };
      let res = await jnYuQTrend(this.addObjtime(obj));
      if (res.data.data && res.data.data.length != 0) {
        dataList = res.data.data;
        this.timeThird = this.$calcu.getDateList(dataList, this.timeThird, "1");
        this.timeThird.forEach(item => {
          item.yjzs = item.yjNum0 + item.yjNum1 + item.yjNum2;
          item.ztzs =
            item.yjNum0 +
            item.yjNum1 +
            item.yjNum2 +
            item.fyjNum0 +
            item.fyjNum1 +
            item.fyjNum2;
        });
        this.timeThird = this.$calcu.getNumSentiment(this.timeThird, 1);
      }
      this.echarts2(this.timeThird);
    },
    // 时间函数
    timersFn() {
      this.timersArr = this.timers;
    },
    // 自调用
    async getinfo() {
      this.slectAddTableArr = [];
      this.tableDatacom = [];
      this.picInfoArr = [];
      let item = {
        organId: this.organId,
        analysisType: 1,
        type: this.timetype
      };
      this.tableDatacom = JSON.parse(JSON.stringify(this.mediaLinkList));
      let res = await jnAnalysisDataStatistics(this.addObjtime(item));
      if (res.data.data && res.data.data.length != 0) {
        this.tableDatacom = this.$calcu.comprehensiveData(
          JSON.parse(JSON.stringify(this.tableDatacom)),
          res.data.data,
          1
        );
        this.echarts1(res.data.data); //表格右边的环形图
        this.echarts3(res.data.data); //各环节负面数据占比
        this.echarts3two(res.data.data);
        this.echarts4(res.data.data); //各环节非负面数据占比
        this.echarts4two(res.data.data); //各环节非负面数据占比
      } else {
        if (document.getElementById("echarts1")) {
          echarts.dispose(document.getElementById("echarts1"));
          this.dataShow = false;
        }
        if (document.getElementById("echarts3")) {
          echarts.dispose(document.getElementById("echarts3"));
          this.echarts3Data = [];
        }
        if (document.getElementById("echarts3two")) {
          echarts.dispose(document.getElementById("echarts3two"));
          this.echarts3twoData = [];
        }
        if (document.getElementById("echarts4")) {
          echarts.dispose(document.getElementById("echarts4"));
          this.echarts4Data = [];
        }
        if (document.getElementById("echarts4two")) {
          echarts.dispose(document.getElementById("echarts4two"));
          this.echarts4twoData = [];
        }
      }
      this.echarts5(); //预警数据事件类型分类
    },
    assignment(item) {
      this.num = item;
      if (this.num == "整体数据") {
        this.sourt = 0;
      } else if (this.num == "负面") {
        this.sourt = 1;
      } else {
        this.sourt = 2;
      }
      this.jnAnalysisHighIncidencePlatform();
    },
    assignment1(item, inx) {
      this.num1 = item;
      this.mediaHot(inx);
    },
    tabCard(index) {
      this.activeTab = index;
    },
    echarts1(data) {
      if (document.getElementById("echarts1")) {
        echarts.dispose(document.getElementById("echarts1"));
      }
      let yjTotal = [];
      let yqTotal = [];
      if (data.length > 0) {
        this.dataShow = true;
        if (data[0].mediaLink) {
          data.forEach(e => {
            yjTotal.push({
              value: e.yjNumffm + e.yjNumfm,
              name: e.mediaLink
            });
            yqTotal.push({
              value: e.yqNum0 + e.yqNum1 + e.yqNum2,
              name: e.mediaLink
            });
          });
        } else if (data[0].mediaAttribute) {
          data.forEach(e => {
            yjTotal.push({
              value: e.yjNumffm + e.yjNumfm,
              name: e.mediaAttribute
            });
            yqTotal.push({
              value: e.yqNum0 + e.yqNum1 + e.yqNum2,
              name: e.mediaAttribute
            });
          });
        } else if (data[0].mediaLevel) {
          data.forEach(e => {
            yjTotal.push({
              value: e.yjNumffm + e.yjNumfm,
              name: e.mediaLevel
            });
            yqTotal.push({
              value: e.yqNum0 + e.yqNum1 + e.yqNum2,
              name: e.mediaLevel
            });
          });
        }
        yjTotal.forEach((item, index) => {
          if (item.value == 0) {
            yjTotal.splice(index, 1);
          }
        });
        yqTotal.forEach((item, index) => {
          if (item.value == 0) {
            yjTotal.splice(index, 1);
          }
        });
        setTimeout(() => {
          let myChart = echarts.init(document.getElementById("echarts1"));
          let option = {
            title: [
              {
                text: "综合数据统计图",
                left: "48%",
                top: "95%",
                textAlign: "center",
                textStyle: {
                  color: "#999999",
                  fontSize: 12,
                  fontWeight: "normal"
                }
              }
            ],
            tooltip: {
              formatter: "{b} {d}%"
            },

            series: [
              {
                name: "Access From",
                type: "pie",
                selectedMode: "single",
                radius: ["0%", "40%"],
                label: {
                  position: "inner",
                  fontSize: 14,
                  normal: {
                    show: false
                  },
                  formatter: "{b}:\n{d}%"
                },
                data: yqTotal
              },
              {
                name: "牛逼",
                type: "pie",
                radius: ["45%", "60%"],
                labelLine: {
                  length: 30
                },
                label: {
                  formatter: "{b}:\n{d}%"
                },
                data: yjTotal
              }
            ]
          };
          myChart.setOption(option);
          let picInfo;
          let _this = this;
          myChart.on("finished", function() {
            picInfo = myChart.getDataURL();
            _this.picInfoArr[0] = picInfo;
          });
        }, 500);
      } else {
        this.dataShow = false;
      }
    },
    // 判断时间 公共函数
    addObjtime(data) {
      //自选时间时传时间范围
      data.startTimeStr = this.timers[0];
      data.endTimeStr = this.timers[1];
      return data;
    },
    // 舆情走势
    echarts2(timeThird) {
      if (document.getElementById("echarts2")) {
        echarts.dispose(document.getElementById("echarts2"));
      }
      let myChart = echarts.init(document.getElementById("echarts2"));
      let option = {
        grid: {
          left: "1%",
          right: "2%",
          bottom: "15%",
          top: "25%",
          containLabel: true
        },
        tooltip: {
          trigger: "axis"
        },
        legend: [
          {
            orient: "horizontal",
            right: "10%",
            align: "left",
            top: "-5",
            itemWidth: 27,
            itemHeight: 16,
            data: [
              {
                name: "舆情预警走势"
              }
            ]
          },
          {
            right: "10%",
            align: "left",
            top: "25",
            itemWidth: 27,
            itemHeight: 16,
            data: [
              {
                name: "整体舆情走势"
              }
            ]
          }
        ],
        dataZoom: [
          {
            type: "inside"
          },
          {
            left: "5%",
            right: "5%"
          }
        ],
        xAxis: {
          type: "category",
          data: timeThird.map(e => e.time),
          borderColor: "#D8D8D8",
          axisLine: {
            lineStyle: {
              color: "#D8D8D8"
            }
          },
          axisTick: {
            show: false
          }
        },
        yAxis: [
          {
            name: "总量",
            type: "value",
            axisLine: {
              lineStyle: {
                color: "#CCCCCC",
                fontSize: 12
              }
            },
            splitLine: {
              lineStyle: {
                color: "#E5E5E5",
                type: "dashed"
              }
            },
            minInterval: 1, //将刻度的最小间距设置为1
            boundaryGap: ["0%", "50%"]
          },
          {
            name: "增量",
            type: "value",
            axisLine: {
              lineStyle: {
                color: "#CCCCCC",
                fontSize: 12
              }
            },
            splitLine: {
              lineStyle: {
                color: "#E5E5E5",
                type: "dashed"
              }
            },
            minInterval: 1, //将刻度的最小间距设置为1
            boundaryGap: ["0%", "50%"]
          }
        ],
        series: [
          {
            name: "舆情预警走势",
            type: "line",
            symbol: "none",
            itemStyle: {
              normal: {
                color: "#34CAFF",
                label: {
                  // show: true
                }
              }
            },
            data: timeThird.map(e => e.yjzs)
          },
          {
            name: "整体舆情走势",
            type: "line",
            symbol: "none",
            yAxisIndex: 1,
            itemStyle: {
              normal: {
                color: "#FF5670",
                label: {
                  // show: true
                }
              }
            },
            data: timeThird.map(e => e.ztzs)
          },
          {
            name: "预警",
            type: "bar",
            symbol: "none",
            stack: "正",
            itemStyle: {
              color: "#5BE6D9"
            },
            barWidth: 6,
            data: timeThird.map(e => e.yjNum0)
          },
          {
            name: "非预警",
            type: "bar",
            symbol: "none",
            stack: "正",
            itemStyle: {
              color: "#A8FAF2"
            },
            barWidth: 6,
            data: timeThird.map(e => e.fyjNum0)
          },
          {
            name: "预警",
            type: "bar",
            symbol: "none",
            stack: "中",
            itemStyle: {
              color: "#6398FF"
            },
            barWidth: 6,
            data: timeThird.map(e => e.yjNum1)
          },
          {
            name: "非预警",
            type: "bar",
            symbol: "none",
            stack: "中",
            itemStyle: {
              color: "#B0CBFF"
            },
            barWidth: 6,
            data: timeThird.map(e => e.fyjNum1)
          },
          {
            name: "预警",
            type: "bar",
            symbol: "none",
            stack: "负",
            itemStyle: {
              color: "#FF70AF"
            },
            barWidth: 6,
            data: timeThird.map(e => e.yjNum2)
          },
          {
            name: "非预警",
            type: "bar",
            symbol: "none",
            stack: "负",
            itemStyle: {
              color: "#FFC2DD"
            },
            barWidth: 6,
            data: timeThird.map(e => e.fyjNum2)
          }
        ]
      };
      myChart.setOption(option);
    },
    // 调用echats图标方法
    echarts3tu1(source) {
      echarts.dispose(document.getElementById("echarts3"));
      let myChart = echarts.init(document.getElementById("echarts3"));
      let option = {
        color: [
          "#92F5A8",
          "#FEBC3F",
          "#FFD88F",
          "#184CFF",
          "#6F90FF",
          "#0098FF",
          "#77C8FF",
          "#00D0A3"
        ],
        tooltip: {},
        title: [
          {
            text: "负面各环节数据占总数据饼图",
            left: "50%",
            top: "85%",
            textAlign: "center",
            textStyle: {
              color: "#999999",
              fontSize: 12,
              fontWeight: "normal"
            }
          }
        ],
        dataset: [
          {
            source: source
          },
          {
            transform: {
              type: "filter",
              config: {
                dimension: "Price",
                value: 1
              }
            }
          },
          {
            transform: {
              type: "filter",
              config: {
                dimension: "Price",
                value: 2
              }
            }
          }
        ],
        label: {
          formatter: "{b}\n{d}%"
        },
        series: [
          {
            name: "全部",
            type: "pie",
            center: ["50%", "45%"],
            radius: 80,
            minAngle: 10,
            datasetIndex: 1,
            itemStyle: {
              borderWidth: 1,
              borderColor: "#fff"
            }
          }
        ]
      };
      myChart.setOption(option);
      let picInfo;
      let _this = this;
      myChart.on("finished", function() {
        picInfo = myChart.getDataURL();
        _this.picInfoArr[2] = picInfo;
      });
    },
    //各环节负面数据占比
    echarts3(data) {
      let source = [["Product", "Sales", "Price"]];
      data.forEach(v => {
        if (v.yqNum2 != 0) {
          source.push([v.mediaLink, v.yqNum2, 1]);
        }
      });
      this.echarts3Data = source;
      if (source.length > 1) {
        setTimeout(() => {
          this.echarts3tu1(source);
        }, 500);
      } else {
        if (document.getElementById("echarts3")) {
          echarts.dispose(document.getElementById("echarts3"));
        }
        this.echarts3Data = [];
      }
    },
    echarts3tu2(source) {
      echarts.dispose(document.getElementById("echarts3two"));
      let myChart = echarts.init(document.getElementById("echarts3two"));
      let option = {
        color: [
          "#92F5A8",
          "#FEBC3F",
          "#FFD88F",
          "#184CFF",
          "#6F90FF",
          "#0098FF",
          "#77C8FF",
          "#00D0A3"
        ],
        tooltip: {},
        title: [
          {
            text: "各环节负面预警数据占总预警数据饼图",
            left: "50%",
            top: "85%",
            textAlign: "center",
            textStyle: {
              color: "#999999",
              fontSize: 12,
              fontWeight: "normal"
            }
          }
        ],
        dataset: [
          {
            source: source
          },
          {
            transform: {
              type: "filter",
              config: {
                dimension: "Price",
                value: 1
              }
            }
          },
          {
            transform: {
              type: "filter",
              config: {
                dimension: "Price",
                value: 2
              }
            }
          }
        ],
        label: {
          formatter: "{b}\n{d}%"
        },
        series: [
          {
            name: "负面",
            type: "pie",
            center: ["50%", "45%"],
            radius: 80,
            minAngle: 10,
            datasetIndex: 2,
            itemStyle: {
              borderWidth: 1,
              borderColor: "#fff"
            }
          }
        ]
      };
      myChart.setOption(option);
      let picInfo;
      let _this = this;
      myChart.on("finished", function() {
        picInfo = myChart.getDataURL();
        _this.picInfoArr[3] = picInfo;
      });
    },
    // 各环节预警数据
    echarts3two(data) {
      let source = [["Product", "Sales", "Price"]];
      data.forEach(v => {
        if (v.yjNumfm != 0) {
          source.push([v.mediaLink, v.yjNumfm, 2]);
        }
      });
      this.echarts3twoData = source;
      if (source.length > 1) {
        setTimeout(() => {
          this.echarts3tu2(source);
        }, 500);
      } else {
        if (document.getElementById("echarts3two")) {
          echarts.dispose(document.getElementById("echarts3two"));
        }
        this.echarts3twoData = [];
      }
    },
    echarts4tu1(source) {
      echarts.dispose(document.getElementById("echarts4"));
      let myChart = echarts.init(document.getElementById("echarts4"));
      let option = {
        color: [
          "#92F5A8",
          "#FEBC3F",
          "#FFD88F",
          "#184CFF",
          "#6F90FF",
          "#0098FF",
          "#77C8FF",
          "#00D0A3"
        ],
        title: [
          {
            text: "各环节非负面数据占总数据饼图",
            left: "50%",
            top: "85%",
            textAlign: "center",
            textStyle: {
              color: "#999999",
              fontSize: 12,
              fontWeight: "normal"
            }
          }
        ],
        tooltip: {},
        dataset: [
          {
            source: source
          },
          {
            transform: {
              type: "filter",
              config: {
                dimension: "Price",
                value: 1
              }
            }
          },
          {
            transform: {
              type: "filter",
              config: {
                dimension: "Price",
                value: 2
              }
            }
          }
        ],
        label: {
          formatter: "{b}:\n{d}%"
        },
        series: [
          {
            name: "全部",
            type: "pie",
            center: ["50%", "45%"],
            radius: 80,
            minAngle: 10,
            datasetIndex: 1,
            itemStyle: {
              borderWidth: 1,
              borderColor: "#fff"
            }
          }
        ]
      };
      myChart.setOption(option);

      let picInfo;
      let _this = this;
      myChart.on("finished", function() {
        picInfo = myChart.getDataURL();
        _this.picInfoArr[4] = picInfo;
      });
    },
    //各环节非负面数据占比
    echarts4(data) {
      let source = [["Product", "Sales", "Price"]];
      data.forEach(v => {
        if (v.yqNum0 + v.yqNum1 != 0) {
          source.push([v.mediaLink, v.yqNum0 + v.yqNum1, 1]);
        }
      });
      this.echarts4Data = source;
      if (source.length > 1) {
        setTimeout(() => {
          this.echarts4tu1(source);
        }, 500);
      } else {
        if (document.getElementById("echarts4")) {
          echarts.dispose(document.getElementById("echarts4"));
        }
        this.echarts4Data = [];
      }
    },
    echarts4tu2(source) {
      echarts.dispose(document.getElementById("echarts4two"));
      let myChart = echarts.init(document.getElementById("echarts4two"));
      let option = {
        color: [
          "#92F5A8",
          "#FEBC3F",
          "#FFD88F",
          "#184CFF",
          "#6F90FF",
          "#0098FF",
          "#77C8FF",
          "#00D0A3"
        ],
        title: [
          {
            text: "各环节非负面预警数据占总预警数据饼图",
            left: "50%",
            top: "85%",
            textAlign: "center",
            textStyle: {
              color: "#999999",
              fontSize: 12,
              fontWeight: "normal"
            }
          }
        ],
        tooltip: {},
        dataset: [
          {
            source: source
          },
          {
            transform: {
              type: "filter",
              config: {
                dimension: "Price",
                value: 1
              }
            }
          },
          {
            transform: {
              type: "filter",
              config: {
                dimension: "Price",
                value: 2
              }
            }
          }
        ],
        label: {
          formatter: "{b}:\n{d}%"
        },
        series: [
          {
            name: "负面",
            type: "pie",
            center: ["50%", "45%"],
            radius: 80,
            minAngle: 10,
            datasetIndex: 2,
            itemStyle: {
              borderWidth: 1,
              borderColor: "#fff"
            }
          }
        ]
      };
      myChart.setOption(option);

      let picInfo;
      let _this = this;
      myChart.on("finished", function() {
        picInfo = myChart.getDataURL();
        _this.picInfoArr[5] = picInfo;
      });
    },
    //各环节非负面数据占比
    echarts4two(data) {
      let source = [["Product", "Sales", "Price"]];
      data.forEach(v => {
        if (v.yjNumffm != 0) {
          source.push([v.mediaLink, v.yjNumffm, 2]);
        }
      });
      this.echarts4twoData = source;
      if (source.length > 1) {
        setTimeout(() => {
          this.echarts4tu2(source);
        }, 500);
      } else {
        if (document.getElementById("echarts4two")) {
          echarts.dispose(document.getElementById("echarts4two"));
        }
        this.echarts4twoData = [];
      }
    },
    //预警数据事件类型分类
    async echarts5() {
      this.PoliticsName = "";
      this.PoliticsNames = "";
      this.PoliticsTotal = null;
      if (document.getElementById("echarts5")) {
        echarts.dispose(document.getElementById("echarts5"));
      }
      let item = {
        organId: this.organId,
        analysisType: 1,
        isEarlyWarn: 1,
        type: this.timetype
      };
      let res = await jnAnalysisDataStatistics(this.addObjtime(item));
      let data;
      let yjTotal = [];

      if (res.data.data && res.data.data.length > 0) {
        data = res.data.data;
        if (data[0].mediaLink) {
          data.forEach(e => {
            if (e.yjNumffm + e.yjNumfm != 0) {
              yjTotal.push({
                value: e.yjNumffm + e.yjNumfm,
                name: e.mediaLink
              });
            }
          });
        } else if (data[0].mediaAttribute) {
          data.forEach(e => {
            if (e.yjNumffm + e.yjNumfm != 0) {
              yjTotal.push({
                value: e.yjNumffm + e.yjNumfm,
                name: e.mediaAttribute
              });
            }
          });
        } else if (data[0].mediaLevel) {
          data.forEach(e => {
            if (e.yjNumffm + e.yjNumfm != 0) {
              yjTotal.push({
                value: e.yjNumffm + e.yjNumfm,
                name: e.mediaLevel
              });
            }
          });
        }
        if (yjTotal.length > 0) {
          this.yjTotalFlag = true;
          yjTotal.sort((a, b) => {
            return b.value - a.value;
          });
          this.PoliticsName = "全部数据:";
          this.PoliticsNames = "";
          let num = 0;
          yjTotal.forEach(x=>{
            num += x.value;
          })
          this.PoliticsTotal = num;
          this.getPolitics();
          this.warningNameFirst = yjTotal[0].name;
          setTimeout(() => {
            let myChart = echarts.init(document.getElementById("echarts5"));
            let option = {
              color: [
                "#92F5A8",
                "#FEBC3F",
                "#FFD88F",
                "#184CFF",
                "#6F90FF",
                "#0098FF",
                "#77C8FF",
                "#00D0A3"
              ],
              label: {
                formatter: "{b}：{d}%"
              },
              title: [
                {
                  text: "预警数据事件类型分类",
                  left: "48%",
                  top: "90%",
                  textAlign: "center",
                  textStyle: {
                    color: "#999999",
                    fontSize: 12,
                    fontWeight: "normal"
                  }
                }
              ],
              series: [
                {
                  type: "pie",
                  center: ["50%", "50%"],
                  radius: 150,
                  minAngle: 10,
                  itemStyle: {
                    borderWidth: 1,
                    borderColor: "#fff"
                  },
                  data: yjTotal
                }
              ]
            };
            myChart.setOption(option);
            let _this = this;
            myChart.on("click", function(params) {
              _this.PoliticsName = params.name + ":";
              _this.PoliticsNames = params.name;

              _this.PoliticsTotal = params.value;
              _this.getPolitics(params.name);
            });
            let picInfo;
            myChart.on("finished", function() {
              picInfo = myChart.getDataURL();
              _this.picInfoArr[6] = picInfo;
            });
          }, 500);
        } else {
          this.yjTotalFlag = false;
        }
      } else {
        this.yjTotalFlag = false;
      }
    },
    // 因为接口速度慢  同时获取两份地图
    createMap() {
      yqFbHotList(
        this.addObjtime({
          organId: this.organId,
          state: 1,
          type: this.timetype
        })
      ).then(res1 => {
        let MapDate = [];
        if (res1.data.data && res1.data.data.length != 0) {
          res1.data.data.forEach(item => {
            if (
              item.province != "其他" &&
              item.province != "全国" &&
              item.province
            ) {
              item.name = item.province;
              item.value = item.gxdNum;
              MapDate.push(item);
            }
          });
          MapDate.sort((a, b) => {
            return a.gxdNum - b.gxdNum;
          });
          this.mapdata1 = MapDate;
          this.GetMap(this.mapdata1);
        } else {
          this.mapdata1 = [];
          this.GetMap([]);
        }
        this.maploading = false;
      });
      setTimeout(() => {
        yqFbHotList(
          this.addObjtime({
            organId: this.organId,
            state: 2,
            type: this.timetype
          })
        ).then(res2 => {
          let MapDate = [];
          if (res2.data.data && res2.data.data.length != 0) {
            res2.data.data.forEach(item => {
              if (
                item.province != "其他" &&
                item.province != "全国" &&
                item.province
              ) {
                item.name = item.province;
                item.value = item.gsdNum;
                MapDate.push(item);
              }
            });
            MapDate.sort((a, b) => {
              return a.gsdNum - b.gsdNum;
            });
            this.mapdata2 = MapDate;
          } else {
            this.mapdata2 = [];
          }
        });
      }, 1000);
    },
    // 热力查询 管辖地 归属地
    mediaHot(inx) {
      this.mapInedx = inx;
      if (inx == 1) {
        this.GetMap(this.mapdata1);
      } else {
        this.GetMap(this.mapdata2);
      }
    },
    GetMap(mapData) {
      let data = {
        region:[],
        value:[],
        average:[],
      };
      let sum = 0;
      mapData.forEach(x=>{
        data.region.push(x.name);
        data.value.push(x.value);
        sum += x.value;
      });
      data.region = data.region.reverse();
      data.value = data.value.reverse();
      data.value.forEach(()=>{
        data.average.push(sum/data.value.length);
      });
      if(data.region.length>=10){
        data.region.splice(10);
      }else{
        while(data.region.length<10){
          data.region.push("");
        }
      }
      echarts.registerMap("China", map);
      let myChart = echarts.init(document.getElementById("echarts6"));
      let option = {
        title: [
          {
            show: true,
            text: 'TOP 10 排行榜',
            right: 40,
            textStyle: {color: '#666666',fontSize: 12}
          }
        ],
        tooltip: {
          trigger: "item",
          backgroundColor: "rgba(255, 255, 255, 0.98)",
          borderWidth: 0,
          formatter: function(params) {
            if (params.data == undefined) {
              return "";
            }
            let str = `<div>
            <p style="width:200px;display:flex;justify-content: space-between;">
            <span style="font-size: 14px;font-weight: 550;color: #2E59EC;">${params.data.name}</span>
            <span style="font-size: 14px;font-weight: 550;color: #2E59EC;">${params.data.value}</span>
            </p></div>`;
            return str;
          }
        },
        legend: {show: false},
        grid: {
          show: true,
          containLabel: false,
          right: 40,
          top: 50,
          bottom: 30,
          width: '20%',
        },
        visualMap: {
          type: 'continuous',
          min: 0,
          max: mapData[mapData.length-1]&&mapData[mapData.length-1].value?mapData[mapData.length-1].value:1,
          text: ['多', '少'],
          seriesIndex: [0, 2],
          dimension: 0,
          realtime: false,
          left: 50,
          bottom: 50,
          itemWidth: 18,
          itemHeight: 100,
          calculable: true,
          inRange: {
            color: [
              "#e6ecfc",
              "#addbff",
              "#5cb8ff",
              "#6fa5ff",
              "#3779ff",
              "#3b64f1"
            ]
          },
          outOfRange: {
            color: ['#eeeeee'],
          },
        },
        toolbox: {
            show: false,
        },
        xAxis: [{
          type: 'value',
          position: 'top',
          inside: false,
          axisLabel: {show: false},
          splitLine: {show: false},
          margin: 10,
        }],
        yAxis: [{
          type: 'category',
          boundaryGap: true,
          axisLine: {show: false},
          axisLabel: {align: 'right',margin: 10,showMaxLabel: true},
          inverse: true,
          data: data.region,
        }],
        series: [
          {
            name: '占比',
            type: 'map',
            mapType: 'China',
            left: '100',
            width: '60%',
            mapValueCalculation: 'sum',
            zoom: 1,
            top: 10,
            selectedMode: false,
            showLegendSymbol: false,
            label: {
              normal: {show: true,color: "#fff",fontSize: 9},
              emphasis: {show: true,color: "#fff"},
            },
            itemStyle: {
              normal: {areaColor: "#e6ecfc",borderColor: "#fff"},
              emphasis: {areaColor: '#3b64f1',borderWidth: 2},
            },
            data: mapData,
          },
          {
            name: '背景',
            type: 'bar',
            roam: false,
            visualMap: false,
            itemStyle: {color:'#eeeeee',opacity: 0.5},
            label: {show: false},
            emphasis: {
              itemStyle: {color: '#eeeeee',opacity: 0.5},
              label: {show: false},
            },
            silent: true,
            barWidth: 18,
            barGap: '-100%',
            data: data.average,
            animation: false,
          },
          {
            name: '占比',
            type: 'bar',
            roam: false,
            visualMap: false,
            barWidth: 18,
            label: {
              normal: {show: true,fontSize: 12,position: 'insideLeft'},
              emphasis: {show: true,},
            },
            tooltip:{
              show:false
            },
            data: data.value,
          },
        ],
      };
      myChart.setOption(option);
      window.addEventListener("resize", () => {
        myChart.resize();
      });
    },
    // 获取涉政事件
    async getPolitics(name) {
      this.warningName = name;
      let data = {
        organId: this.organId,
        eventClass: name,
        type: this.timetype
      };
      let res = await getPoliticsList(this.addObjtime(data));
      if (res.data.code == 200) {
        res.data.data.forEach(v => {
          let e = v.eventClass.split("-");
          v.eventClass = e[e.length - 1];
        });
        this.tableData = res.data.data;
      }
    },
    //  舆情高发平台统计   舆情高发平台统计（TOP 10
    async jnAnalysisHighIncidencePlatform() {
      let data = {
        organId: this.organId,
        sort: this.sourt,
        type: this.timetype
      };
      let res = await jnAnalysisHighIncidencePlatform(this.addObjtime(data));
      if (res.data.data && res.data.data.length > 0) {
        this.tableData1 = res.data.data;
        this.tableData1.push({
          fbTotalNum: 0,
          ffmNum: 0,
          fmNum: 0,
          source: "合计"
        });
        for (let i = 0; i < this.tableData1.length - 1; i++) {
          this.tableData1[
            this.tableData1.length - 1
          ].fbTotalNum += this.tableData1[i].fbTotalNum;
          this.tableData1[this.tableData1.length - 1].ffmNum += this.tableData1[
            i
          ].ffmNum;
          this.tableData1[this.tableData1.length - 1].fmNum += this.tableData1[
            i
          ].fmNum;
        }
        this.jnAnalysisHighIncidencePlatformAuthor(res.data.data[0]);
      } else {
        this.tableData1 = [];
        this.tableData2 = [];
      }
    },
    // 舆情高发平台作者统计
    async jnAnalysisHighIncidencePlatformAuthor(val) {
      if (val.source == "合计") {
        return false;
      }
      this.AnalysSource = val.source;
      let data = {
        organId: this.organId,
        source: val.source,
        type: this.timetype
      };
      let res = await jnAnalysisHighIncidencePlatformAuthor(
        this.addObjtime(data)
      );
      this.tableData2 = res.data.data;
    },
    // 表格方法二
    // 综合数据表 点击tab切换调
    async TabjnAnalysisDataStatistics(type) {
      this.tableDatacom = [];
      let data = {
        organId: this.organId,
        analysisType: type ? type : 1,
        type: this.timetype
      };
      let res = await jnAnalysisDataStatistics(this.addObjtime(data));
      // 表头排序
      if (type == 1) {
        this.tableDatacom = JSON.parse(JSON.stringify(this.mediaLinkList));
      } else if (type == 2) {
        this.tableDatacom = JSON.parse(JSON.stringify(this.mediaAttributeList));
      } else {
        this.tableDatacom = JSON.parse(JSON.stringify(this.mediamediaLevel));
      }
      if (res.data.data && res.data.data.length != 0) {
        this.tableDatacom = this.$calcu.comprehensiveData(
          this.tableDatacom,
          res.data.data,
          1
        );
        this.echarts1(res.data.data);
      }
      this.slectAddTableArr = [];
    },
    // 点击标头 扩展它的数据
    async slectAddTable(item) {
      if (Object.values(item)[0] == "合计") {
        return false;
      }
      this.tableDatacom = [];
      let data = {
        organId: this.organId,
        analysisType: 1,
        type: this.timetype
      };
      if (item.mediaLink) {
        data.analysisType = 2;
        data.mediaLink = item.mediaLink;
        if (this.slectAddTableArr.length >= 1) {
          data.mediaLevel = this.slectAddTableArr[0].name;
        }
        //   这里放属性
        this.tableDatacom = JSON.parse(JSON.stringify(this.mediaAttributeList));
      } else if (item.mediaAttribute) {
        data.analysisType = 3;
        data.mediaAttribute = item.mediaAttribute;
        if (this.slectAddTableArr.length >= 1) {
          data.mediaLink = this.slectAddTableArr[0].name;
        }
        //   放级别
        this.tableDatacom = JSON.parse(JSON.stringify(this.mediamediaLevel));
      } else if (item.mediaLevel) {
        data.analysisType = 1;
        data.mediaLevel = item.mediaLevel;
        if (this.slectAddTableArr.length >= 1) {
          data.mediaAttribute = this.slectAddTableArr[0].name;
        }
        this.tableDatacom = JSON.parse(JSON.stringify(this.mediaLinkList));
      }
      let res = await jnAnalysisDataStatistics(this.addObjtime(data));
      // 表头排序
      if (res.data.data && res.data.data.length != 0) {
        this.tableDatacom = this.$calcu.comprehensiveData(
          this.tableDatacom,
          res.data.data,
          1
        );
        this.echarts1(res.data.data);
      }
      // 放在上面的判断里写 会有一种表结构变化了  还没数据的尴尬场面
      // 下面数组的name 是用来v-for做循环  因为在同一table结构下有两个v-for 因此 index不能做key值
      if (item.mediaLink) {
        this.slectAddTableArr.push({
          mediaLink: item.mediaLink,
          name: item.mediaLink
        });
      } else if (item.mediaAttribute) {
        this.slectAddTableArr.push({
          mediaAttribute: item.mediaAttribute,
          name: item.mediaAttribute
        });
      } else if (item.mediaLevel) {
        this.slectAddTableArr.push({
          mediaLevel: item.mediaLevel,
          name: item.mediaLevel
        });
      }
    },
    // 点击标头 收起它的数据
    async slectremoveTable(item, index) {
      this.tableDatacom = [];
      let data = {
        organId: this.organId,
        analysisType: 1,
        type: this.timetype
      };
      if (item.activeTab == undefined) {
        //非第一个
        if (item.mediaLink) {
          data.mediaLink = item.mediaLink;
          data.analysisType = 2;
        } else if (item.mediaAttribute) {
          data.mediaAttribute = item.mediaAttribute;
          data.analysisType = 3;
        } else if (item.mediaLevel) {
          data.analysisType = 1;
          data.mediaLevel = item.mediaLevel;
        }
      } else {
        //第一个
        if (item.activeTab == 1) {
          //为0 就用默认data 为1用 analysisType = 2
          data.analysisType = 2;
        } else if (item.activeTab == 2) {
          //为2用 analysisType = 3
          data.analysisType = 3;
        }
      }
      let res = await jnAnalysisDataStatistics(this.addObjtime(data));
      // 表头排序
      if (item.mediaLink || item.activeTab == 1) {
        //   放属性
        this.tableDatacom = JSON.parse(JSON.stringify(this.mediaAttributeList));
      } else if (item.mediaAttribute || item.activeTab == 2) {
        //   放级别
        this.tableDatacom = JSON.parse(JSON.stringify(this.mediamediaLevel));
      } else if (item.mediaLevel || item.activeTab == 0) {
        //   放环节
        this.tableDatacom = JSON.parse(JSON.stringify(this.mediaLinkList));
      }
      if (res.data.data && res.data.data.length != 0) {
        this.tableDatacom = this.$calcu.comprehensiveData(
          this.tableDatacom,
          res.data.data,
          1
        );
        this.echarts1(res.data.data);
      }
      this.slectAddTableArr.splice(index);
    },
    // 在index页面调 导出PDF
    getPdf() {
      let obj = {
        url: "api/msgquery/analysisBySynthesis/generateReportStream",
        method: "post",
        data: {
          organizationId: this.$store.state.user.organizationId, //机构id
          organizationName: this.$store.state.user.mechanism, //机构名称
          startTime: this.timersArr[0], //开始时间
          endTime: this.timersArr[1], //结束时间
          reportType: "境内",
          reportName: "境内报告",
          // 综合数据统计表
          analysisDataStatisticsVo: {
            organId: this.organId,
            analysisType: 1,
            endTimeStr: this.timersArr[1],
            startTimeStr: this.timersArr[0]
          },
          //预警数据事件类型分类
          analysisPoliticsVo: {
            organId: this.organId,
            eventClass: this.warningName,
            endTimeStr: this.timersArr[1],
            startTimeStr: this.timersArr[0]
          },
          //舆情高发平台统计
          analysisHighPlatformVo: {
            organId: this.organId,
            sort: this.sourt,
            endTimeStr: this.timersArr[1],
            startTimeStr: this.timersArr[0]
          },
          //舆情发布热力分布
          fbHotVo: {
            organId: this.organId,
            state: this.mapInedx,
            endTimeStr: this.timersArr[1],
            startTimeStr: this.timersArr[0]
          },
          warnLike: this.warningNameFirst
        },
        responseType: "blob"
      };

      if (this.picInfoArr[0]) {
        obj.data.viewPicture1 = this.picInfoArr[0];
      }
      if (this.picInfoArr[2]) {
        obj.data.viewPicture3 = this.picInfoArr[2];
      }
      if (this.picInfoArr[3]) {
        obj.data.viewPicture4 = this.picInfoArr[3];
      }
      if (this.picInfoArr[4]) {
        obj.data.viewPicture5 = this.picInfoArr[4];
      }
      if (this.picInfoArr[5]) {
        obj.data.viewPicture6 = this.picInfoArr[5];
      }
      if (this.picInfoArr[6]) {
        obj.data.viewWarn = this.picInfoArr[6];
      }
      let textName = "境内报告.docx";
      // 舆情走势
      this.$nextTick(() => {
        html2canvas(document.querySelector(".InAnalysis-trend")).then(
          resolve => {
            let imgUrl = resolve.toDataURL("image/png");
            obj.data.viewPicture2 = imgUrl;
            this.$parent.$parent.downloadFun(obj, textName);
          }
        );
      });
    }
  },
  created() {
    this.allFunction();
  },
  mounted() {
    this.GetMap([]);
  }
};
</script>

<style scoped>
.InAnalysis {
  margin-top: 16px;
}

.InAnalysis-top {
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
  padding: 24px;
}

.InAnalysis-title {
  width: 100%;
  height: 122px;
  background: #f6fafe;
  border-radius: 4px;
  padding: 24px;
}

.InAnalysis-title .p1 {
  font-size: 24px;
  font-weight: bold;
  color: #333333;
  text-align: center;
}

.InAnalysis-title .p2 {
  font-size: 18px;
  color: #666;
  text-align: center;
  margin-top: 16px;
  font-weight: normal;
}

.InAnalysis-statistical-table {
  width: 100%;
  margin-top: 24px;
}

.InAnalysis-top-title {
  display: flex;
  align-items: center;
}

.InAnalysis-top-title img {
  position: relative;
  top: 2px;
  margin-right: 5px;
}

.InAnalysis-top-title span {
  font-size: 16px;
  color: #333333;
  font-weight: bold;
}

.InAnalysis-tab {
  width: 100%;
  padding: 20px;
  display: flex;
  cursor: pointer;
  font-weight: bold;
}

.InAnalysis-tab-card {
  padding: 7px 19px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #e1e1e1;
  font-size: 16px;
  color: #333333;
  margin-right: 24px;
}

.InAnalysis-tab-card.active {
  background: #e9f4ff;
  border-radius: 4px;
  border-color: transparent;
  color: #2e59ec;
}

.InAnalysis-link {
  padding-left: 20px;
  font-size: 14px;
  color: #666666;
}

.analyse-container {
  width: 50%;
}

.InAnalysis-link-span {
  font-size: 14px;
  color: #2e59ec;
}

.InAnalysis-table {
  width: 976px;
  /* height: 420px; */
  margin-left: 20px;
  margin-top: 16px;

  font-size: 14px;
  font-weight: bold;
  color: #333333;
  float: left;
}

.InAnalysis-table-header {
  width: 100%;
  height: 81px;
  display: flex;
}

.InAnalysis-table-body {
  width: 100%;
  height: 335px;
}

.InAnalysis-table-color {
  background: #f5f6fa;
}

.InAnalysis-1-2 {
  width: 120px;
  height: 81px;
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
  text-align: center;
  line-height: 81px;
}

.InAnalysis-4-1 {
  width: 488px;
  height: 41px;
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
  border-left: 1px solid #fff;
  text-align: center;
  line-height: 41px;
}

.InAnalysis-4-2 {
  width: 488px;
  height: 82px;
  text-align: center;
}

.InAnalysis-1-1 {
  width: 122px;
  height: 40px;
  border-right: 1px solid #fff;
}

.InAnalysis-1-1 + .InAnalysis-1-1 {
  border-left: 1px solid #fff;
}

.InAnalysis-row .InAnalysis-1-1:last-child {
  border-right: none;
}

.InAnalysis-3-2 {
  width: 366px;
  height: 82px;
  text-align: center;
}

.InAnalysis-3-1 {
  width: 366px;
  height: 41px;
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
  border-left: 1px solid #fff;
  text-align: center;
  line-height: 41px;
}

.InAnalysis-row {
  width: 100%;
  height: 42px;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  text-align: center;
  line-height: 40px;
  display: flex;
}

#echarts1 {
  width: 550px;
  height: 420px;
  margin-top: 16px;
}

.InAnalysis-trend {
  position: relative;
  width: 100%;
  height: 434px;
  background: #ffffff;
  border-radius: 4px;
  margin-top: 16px;
  padding: 24px;
}

#echarts2 {
  width: 100%;
  height: 360px;
  position: relative;
}

.InAnalysis-echarts {
  width: 100%;
  height: 490px;
  margin-top: 16px;
  padding: 24px;
  display: flex;
  background: #ffffff;
  border-radius: 4px;
}

.InAnalysis-echarts-div {
  width: 50%;
  height: 100%;
}

.analyse-container {
  background-color: #fff;
}

.analyse-container-img p {
  font-size: 14px;
  text-align: center;
  color: #999999;
}

.InAnalysis-echarts-grey {
  width: 770px;
  height: 380px;
  padding: 16px;
  background: #f5f6fa;
  border-radius: 4px;
  margin-top: 32px;
  margin-left: 20px;
  display: flex;
}

.InAnalysis-echarts-grey-div {
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-radius: 4px;
}

.InAnalysis-fenlei {
  width: 100%;
  /* height: 551px; */
  background: #ffffff;
  border-radius: 4px;
  padding: 24px;
  margin-top: 16px;
  position: relative;
}

.InAnalysis-top-logo {
  position: absolute;
  top: 20px;
  left: 40%;
  width: 20%;
  background: rgba(228, 227, 227, 0.4);
}

.InAnalysis-fenlei-div {
  height: 480px;
  position: relative;
  width: 100%;
  display: flex;
}

.InAnalysis-fenlei-small:nth-child(2) {
  top: 3%;
  right: 0;
  position: absolute;
  width: 50%;
}

.InAnalysis-fenlei-small:nth-child(1) {
  top: 0;
  left: 0;
  position: absolute;
  width: 50%;
}

#echarts5 {
  height: 480px;
}

.InAnalysis-fenlei-content {
  display: flex;
  justify-content: space-between;
}
.InAnalysis-fenlei-content .sourceCell {
  overflow: hidden; /*超出隐藏*/
  white-space: nowrap; /* 强制不换行 */
  text-overflow: ellipsis; /*文字隐藏的格式 */
}
.InAnalysis-fenlei-content-left {
  width: 49%;
}

.InAnalysis-fenlei-content-left-top {
  color: #333333;
  margin-top: 32px;
  display: flex;
  margin-bottom: 25px;
}

.InAnalysis-fenlei-content-left-top li {
  padding: 0 24px;
  height: 36px;
  border-radius: 4px;
  text-align: center;
  line-height: 36px;
  border-radius: 4px;
  border: 1px solid #e1e1e1;
  margin-right: 24px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.item-Li {
  background: #e9f4ff;
  color: #2e59ec;
}

.InAnalysis-fenlei-content-right {
  width: 49%;
  margin-top: 52px;
}

#echarts6 {
  width: 100%;
  height: 800px;
}

.map {
  position: absolute;
  bottom: 100px;
  right: 550px;
}

.echarts-legend {
  padding-right: 16px;
  justify-content: space-between;
  display: flex;
  position: absolute;
  right: 2.5%;
  top: 6%;
  width: 264px;
  height: 72px;
  border: 1px solid #e4e6ec;
}

.echarts-legend-line {
  right: -51%;
  top: 26%;
  position: relative;
  width: 1px;
  height: 40px;
  background: #e4e6ec;
}

.noYujing {
  font-size: 12px;
  color: #5a6474;
}

.legend-top {
  margin-left: 45px;
}

.table {
  width: 100%;
  border: 1px solid #d8d8d8;
}

.table th {
  height: 40px;
  background: #f5f6fa;
  text-align: center;
}

.table td {
  text-align: center;
}

.table tbody .tr {
  background: #f5f6fa;
}

.pointer {
  cursor: pointer;
}
.dataShowImg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.analyse-container-img-flex {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.pointor {
  cursor: pointer;
}
.fbTotalNum {
  display: inline-block;
  cursor: pointer;
  width: 100%;
  height: 100%;
}
</style>
