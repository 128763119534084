// 综合分析
import request from '@/api/request.js'

//       境内  
// 涉政图表
export function getPolitics(data) {
    return request({
        url: 'api/msgquery/analysisBySynthesis/politicsData',
        method: 'post',
        data: data
    })
}
// 表格
export function getPoliticsList(data) {
    return request({
        url: 'api/msgquery/analysisBySynthesis/politicsDataList',
        method: 'post',
        data: data
    })
}
// 舆情高发平台统计
export function jnAnalysisHighIncidencePlatform(data) {
    return request({
        url: 'api/msgquery/analysisBySynthesis/jnAnalysisHighIncidencePlatform',
        method: 'post',
        data: data
    })
}
// 舆情高发平台作者统计
export function jnAnalysisHighIncidencePlatformAuthor(data) {
    return request({
        url: 'api/msgquery/analysisBySynthesis/jnAnalysisHighIncidencePlatformAuthor',
        method: 'post',
        data: data
    })
}
// 舆情走势 
export function jnYuQTrend(data) {
    return request({
        url: 'api/msgquery/analysisBySynthesis/jnYuQTrend',
        method: 'post',
        data: data
    })
}
// 综合数据统计表-各环节数据占比 
export function jnAnalysisDataStatistics(data) {
    return request({
        url: 'api/msgquery/analysisBySynthesis/jnAnalysisDataStatistics',
        method: 'post',
        data: data
    })
}
// 舆情发布热力分布
export function yqFbHotList(data) {
    return request({
        url: 'api/msgquery/analysisBySynthesis/yqFbHotList',
        method: 'post',
         data
    })
}
//      境外
// 舆情走势
export function jwYuQTrend(data) {
    return request({
        url: 'api/msgquery/analysisBySynthesis/jwYuQTrend',
        method: 'post',
        data: data
    })
}
// 综合数据统计表-各环节数据占比
export function jwAnalysisDataStatistics(data) {
    return request({
        url: 'api/msgquery/analysisBySynthesis/jwAnalysisDataStatistics',
        method: 'post',
        data: data
    })
}
//      跟评 
// 周期内每日跟评走势及方案信息数量
export function gpDayTrendAndNum(data) {
    return request({
        url: 'api/msgquery/analysisBySynthesis/gpDayTrendAndNum',
        method: 'post',
        data: data
    })
}
// 周期内各监测平台跟评数量及方案信息数量图
export function gpDayPlatformAndNum(data) {
    return request({
        url: 'api/msgquery/analysisBySynthesis/gpDayPlatformAndNum',
        method: 'post',
        data: data
    })
}
// 方案统计周期内发评人发布数量Top10
export function gpDayPlatformReviewer(data) {
    return request({
        url: 'api/msgquery/analysisBySynthesis/gpDayPlatformReviewer',
        method: 'post',
        data: data
    })
}
// 监测方案筛选下拉列表
export function gpMonitorList(data) {
    return request({
        url: 'api/msgquery/analysisBySynthesis/gpMonitorList',
        method: 'post',
         data
    })
}

//实时信息综合数据统计表
export function climap(data) {
    return request({
        url: 'api/msgquery/InforMationAnalysis/jnAnalysisDataStatistics',
        method: 'post',
        data
    })
}

//实时信息舆情走势
export function trend(data) {
    return request({
        url: 'api/msgquery/InforMationAnalysis/jnYuQTrend',
        method: 'post',
        data
    })
}

//实时信息舆情高发平台
export function platform(data) {
    return request({
        url: 'api/msgquery/InforMationAnalysis/jnAnalysisHighIncidencePlatform',
        method: 'post',
        data
    })
}

//实时信息舆情高发平台作者统计
export function platformA(data) {
    return request({
        url: 'api/msgquery/InforMationAnalysis/jnAnalysisHighIncidencePlatformAuthor',
        method: 'post',
        data
    })
}

//实时信息热力地图
export function getMap(data) {
    return request({
        url: 'api/msgquery/InforMationAnalysis/yqFbHotList',
        method: 'post',
        data
    })
}
