<template>
    <div id="zb_realtime">
        <div class="zb_realtime_box">
            <div id="zb_realtime_prelude">
                <p>实时信息 综合分析</p>
                <p>{{time[0]}} 至 {{time[1]}}</p>
            </div>
            <!-- 综合数据统计表 -->
            <div id="zb_realtime_climap">
                <div class="zb_realtime_title">
                    <img src="../../assets/img/views/topqq.png" />
                    <span>综合数据统计表</span>
                </div>
                <div class="zb_realtime_climap_option">
                    <div class="zb_realtime_botton zb_realtime_bottonS">媒体环节</div>
                </div>
                <p class="zb_realtime_stext">全部环节：</p>
                <div id="zb_realtime_climap_table">
                    <!-- 表格 -->
                    <div id="zb_realtime_climap_tableL">
                        <table>
                            <thead>
                                <tr>
                                    <th rowspan="2">环节</th>
                                    <th colspan="4">舆情数据</th>
                                    <th colspan="3">预警数据</th>
                                </tr>
                                <tr>
                                    <th class="zb_realtime_table_white">负面</th>
                                    <th class="zb_realtime_table_white">中性</th>
                                    <th class="zb_realtime_table_white">正面</th>
                                    <th class="zb_realtime_table_white">合计</th>
                                    <th class="zb_realtime_table_white">负面</th>
                                    <th class="zb_realtime_table_white">非负面</th>
                                    <th class="zb_realtime_table_white">合计</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>电视</th>
                                    <th v-for="index in 7" :key="index">
                                        {{ climapData.table["电视"][index-1]?climapData.table["电视"][index-1]:0 }}
                                    </th>
                                </tr>
                                <tr>
                                    <th>纸媒</th>
                                    <th class="zb_realtime_table_white" v-for="index in 7" :key="index">
                                        {{ climapData.table["纸媒"][index-1]?climapData.table["纸媒"][index-1]:0 }}
                                    </th>
                                </tr>
                                <tr>
                                    <th>网站</th>
                                    <th v-for="index in 7" :key="index">
                                        {{ climapData.table["网站"][index-1]?climapData.table["网站"][index-1]:0 }}
                                    </th>
                                </tr>
                                <tr>
                                    <th>微博</th>
                                    <th class="zb_realtime_table_white" v-for="index in 7" :key="index">
                                        {{ climapData.table["微博"][index-1]?climapData.table["微博"][index-1]:0 }}
                                    </th>
                                </tr>
                                <tr>
                                    <th>微信公众号</th>
                                    <th v-for="index in 7" :key="index">
                                        {{ climapData.table["微信公众号"][index-1]?climapData.table["微信公众号"][index-1]:0 }}
                                    </th>
                                </tr>
                                <tr>
                                    <th>移动客户端</th>
                                    <th class="zb_realtime_table_white" v-for="index in 7" :key="index">
                                        {{ climapData.table["移动客户端"][index-1]?climapData.table["移动客户端"][index-1]:0 }}
                                    </th>
                                </tr>
                                <tr>
                                    <th>互动栏目</th>
                                    <th v-for="index in 7" :key="index">
                                        {{ climapData.table["互动栏目"][index-1]?climapData.table["互动栏目"][index-1]:0 }}
                                    </th>
                                </tr>
                                <tr>
                                    <th>视频</th>
                                    <th class="zb_realtime_table_white" v-for="index in 7" :key="index">
                                        {{ climapData.table["视频"][index-1]?climapData.table["视频"][index-1]:0 }}
                                    </th>
                                </tr>
                                <tr>
                                    <th>合计</th>
                                    <th v-for="index in 7" :key="index">
                                        {{ climapData.table.all[index-1]?climapData.table.all[index-1]:0 }}
                                    </th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- 饼图 -->
                    <div id="zb_realtime_climap_tableR">
                        <div v-if="climapData.echartsShow" id="zb_realtime_climap_echarts"></div>
                        <div class="zb_realtime_none" v-else>
                            <img src="../../assets/img/views/analyse-img.png"/>
                            <p>综合数据统计图</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <!-- 舆情走势 -->
        <div class="zb_realtime_box">
            <div class="zb_realtime_title">
                <img src="../../assets/img/views/topqq.png" />
                <span>舆情走势</span>
            </div>
            <div id="zb_realtime_trend">
                <div id="zb_realtime_trend_echarts"></div>
                <div id="zb_realtime_trend_sub">
                    <div id="zb_realtime_trend_middel"></div>
                    <div>
                        <div id="zb_realtime_trend_text">
                            <span>正</span>
                            <span>中</span>
                            <span>负</span>
                        </div>
                        <div style="display: flex; text-align: right; margin-top: 7px">
                            <div style=" display: flex; flex-direction: column; color: #5a6474; font-size: 12px;">
                                <span>非预警</span>
                                <span>预警</span>
                            </div>
                            <div style="display: flex; flex-direction: column; margin-left: 8px">
                                <span style="width: 16px; height: 16px; background: #a8faf2"></span>
                                <span style="width: 16px; height: 16px; background: #5be6d9"></span>
                            </div>
                            <div style="display: flex; flex-direction: column; margin-left: 8px">
                                <span style="width: 16px; height: 16px; background: #b0cbff"></span>
                                <span style="width: 16px; height: 16px; background: #6398ff"></span>
                            </div>
                            <div style="display: flex; flex-direction: column; margin-left: 8px">
                                <span style="width: 16px; height: 16px; background: #ffc2dd"></span>
                                <span style="width: 16px; height: 16px; background: #ff70af"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 各环节负面数据占比 各环节非负面数据占比 -->
        <div id="zb_realtime_proportion" class="zb_realtime_box">
            <div>
                <div class="zb_realtime_title">
                    <img src="../../assets/img/views/topqq.png" />
                    <span>各环节负面数据占比</span>
                </div>
                <div class="zb_realtime_proportion_box">
                    <div class="zb_realtime_proportion_echarts">
                        <div v-if="climapData.show1" id="zb_realtime_proportion_echarts1"></div>
                        <div class="zb_realtime_none" v-else>
                            <img src="../../assets/img/views/analyse-img.png" />
                            <p>负面各环节数据占总数据饼图</p>
                        </div>
                    </div>
                    <div class="zb_realtime_proportion_echarts">
                        <div v-if="climapData.show2" id="zb_realtime_proportion_echarts2"></div>
                        <div class="zb_realtime_none" v-else>
                            <img src="../../assets/img/views/analyse-img.png" />
                            <p>各环节负面预警数据占总预警数据饼图</p>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="zb_realtime_title">
                    <img src="../../assets/img/views/topqq.png" />
                    <span>各环节非负面数据占比</span>
                </div>
                <div class="zb_realtime_proportion_box">
                    <div class="zb_realtime_proportion_echarts">
                        <div v-if="climapData.show3" id="zb_realtime_proportion_echarts3"></div>
                        <div class="zb_realtime_none" v-else>
                            <img src="../../assets/img/views/analyse-img.png" />
                            <p>各环节非负面数据占总数据饼图</p>
                        </div>
                    </div>
                    <div class="zb_realtime_proportion_echarts">
                        <div v-if="climapData.show4" id="zb_realtime_proportion_echarts4"></div>
                        <div class="zb_realtime_none" v-else>
                            <img src="../../assets/img/views/analyse-img.png" />
                            <p>各环节非负面预警数据占总预警数据饼图</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 预警数据事件类型分类 -->
        <div class="zb_realtime_box">
            <div class="zb_realtime_title">
                <img src="../../assets/img/views/topqq.png" />
                <span>预警数据事件类型分类</span>
            </div>
            <div id="zb_realtime_warning_box">
                <div>
                    <div v-if="warningData.show" id="zb_realtime_warning_echarts"></div>
                    <div class="zb_realtime_none" v-else>
                        <img src="../../assets/img/views/analyse-img.png" />
                        <p>预警数据事件类型分类</p>
                    </div>
                </div>
                <div>
                    <!-- <div>{{ "全部数据" }}:{{ 2 }}</div> -->
                    <el-table
                        @row-click="()=>{}"
                        :header-cell-style="{background:'#F5F6FA',color:'#333333'}"
                        :data="warningData.tableData"
                        max-height="385"
                        id="zb_realtime_warning_table"
                    >
                        <el-table-column prop="eventClass" label="预警类型" align="center"></el-table-column>
                        <el-table-column prop="yjTypeNum" label="数量" align="center"></el-table-column>
                        <el-table-column prop="total" label="数据占比" align="center">
                            <!-- <template slot-scope="scope">
                                <span>{{((scope.row.yjTypeNum/PoliticsTotal)*100).toFixed(2)}}%</span>
                            </template> -->
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
        <!-- 高发平台统计 -->
        <div class="zb_realtime_box">
            <div class="zb_realtime_title">
                <img src="../../assets/img/views/topqq.png" />
                <span>舆情高发平台统计（TOP 10）</span>
            </div>
            <div id="zb_realtime_platform">
                <div>
                    <ul class="zb_realtime_climap_option">
                        <li @click="switchType(0)" class="zb_realtime_botton" :class="platformData.sort==0?'zb_realtime_bottonS':''">
                            整体数据
                        </li>
                        <li @click="switchType(1)" class="zb_realtime_botton" :class="platformData.sort==1?'zb_realtime_bottonS':''">
                            负面
                        </li>
                        <li @click="switchType(2)" class="zb_realtime_botton" :class="platformData.sort==2?'zb_realtime_bottonS':''">
                            非负面
                        </li>
                    </ul>
                    <el-table
                        :header-cell-style="platformData.tableStyle"
                        :cell-style="{padding:'0px',height:'40px'}"
                        :data="platformData.table1data"
                        stripe
                        class="zb_realtime_platform_table"
                        @row-click="getPlatformA($event.source)"
                    >
                        <el-table-column prop="source" label="发布平台" align="center"></el-table-column>
                        <el-table-column prop="fbTotalNum" label="发布总数" align="center"></el-table-column>
                        <el-table-column prop="fmNum" label="负面数据" align="center"></el-table-column>
                        <el-table-column prop="ffmNum" label="非负面数据" align="center"></el-table-column>
                    </el-table>
                </div>
                <div>
                    <div style="margin-top:38px" class="zb_realtime_platform_title">
                        {{ platformData.media }}账号发布本排行（TOP20）
                    </div>
                    <el-table
                        :header-cell-style="platformData.tableStyle"
                        :cell-style="{padding:'0px',height:'40px'}"
                        :data="platformData.table2data"
                        max-height="444"
                        stripe
                        class="zb_realtime_platform_table"
                    >
                        <el-table-column prop="author" label="账户/账号" align="center"></el-table-column>
                        <el-table-column prop="fbTotalNum" label="发布总数" align="center"></el-table-column>
                        <el-table-column prop="fmNum" label="负面数据" align="center"></el-table-column>
                        <el-table-column prop="ffmNum" label="非负面数据" align="center"></el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
        <!-- 热力分布 -->
        <div class="zb_realtime_box">
            <div class="zb_realtime_title">
                <img src="../../assets/img/views/topqq.png" />
                <span>舆情发布热力分布</span>
            </div>
            <ul class="zb_realtime_climap_option">
                <li @click="hotType(1)" class="zb_realtime_botton" :class="mapData.select==1?'zb_realtime_bottonS':''">
                    管辖地
                </li>
                <li @click="hotType(2)" class="zb_realtime_botton" :class="mapData.select==2?'zb_realtime_bottonS':''">
                    归属地
                </li>
            </ul>
            <div id="zb_realtime_hot">
                <div id="zb_realtime_hot_echarts" v-loading="mapData.loading"></div>
                <img id="zb_realtime_hot_map" src="../../assets/img/views/map.png" />
            </div>
            
        </div>
    </div>
</template>

<script>
import {
    climap,
    trend,
    platform,
    platformA,
    getMap
} from "../../api/analyse";
import * as echarts from "echarts";
import map from "../../assets/js/China1.json";
import html2canvas from "html2canvas";
export default {
    props: {
        timetype: {
            type: Number
        },
        timers: {
            type: Array,
            default:()=>[]
        },
        organId:Number
    },
    data(){
        return{
            first:true,
            time:[],
            //综合数据统计
            climapData:{
                echartsShow:false,
                table:{
                    "电视":[],
                    "纸媒":[],
                    "网站":[],
                    "微博":[],
                    "微信公众号":[],
                    "移动客户端":[],
                    "互动栏目":[],
                    "视频":[],
                    all:[0,0,0,0,0,0,0],
                },
                URL1:null,
                URL2:null,
                URL3:null,
                URL4:null,
                URL5:null,
                show1:false,
                show2:false,
                show3:false,
                show4:false,
            },
            platformData:{
                tableStyle:{
                    background: '#fafafa',
                    padding: '0px',
                    height: '40px',
                    color: '#333',
                },
                sort:0,//0-整体数据 1=负面 2-非负面
                table1data:[],
                table2data:[]
            },
            warningData:{
                show:false,
                tableData:[],
                URL:null
            },
            mapData:{
                select:1,
                loading:false
            }
        }
    },
    methods:{
        //刷新页面数据
        refresh(){
            if(this.first)return;
            this.time = JSON.parse(JSON.stringify(this.timers));
            this.getClimap();
            this.getTrend();
            this.getPlatform();
            this.getMap();
        },
        //获取综合数据统计
        getClimap(){
            let data = {
                organId: this.organId,
                analysisType: 1,
                type: this.timetype,
            };
            data.startTimeStr = this.time[0];
            data.endTimeStr = this.time[1];
            climap(data).then(res=>{
                if(res.data.code == 200 && res.data.data){
                    //生成表格
                    this.foundClimapTable(res.data.data);
                    //生成饼图
                    this.foundClimapEcharts1(res.data.data);
                    this.foundClimapEcharts2(res.data.data);
                    this.foundClimapEcharts3(res.data.data);
                    this.foundClimapEcharts4(res.data.data);
                    this.foundClimapEcharts5(res.data.data);
                    this.warningEcharts(res.data.data);
                }
            });
        },
        foundClimapTable(data){
            let output = {
                "电视":[],
                "纸媒":[],
                "网站":[],
                "微博":[],
                "微信公众号":[],
                "移动客户端":[],
                "互动栏目":[],
                "视频":[],
                all:[0,0,0,0,0,0,0],
            };
            data.forEach(x=>{
                output[x.mediaLink] = [
                    x.yqNum2?x.yqNum2:0,
                    x.yqNum1?x.yqNum1:0,
                    x.yqNum0?x.yqNum0:0,
                    (x.yqNum2?x.yqNum2:0)+(x.yqNum1?x.yqNum1:0)+(x.yqNum0?x.yqNum0:0),
                    x.yjNumfm?x.yjNumfm:0,
                    x.yjNumffm?x.yjNumffm:0,
                    (x.yjNumfm?x.yjNumfm:0)+(x.yjNumffm?x.yjNumffm:0)
                ];
                output.all[0]+=output[x.mediaLink][0];
                output.all[1]+=output[x.mediaLink][1];
                output.all[2]+=output[x.mediaLink][2];
                output.all[3]+=output[x.mediaLink][3];
                output.all[4]+=output[x.mediaLink][4];
                output.all[5]+=output[x.mediaLink][5];
                output.all[6]+=output[x.mediaLink][6];
            });
            this.climapData.table = output;
        },
        foundClimapEcharts1(data){
            if(data.length>0){
                this.climapData.echartsShow = true;
                let yjTotal = [];
                let yqTotal = [];
                data.forEach(e => {
                    if((e.yjNumffm + e.yjNumfm)!=0){
                        yjTotal.push({
                            value: e.yjNumffm + e.yjNumfm,
                            name: e.mediaLink
                        });
                    }
                    if((e.yqNum0 + e.yqNum1 + e.yqNum2)!=0){
                        yqTotal.push({
                            value: e.yqNum0 + e.yqNum1 + e.yqNum2,
                            name: e.mediaLink
                        });
                    }
                });
                this.$nextTick(()=>{
                    let myChart = echarts.init(document.getElementById("zb_realtime_climap_echarts"));
                    let option = {
                        title: 
                        [{
                            text: "综合数据统计图",
                            left: "48%",
                            top: "95%",
                            textAlign: "center",
                            textStyle: {
                            color: "#999999",
                            fontSize: 12,
                            fontWeight: "normal"
                            }
                        }],
                        tooltip: {
                        formatter: "{b} {d}%"
                        },
                        series: 
                        [{
                            name: "Access From",
                            type: "pie",
                            selectedMode: "single",
                            radius: ["0%", "40%"],
                            label: {
                            position: "inner",
                            fontSize: 14,
                            normal: {
                                show: false
                            },
                            formatter: "{b}:\n{d}%"
                            },
                            data: yqTotal
                        },
                        {
                            name: "牛逼",
                            type: "pie",
                            radius: ["45%", "60%"],
                            labelLine: {
                            length: 30
                            },
                            label: {
                            formatter: "{b}:\n{d}%"
                            },
                            data: yjTotal
                        }]
                    };
                    myChart.setOption(option);
                    let that = this;
                    myChart.on("finished", function() {
                        that.climapData.URL1 = myChart.getDataURL();
                    });
                });
            }else{
                this.climapData.echartsShow=false;
            }
        },
        foundClimapEcharts2(data){
            let source = [["Product", "Sales", "Price"]];
            data.forEach(v => {
                if (v.yqNum2 != 0) {
                    source.push([v.mediaLink, v.yqNum2, 1]);
                }
            });
            if (source.length > 1){
                this.climapData.show1 = true;
                this.$nextTick(()=>{
                    let option = {
                        color: [
                        "#92F5A8",
                        "#FEBC3F",
                        "#FFD88F",
                        "#184CFF",
                        "#6F90FF",
                        "#0098FF",
                        "#77C8FF",
                        "#00D0A3"
                        ],
                        tooltip: {},
                        title: [
                        {
                            text: "负面各环节数据占总数据饼图",
                            left: "50%",
                            top: "85%",
                            textAlign: "center",
                            textStyle: {
                                color: "#999999",
                                fontSize: 12,
                                fontWeight: "normal"
                            }
                        }
                        ],
                        dataset: 
                        [{
                            source: source
                        },
                        {
                            transform: {
                            type: "filter",
                            config: {
                                dimension: "Price",
                                value: 1
                            }
                            }
                        },
                        {
                            transform: {
                                type: "filter",
                                config: {
                                    dimension: "Price",
                                    value: 2
                                }
                            }
                        }],
                        label: {
                        formatter: "{b}\n{d}%"
                        },
                        series: [
                        {
                            name: "全部",
                            type: "pie",
                            center: ["50%", "45%"],
                            radius: 80,
                            minAngle: 10,
                            datasetIndex: 1,
                            itemStyle: {
                                borderWidth: 1,
                                borderColor: "#fff"
                            }
                        }
                        ]
                    };
                    let myChart = echarts.init(document.getElementById("zb_realtime_proportion_echarts1"));
                    myChart.setOption(option);
                    let that = this;
                    myChart.on("finished", function() {
                        that.climapData.URL2 = myChart.getDataURL();
                    });
                })
            }else{
                this.climapData.show1 = false;
            }
        },
        foundClimapEcharts3(data){
            let source = [["Product", "Sales", "Price"]];
            data.forEach(v => {
                if (v.yjNumfm != 0) {
                    source.push([v.mediaLink, v.yjNumfm, 2]);
                }
            });
            if (source.length > 1) {
                this.climapData.show2 = true;
                this.$nextTick(()=>{
                    let option = {
                        color: [
                        "#92F5A8",
                        "#FEBC3F",
                        "#FFD88F",
                        "#184CFF",
                        "#6F90FF",
                        "#0098FF",
                        "#77C8FF",
                        "#00D0A3"
                        ],
                        tooltip: {},
                        title: [
                        {
                            text: "各环节负面预警数据占总预警数据饼图",
                            left: "50%",
                            top: "85%",
                            textAlign: "center",
                            textStyle: {
                            color: "#999999",
                            fontSize: 12,
                            fontWeight: "normal"
                            }
                        }
                        ],
                        dataset: [
                        {
                            source: source
                        },
                        {
                            transform: {
                            type: "filter",
                            config: {
                                dimension: "Price",
                                value: 1
                            }
                            }
                        },
                        {
                            transform: {
                            type: "filter",
                            config: {
                                dimension: "Price",
                                value: 2
                            }
                            }
                        }
                        ],
                        label: {
                        formatter: "{b}\n{d}%"
                        },
                        series: [
                        {
                            name: "负面",
                            type: "pie",
                            center: ["50%", "45%"],
                            radius: 80,
                            minAngle: 10,
                            datasetIndex: 2,
                            itemStyle: {
                            borderWidth: 1,
                            borderColor: "#fff"
                            }
                        }
                        ]
                    };
                    let myChart = echarts.init(document.getElementById("zb_realtime_proportion_echarts2"));
                    myChart.setOption(option);
                    let that = this;
                    myChart.on("finished", function() {
                        that.climapData.URL3 = myChart.getDataURL();
                    });
                })
            }else{
                this.climapData.show2 = false;
            }
        },
        foundClimapEcharts4(data){
            let source = [["Product", "Sales", "Price"]];
            data.forEach(v => {
                if (v.yqNum0 + v.yqNum1 != 0) {
                    source.push([v.mediaLink, v.yqNum0 + v.yqNum1, 1]);
                }
            });
            if (source.length > 1){
                this.climapData.show3 = true;
                this.$nextTick(()=>{
                    let option = {
                        color: [
                        "#92F5A8",
                        "#FEBC3F",
                        "#FFD88F",
                        "#184CFF",
                        "#6F90FF",
                        "#0098FF",
                        "#77C8FF",
                        "#00D0A3"
                        ],
                        title: [
                        {
                            text: "各环节非负面数据占总数据饼图",
                            left: "50%",
                            top: "85%",
                            textAlign: "center",
                            textStyle: {
                            color: "#999999",
                            fontSize: 12,
                            fontWeight: "normal"
                            }
                        }
                        ],
                        tooltip: {},
                        dataset: [
                        {
                            source: source
                        },
                        {
                            transform: {
                            type: "filter",
                            config: {
                                dimension: "Price",
                                value: 1
                            }
                            }
                        },
                        {
                            transform: {
                            type: "filter",
                            config: {
                                dimension: "Price",
                                value: 2
                            }
                            }
                        }
                        ],
                        label: {
                        formatter: "{b}:\n{d}%"
                        },
                        series: [
                        {
                            name: "全部",
                            type: "pie",
                            center: ["50%", "45%"],
                            radius: 80,
                            minAngle: 10,
                            datasetIndex: 1,
                            itemStyle: {
                            borderWidth: 1,
                            borderColor: "#fff"
                            }
                        }
                        ]
                    };
                    let myChart = echarts.init(document.getElementById("zb_realtime_proportion_echarts3"));
                    myChart.setOption(option);
                    let that = this;
                    myChart.on("finished", function() {
                        that.climapData.URL4 = myChart.getDataURL();
                    });
                })
            }else{
                this.climapData.show3 = false;
            }
        },
        foundClimapEcharts5(data){
            let source = [["Product", "Sales", "Price"]];
            data.forEach(v => {
                if (v.yjNumffm != 0) {
                    source.push([v.mediaLink, v.yjNumffm, 2]);
                }
            });
            if (source.length > 1) {
                this.climapData.show4 = true;
                this.$nextTick(()=>{
                    let option = {
                        color: [
                        "#92F5A8",
                        "#FEBC3F",
                        "#FFD88F",
                        "#184CFF",
                        "#6F90FF",
                        "#0098FF",
                        "#77C8FF",
                        "#00D0A3"
                        ],
                        title: [
                        {
                            text: "各环节非负面预警数据占总预警数据饼图",
                            left: "50%",
                            top: "85%",
                            textAlign: "center",
                            textStyle: {
                            color: "#999999",
                            fontSize: 12,
                            fontWeight: "normal"
                            }
                        }
                        ],
                        tooltip: {},
                        dataset: [
                        {
                            source: source
                        },
                        {
                            transform: {
                            type: "filter",
                            config: {
                                dimension: "Price",
                                value: 1
                            }
                            }
                        },
                        {
                            transform: {
                            type: "filter",
                            config: {
                                dimension: "Price",
                                value: 2
                            }
                            }
                        }
                        ],
                        label: {
                        formatter: "{b}:\n{d}%"
                        },
                        series: [
                        {
                            name: "负面",
                            type: "pie",
                            center: ["50%", "45%"],
                            radius: 80,
                            minAngle: 10,
                            datasetIndex: 2,
                            itemStyle: {
                            borderWidth: 1,
                            borderColor: "#fff"
                            }
                        }
                        ]
                    };
                    let myChart = echarts.init(document.getElementById("zb_realtime_proportion_echarts4"));
                    myChart.setOption(option);
                    let that = this;
                    myChart.on("finished", function() {
                        that.climapData.URL5 = myChart.getDataURL();
                    });
                })
            }else{
                this.climapData.show4 = false;
            }
        },
        //获取舆情走势
        getTrend(){
            let data = {
                organId: this.organId,
                type: this.timetype
            };
            data.startTimeStr = this.time[0];
            data.endTimeStr = this.time[1];
            trend(data).then(res=>{
                if(res.data.code == 200 && res.data.data){
                    let dataList = res.data.data;
                    let timeEnd = this.timers[1].split(" ")[0];
                    let timeStar = this.timers[0].split(" ")[0];
                    let numDate = this.getDays(timeEnd, timeStar);
                    let data = new Date(timeEnd+" "+new Date().getHours() + ":00:00");
                    let timeThird = this.getTimeSentiment(numDate,numDate+1,1,data);
                    timeThird = this.getDateList(dataList, timeThird);
                    timeThird.forEach(item => {
                        item.yjzs = item.yjNum0 + item.yjNum1 + item.yjNum2;
                        item.ztzs =
                            item.yjNum0 +
                            item.yjNum1 +
                            item.yjNum2 +
                            item.fyjNum0 +
                            item.fyjNum1 +
                            item.fyjNum2;
                    });
                    timeThird = this.getNumSentiment(timeThird, 1);
                    this.$nextTick(()=>{
                        console.log(timeThird)
                        let myChart = echarts.init(document.getElementById("zb_realtime_trend_echarts"));
                        let option = {
                            grid: {
                            left: "1%",
                            right: "2%",
                            bottom: "15%",
                            top: "25%",
                            containLabel: true
                            },
                            tooltip: {
                            trigger: "axis"
                            },
                            legend: [
                            {
                                orient: "horizontal",
                                right: "10%",
                                align: "left",
                                top: "-5",
                                itemWidth: 27,
                                itemHeight: 16,
                                data: [
                                {
                                    name: "舆情预警走势"
                                }
                                ]
                            },
                            {
                                right: "10%",
                                align: "left",
                                top: "25",
                                itemWidth: 27,
                                itemHeight: 16,
                                data: [
                                {
                                    name: "整体舆情走势"
                                }
                                ]
                            }
                            ],
                            dataZoom: [
                            {
                                type: "inside"
                            },
                            {
                                left: "5%",
                                right: "5%"
                            }
                            ],
                            xAxis: {
                            type: "category",
                            data: timeThird.map(e => e.time),
                            borderColor: "#D8D8D8",
                            axisLine: {
                                lineStyle: {
                                color: "#D8D8D8"
                                }
                            },
                            axisTick: {
                                show: false
                            }
                            },
                            yAxis: [
                            {
                                name: "总量",
                                type: "value",
                                axisLine: {
                                lineStyle: {
                                    color: "#CCCCCC",
                                    fontSize: 12
                                }
                                },
                                splitLine: {
                                lineStyle: {
                                    color: "#E5E5E5",
                                    type: "dashed"
                                }
                                },
                                minInterval: 1, //将刻度的最小间距设置为1
                                boundaryGap: ["0%", "50%"]
                            },
                            {
                                name: "增量",
                                type: "value",
                                axisLine: {
                                lineStyle: {
                                    color: "#CCCCCC",
                                    fontSize: 12
                                }
                                },
                                splitLine: {
                                lineStyle: {
                                    color: "#E5E5E5",
                                    type: "dashed"
                                }
                                },
                                minInterval: 1, //将刻度的最小间距设置为1
                                boundaryGap: ["0%", "50%"]
                            }
                            ],
                            series: [
                            {
                                name: "舆情预警走势",
                                type: "line",
                                symbol: "none",
                                itemStyle: {
                                normal: {
                                    color: "#34CAFF",
                                    label: {
                                    // show: true
                                    }
                                }
                                },
                                data: timeThird.map(e => e.yjzs)
                            },
                            {
                                name: "整体舆情走势",
                                type: "line",
                                symbol: "none",
                                yAxisIndex: 1,
                                itemStyle: {
                                normal: {
                                    color: "#FF5670",
                                    label: {
                                    // show: true
                                    }
                                }
                                },
                                data: timeThird.map(e => e.ztzs)
                            },
                            {
                                name: "预警",
                                type: "bar",
                                symbol: "none",
                                stack: "正",
                                itemStyle: {
                                color: "#5BE6D9"
                                },
                                barWidth: 6,
                                data: timeThird.map(e => e.yjNum0)
                            },
                            {
                                name: "非预警",
                                type: "bar",
                                symbol: "none",
                                stack: "正",
                                itemStyle: {
                                color: "#A8FAF2"
                                },
                                barWidth: 6,
                                data: timeThird.map(e => e.fyjNum0)
                            },
                            {
                                name: "预警",
                                type: "bar",
                                symbol: "none",
                                stack: "中",
                                itemStyle: {
                                color: "#6398FF"
                                },
                                barWidth: 6,
                                data: timeThird.map(e => e.yjNum1)
                            },
                            {
                                name: "非预警",
                                type: "bar",
                                symbol: "none",
                                stack: "中",
                                itemStyle: {
                                color: "#B0CBFF"
                                },
                                barWidth: 6,
                                data: timeThird.map(e => e.fyjNum1)
                            },
                            {
                                name: "预警",
                                type: "bar",
                                symbol: "none",
                                stack: "负",
                                itemStyle: {
                                color: "#FF70AF"
                                },
                                barWidth: 6,
                                data: timeThird.map(e => e.yjNum2)
                            },
                            {
                                name: "非预警",
                                type: "bar",
                                symbol: "none",
                                stack: "负",
                                itemStyle: {
                                color: "#FFC2DD"
                                },
                                barWidth: 6,
                                data: timeThird.map(e => e.fyjNum2)
                            }
                            ]
                        };
                        myChart.setOption(option);
                    });
                }
            })
        },
        //获取高发平台
        getPlatform(){
            let data = {
                organId: this.organId,
                sort: this.platformData.sort,
                type: this.timetype
            };
            data.startTimeStr = this.time[0];
            data.endTimeStr = this.time[1];
            platform(data).then(res=>{
                if(res.data.code == 200 && res.data.data && res.data.data.length > 0){
                    this.platformData.table1data = res.data.data;
                    this.getPlatformA(this.platformData.table1data[0].source);
                }
            })
        },
        //获取高发作者
        getPlatformA(str){
            this.platformData.table2data = [];
            this.platformData.media = str;
            let data = {
                organId: this.organId,
                source: str,
                type: this.timetype
            };
            data.startTimeStr = this.time[0];
            data.endTimeStr = this.time[1];
            platformA(data).then(res=>{
                if(res.data.code == 200 && res.data.data && res.data.data.length > 0){
                    this.platformData.table2data = res.data.data;
                }
            })
        },
        getDays(strDateStart, strDateEnd) {
            let strSeparator = "-";
            let oDate1;
            let oDate2;
            let iDays;
            oDate1 = strDateStart.split(strSeparator);
            oDate2 = strDateEnd.split(strSeparator);
            let strDateS = new Date(oDate1[0], oDate1[1] - 1, oDate1[2]);
            let strDateE = new Date(oDate2[0], oDate2[1] - 1, oDate2[2]);
            iDays = parseInt(Math.abs(strDateS - strDateE) / 1000 / 60 / 60 / 24);
            return iDays;
        },
        getTimeSentiment(data, num, type,myDate) {
            let DataList = [];
            myDate.setDate(myDate.getDate() - data);
            let dateArray = [];
            let flag = 1;
            let getMonth;
            let getDate;
            for (let i = 0; i < num; i++) {
            getMonth =
                myDate.getMonth() + 1 < 10
                ? "0" + (myDate.getMonth() + 1)
                : myDate.getMonth() + 1;
            getDate =
                myDate.getDate() < 10 ? "0" + myDate.getDate() : myDate.getDate();
            dateArray.push(getMonth + "-" + getDate);
            myDate.setDate(myDate.getDate() + flag);
            }
            let nowHours = myDate.getHours();
            let nowDate = dateArray.length - 1;

            for (let i = 0; i < dateArray.length; i++) {
            for (let y = 0; y < 24; y++) {
                if (nowDate == i) {
                if (nowHours + 1 == y) {
                    break;
                }
                }
                if (y <= 9) {
                if (type == 1) {
                    DataList.push({
                    time: dateArray[i] + " " + "0" + y + ":00",
                    fyjNum0: 0,
                    fyjNum1: 0,
                    fyjNum2: 0,
                    yjNum0: 0,
                    yjNum1: 0,
                    yjNum2: 0,
                    });
                } else {
                    DataList.push({
                    time: dateArray[i] + " " + "0" + y + ":00",
                    fyjfmgNum: 0,
                    fyjmgNum: 0,
                    yjfmgNum: 0,
                    yjmgNum: 0,
                    });
                }
                } else {
                if (type == 1) {
                    DataList.push({
                    time: dateArray[i] + " " + y + ":00",
                    fyjNum0: 0,
                    fyjNum1: 0,
                    fyjNum2: 0,
                    yjNum0: 0,
                    yjNum1: 0,
                    yjNum2: 0,
                    });
                } else {
                    DataList.push({
                    time: dateArray[i] + " " + y + ":00",
                    fyjfmgNum: 0,
                    fyjmgNum: 0,
                    yjfmgNum: 0,
                    yjmgNum: 0,
                    });
                }
                }
            }
            }
            return DataList;
        },
        getDateList(dataList, sentiment_time) {
            let timer;
            dataList.forEach((item) => {
                sentiment_time.forEach((ite) => {
                    if (item.showTime) {
                        timer = item.showTime.slice(5);
                        if (timer == ite.time+":00") {
                            ite.fyjNum0 = item.fyjNum0;
                            ite.fyjNum1 = item.fyjNum1;
                            ite.fyjNum2 = item.fyjNum2;
                            ite.yjNum0 = item.yjNum0;
                            ite.yjNum1 = item.yjNum1;
                            ite.yjNum2 = item.yjNum2;
                        }
                    }
                });
            });
            return sentiment_time;
        },
        getNumSentiment(listType, type) {
            for (let i = 0; i < listType.length; i++) {
            if (i != 0) {
                if (type == 1) {
                listType[i].fyjNum0 += listType[i - 1].fyjNum0;
                listType[i].fyjNum1 += listType[i - 1].fyjNum1;
                listType[i].fyjNum2 += listType[i - 1].fyjNum2;
                listType[i].yjNum0 += listType[i - 1].yjNum0;
                listType[i].yjNum1 += listType[i - 1].yjNum1;
                listType[i].yjNum2 += listType[i - 1].yjNum2;
                } else {
                listType[i].fyjfmgNum += listType[i - 1].fyjfmgNum;
                listType[i].fyjmgNum += listType[i - 1].fyjmgNum;
                listType[i].yjfmgNum += listType[i - 1].yjfmgNum;
                listType[i].yjmgNum += listType[i - 1].yjmgNum;
                }
            }
            }
            return listType;
        },
        warningEcharts(){
            let data = {
                organId: this.organId,
                analysisType: 1,
                isEarlyWarn: 1,
                type: this.timetype
            };
            data.startTimeStr = this.time[0];
            data.endTimeStr = this.time[1];
            climap(data).then(res=>{
                if(res.data.code == 200 && res.data.data && res.data.data.length>0){
                    let data = res.data.data;
                    let yjTotal = [];
                    data.forEach(e => {
                        if (e.yjNumffm + e.yjNumfm != 0) {
                            yjTotal.push({
                                value: e.yjNumffm + e.yjNumfm,
                                name: e.mediaLink
                            });
                        }
                    });
                    if (yjTotal.length > 0) {
                        this.warningData.show = true;
                        yjTotal.sort((a, b) => {
                            return b.value - a.value;
                        });
                        // this.PoliticsName = "全部数据:";
                        // this.PoliticsNames = "";
                        // let num = 0;
                        // yjTotal.forEach(x=>{
                        //     num += x.value;
                        // })
                        // this.PoliticsTotal = num;
                        // this.getPolitics();
                        this.warningData.most = yjTotal[0].name;
                        this.$nextTick(() => {
                            let myChart = echarts.init(document.getElementById("zb_realtime_warning_echarts"));
                            let option = {
                            color: [
                                "#92F5A8",
                                "#FEBC3F",
                                "#FFD88F",
                                "#184CFF",
                                "#6F90FF",
                                "#0098FF",
                                "#77C8FF",
                                "#00D0A3"
                            ],
                            label: {
                                formatter: "{b}：{d}%"
                            },
                            title: [
                                {
                                text: "预警数据事件类型分类",
                                left: "48%",
                                top: "90%",
                                textAlign: "center",
                                textStyle: {
                                    color: "#999999",
                                    fontSize: 12,
                                    fontWeight: "normal"
                                }
                                }
                            ],
                            series: [
                                {
                                type: "pie",
                                center: ["50%", "50%"],
                                radius: 150,
                                minAngle: 10,
                                itemStyle: {
                                    borderWidth: 1,
                                    borderColor: "#fff"
                                },
                                data: yjTotal
                                }
                            ]
                            };
                            myChart.setOption(option);
                            // let that = this;
                            // myChart.on("click", function(params) {
                            //     that.PoliticsName = params.name + ":";
                            //     that.PoliticsNames = params.name;

                            //     that.PoliticsTotal = params.value;
                            //     that.getPolitics(params.name);
                            // });
                            let that = this;
                            myChart.on("finished", function() {
                                that.warningData.URL = myChart.getDataURL();
                            });
                        });
                    }else{
                        this.warningData.show = false;
                    }
                }else{
                    this.warningData.show = false;
                }
            });
        },
        //切换高发平台类型
        switchType(type){
            if(this.platformData.sort === type){
                return;
            }else{
                this.platformData.sort = type;
                this.getPlatform();
            }
        },
        getMap(){
            this.mapData.loading = true;
            let data = {
                organId: this.organId,
                state:this.mapData.select,
                type: this.timetype
            }
            data.startTimeStr = this.time[0];
            data.endTimeStr = this.time[1];
            getMap(data).then(res=>{
                this.mapData.loading = false;
                let mapData = [];
                let data = {
                    region:[],
                    value:[],
                    average:[],
                };
                if(res.data.code == 200 && res.data.data){
                    res.data.data.forEach(item => {
                        if (
                            item.province != "其他" &&
                            item.province != "全国" &&
                            item.province
                        ) {
                            item.name = item.province;
                            item.value = item.gxdNum;
                            mapData.push(item);
                        }
                    });
                    mapData.sort((a, b) => {
                        return a.gxdNum - b.gxdNum;
                    });
                    let sum = 0;
                    mapData.forEach(x=>{
                        data.region.push(x.name);
                        data.value.push(x.value);
                        sum += x.value;
                    });
                    data.region = data.region.reverse();
                    data.value = data.value.reverse();
                    data.value.forEach(()=>{
                        data.average.push(sum/data.value.length);
                    });
                    if(data.region.length>=10){
                        data.region.splice(10);
                    }else{
                        while(data.region.length<10){
                        data.region.push("");
                        }
                    }
                }
                echarts.registerMap("China", map);
                let myChart = echarts.init(document.getElementById("zb_realtime_hot_echarts"));
                let option = {
                    title: [
                    {
                        show: true,
                        text: 'TOP 10 排行榜',
                        right: 40,
                        textStyle: {color: '#666666',fontSize: 12}
                    }
                    ],
                    tooltip: {
                    trigger: "item",
                    backgroundColor: "rgba(255, 255, 255, 0.98)",
                    borderWidth: 0,
                    formatter: function(params) {
                        if (params.data == undefined) {
                        return "";
                        }
                        let str = `<div>
                        <p style="width:200px;display:flex;justify-content: space-between;">
                        <span style="font-size: 14px;font-weight: 550;color: #2E59EC;">${params.data.name}</span>
                        <span style="font-size: 14px;font-weight: 550;color: #2E59EC;">${params.data.value}</span>
                        </p></div>`;
                        return str;
                    }
                    },
                    legend: {show: false},
                    grid: {
                    show: true,
                    containLabel: false,
                    right: 40,
                    top: 50,
                    bottom: 30,
                    width: '20%',
                    },
                    visualMap: {
                    type: 'continuous',
                    min: 0,
                    max: mapData[mapData.length-1]&&mapData[mapData.length-1].value?mapData[mapData.length-1].value:1,
                    text: ['多', '少'],
                    seriesIndex: [0, 2],
                    dimension: 0,
                    realtime: false,
                    left: 50,
                    bottom: 50,
                    itemWidth: 18,
                    itemHeight: 100,
                    calculable: true,
                    inRange: {
                        color: [
                        "#e6ecfc",
                        "#addbff",
                        "#5cb8ff",
                        "#6fa5ff",
                        "#3779ff",
                        "#3b64f1"
                        ]
                    },
                    outOfRange: {
                        color: ['#eeeeee'],
                    },
                    },
                    toolbox: {
                        show: false,
                    },
                    xAxis: [{
                    type: 'value',
                    position: 'top',
                    inside: false,
                    axisLabel: {show: false},
                    splitLine: {show: false},
                    margin: 10,
                    }],
                    yAxis: [{
                    type: 'category',
                    boundaryGap: true,
                    axisLine: {show: false},
                    axisLabel: {align: 'right',margin: 10,showMaxLabel: true},
                    inverse: true,
                    data: data.region,
                    }],
                    series: [
                    {
                        name: '占比',
                        type: 'map',
                        mapType: 'China',
                        left: '100',
                        width: '60%',
                        mapValueCalculation: 'sum',
                        zoom: 1,
                        top: 10,
                        selectedMode: false,
                        showLegendSymbol: false,
                        label: {
                        normal: {show: true,color: "#fff",fontSize: 9},
                        emphasis: {show: true,color: "#fff"},
                        },
                        itemStyle: {
                        normal: {areaColor: "#e6ecfc",borderColor: "#fff"},
                        emphasis: {areaColor: '#3b64f1',borderWidth: 2},
                        },
                        data: mapData,
                    },
                    {
                        name: '背景',
                        type: 'bar',
                        roam: false,
                        visualMap: false,
                        itemStyle: {color:'#eeeeee',opacity: 0.5},
                        label: {show: false},
                        emphasis: {
                        itemStyle: {color: '#eeeeee',opacity: 0.5},
                        label: {show: false},
                        },
                        silent: true,
                        barWidth: 18,
                        barGap: '-100%',
                        data: data.average,
                        animation: false,
                    },
                    {
                        name: '占比',
                        type: 'bar',
                        roam: false,
                        visualMap: false,
                        barWidth: 18,
                        label: {
                        normal: {show: true,fontSize: 12,position: 'insideLeft'},
                        emphasis: {show: true,},
                        },
                        tooltip:{
                        show:false
                        },
                        data: data.value,
                    },
                    ],
                };
                myChart.setOption(option);
                window.addEventListener("resize", () => {
                    myChart.resize();
                });
            })
        },
        hotType(type){
            if(this.mapData.select === type){
                return;
            }else{
                this.mapData.select = type;
                this.getMap();
            }
        },
        exportData(){
            let data = {
                organizationId: this.organId, //机构id
                organizationName: this.$store.state.user.mechanism, //机构名称
                startTime: this.time[0], //开始时间
                endTime: this.time[1], //结束时间
                analysisDataStatisticsVo: {
                    organId: this.organId,
                    analysisType: 1,
                    endTimeStr: this.time[0],
                    startTimeStr: this.time[1]
                },
                analysisPoliticsVo: {
                    organId: this.organId,
                    endTimeStr: this.time[0],
                    startTimeStr: this.time[1]
                },
                analysisHighPlatformVo: {
                    organId: this.organId,
                    sort: this.platformData.sort,
                    endTimeStr: this.time[0],
                    startTimeStr: this.time[1]
                },
                fbHotVo: {
                    organId: this.organId,
                    state: this.mapData.select,
                    endTimeStr: this.time[0],
                    startTimeStr: this.time[1]
                },
                warnLike: this.warningData.most
            };
            if (this.climapData.URL1) {
                data.viewPicture1 = this.climapData.URL1;
            }
            if (this.climapData.URL2) {
                data.viewPicture3 = this.climapData.URL2;
            }
            if (this.climapData.URL3) {
                data.viewPicture4 = this.climapData.URL3;
            }
            if (this.climapData.URL4) {
                data.viewPicture5 = this.climapData.URL4;
            }
            if (this.climapData.URL5) {
                data.viewPicture6 = this.climapData.URL5;
            }
            if (this.warningData.URL) {
                data.viewWarn = this.warningData.URL;
            }
            if (this.warningData.URL) {
                data.viewPicture2 = this.warningData.URL;
            }
            html2canvas(document.querySelector("#zb_realtime_trend")).then(
                resolve => {
                    let imgUrl = resolve.toDataURL("image/png");
                    let textName = "实时信息报告.docx";
                    data.viewPicture2 = imgUrl;
                    let obj = {
                        url: 'api/msgquery/InforMationAnalysis/generateReportStream',
                        method: 'post',
                        data:data,
                        responseType: "blob"
                    }
                    this.$parent.$parent.downloadFun(obj, textName);
                }
            );
        }
    },
    created(){
        this.refresh();
        this.first = false;
    }
}
</script>

<style scoped>
.zb_realtime_title {
    display: flex;
    align-items: center;
}
.zb_realtime_title img {
    position: relative;
    top: 2px;
    margin-right: 5px;
}
.zb_realtime_title span {
    font-size: 16px;
    color: #333333;
    font-weight: bold;
}
.zb_realtime_box{
    width: 100%;
    background: #ffffff;
    border-radius: 4px;
    padding: 24px;
    margin-top: 16px;
    position: relative;
    overflow: hidden;
}
#zb_realtime_prelude{
    width: 100%;
    height: 122px;
    background: #f6fafe;
    border-radius: 4px;
    padding: 24px;
    text-align: center;
}
#zb_realtime_prelude p:first-child{
    font-size: 24px;
    font-weight: bold;
    color: #333333;
}
#zb_realtime_prelude p:last-child{
    font-size: 18px;
    color: #666;
    margin-top: 16px;
}
.zb_realtime_none{
    position:absolute;
    left:50%;
    top:50%;
    transform: translate(-50%,-50%);
    text-align: center;
}
.zb_realtime_none p{
    font-size: 14px;
    color: #999;
}
.zb_realtime_stext{
    font-size: 14px;
    color: #666;
    padding-left: 20px;
}
#zb_realtime_climap{
    margin-top: 24px;
}
.zb_realtime_climap_option{
    padding: 20px;
}
.zb_realtime_botton{
    display: inline-block;
    padding: 8px 20px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
    margin-right: 24px;
    cursor: pointer;
    box-sizing: border-box;
    border: 1px solid #e1e1e1;
}
.zb_realtime_bottonS{
    background: #e9f4ff;
    color: #2e59ec;
    border: 1px solid transparent;
}
#zb_realtime_trend_sub{
    padding-right: 16px;
    justify-content: space-between;
    display: flex;
    position: absolute;
    right: 2.5%;
    top: 6%;
    width: 264px;
    height: 72px;
    border: 1px solid #e4e6ec;
}
#zb_realtime_trend_middel{
    position: relative;
    right: -51%;
    top: 26%;
    width: 1px;
    height: 40px;
    background: #e4e6ec;
}
#zb_realtime_trend_echarts{
    width: 100%;
    height: 360px;
    position: relative;
}
#zb_realtime_trend_text{
    font-size: 12px;
    color: #5a6474;
    text-align: right;
    padding: 5px 2px 0 0;
}
#zb_realtime_trend_text span{
    margin-left: 12px;
}
#zb_realtime_proportion{
    display: flex;
    justify-content: space-between;
    height: 490px;
}
#zb_realtime_proportion>div{
    width: 50%;
    height: 100%;
}
.zb_realtime_proportion_box{
    width: calc(100% - 20px);
    height: calc(100% - 54px);
    display: flex;
    padding: 16px;
    background: #f5f6fa;
    margin-top: 32px;
    margin-left: 20px;
}
.zb_realtime_proportion_echarts{
    position: relative;
    width: 50%;
    height: 100%;
    background-color: #fff;
}
.zb_realtime_proportion_echarts>div:first-child{
    width: 100%;
    height: 100%;
}
#zb_realtime_warning_table{
    width: 100%;
    margin-top: 17px;
    border: 1px solid #d8d8d8;
    border-bottom: none;
    cursor: pointer;
}
#zb_realtime_warning_box{
    width: 100%;
    height: 480px;
    display: flex;
}
#zb_realtime_warning_box>div{
    width: 50%;
    height: 100%;
    position: relative;
}
.zb_realtime_platform_table{
    width: 100%;
    border: 1px solid #d8d8d8;
    cursor: pointer
}
.zb_realtime_platform_title{
    color: #2e59ec;
    font-weight: bold;
    margin-bottom: 20px
}
#zb_realtime_platform{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
#zb_realtime_platform>div{
    width: 49%;
}
#zb_realtime_hot{
    width: 100%;
    position: relative;
}
#zb_realtime_hot_echarts{
    width: 100%;
    height: 800px;
}
#zb_realtime_hot_map{
    position: absolute;
    right: 550px;
    bottom: 100px;
}
#zb_realtime_climap_table{
    display: flex;
    justify-content: space-between;
}
#zb_realtime_climap_tableR{
    width: 550px;
    height: 420px;
    position: relative;
}
.zb_realtime_table_white{
    background-color: #fff !important;
}
#zb_realtime_climap_tableL>table{
    margin-left: 20px;
    margin-top: 20px;
    width: 976px;
    font-size: 14px;
    color: #333;
    border: 1px solid #d8d8d8
}
#zb_realtime_climap_tableL>table th{
    height: 40px;
    background: #f5f6fa;
    text-align: center;
}
#zb_realtime_climap_tableL>table td{
    text-align: center;
}
#zb_realtime_climap_tableL>table tbody{
    cursor: pointer;
}
#zb_realtime_climap_echarts{
    width: 100%;
    height: 100%;
}
#zb_realtime_warning_echarts{
    width: 100%;
    height: 100%;
}
</style>