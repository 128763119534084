<template>
  <div class="analyse">
    <div class="index-title">
      综合分析
      <span class="index-title-subhead">最大跨度90天</span>
    </div>
    <div class="analyse-search">
      <div class="analyse-search-left">
        <span class="analyse-search-title">分析时间</span>
        <el-date-picker
          v-model="reportTime[0]"
          type="datetime"
          :picker-options="pickerOptions"
          @change="clearcardActive"
          @focus="focusDate(1)"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="开始时间">
        </el-date-picker>
        -
        <el-date-picker
          v-model="reportTime[1]"
          type="datetime"
          :picker-options="pickerOptions"
          @change="clearcardActive"
          @focus="focusDate(2)"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="结束时间">
        </el-date-picker>
        <div
          v-for="(val, index) in tabCard"
          :key="index"
          :class="{
            'analyse-search-card': true,
            active: cardActive == index + 1,
          }"
          @click="analyseSearch(val.type)"
        >
          {{ val.name }}
        </div>
        <el-select
          v-model="value2"
          placeholder="请选择"
          @change="changeAnalysis"
          style="margin-left: 24px"
          size="medium"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div>
        <el-button type="primary" :class="suspend?'prohibit':''" plain size="medium" v-if="ToAnalyze" :disabled="downLoad" @click="getPDF">
          {{ downLoad ? "正在导出..." : "导出分析" }}
        </el-button>
        <el-button type="primary" size="medium" @click="analyzeGo">开始分析</el-button>
      </div>
    </div>
    <div class="analyse-container" v-if="!ToAnalyze">
      <div class="analyse-container-img">
        <img src="../../assets/img/views/analyse-img.png" />
        <p>暂无数据</p>
      </div>
    </div>
    <!-- <div class="analyse-container" v-if="ToAnalyzeType == 1 && ToAnalyze">
      <div class="analyse-container-img">
        <img src="../../assets/img/views/nodata.png" />
        <p style="color: #999999;font-size: 16px;">正在维护中...</p>
      </div>
    </div>-->
    <InAnalysis
      v-if="ToAnalyzeType == 1 && ToAnalyze"
      :timers="timersArr"
      :timetype="timeType"
      ref="InAnalysis"
    />
    <OutsideAnalysis
      v-if="ToAnalyzeType == 2 && ToAnalyze"
      :timers="timersArr"
      :timetype="timeType"
      ref="OutsideAnalysis"
    />
    <EvaluationAnalysis
      v-if="ToAnalyzeType == 3 && ToAnalyze"
      :timers="timersArr"
      :timetype="timeType"
      ref="EvaluationAnalysis"
    />
    <RealTime
      v-if="ToAnalyzeType == 4 && ToAnalyze"
      :timers="timersArr"
      :timetype="timeType"
      :organId="$store.state.user.organizationId"
      ref="RealTime"
    >
    </RealTime>
    <el-dialog
      title="下载提示"
      :visible.sync="download.dialogFormVisible"
      width="700px"
      class="dialog-box"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :append-to-body="true"
      :show-close="false"
    >
      <div class="dialog-body">
        <span class="download-Wait" v-if="download.wite">正在下载...</span>
        <span class="download-Wait" v-else>下载完成！</span>
        <el-progress
          :stroke-width="22"
          :text-inside="true"
          :percentage="download.percentage"
        ></el-progress>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="downloadYes"
          size="medium"
          type="primary"
          class="search_z"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import InAnalysis from "./InAnalysis";
import OutsideAnalysis from "./OutsideAnalysis";
import EvaluationAnalysis from "./EvaluationAnalysis";
import RealTime from "./RealTime";
export default {
  components: {
    InAnalysis,
    OutsideAnalysis,
    EvaluationAnalysis,
    RealTime
  },
  data() {
    // let minTime = null;
    // let maxTime = null;
    return {
      // 时间筛选
      dataTime: "",
      // 控制导出分析按钮
      downLoad: false,
      pickerOptions: this.pickerOptionsObj(),
        // onPick(time) {
        //   //选择了一个时间
        //   if (!time.maxDate) {
        //     const timeRange = 90 * 24 * 60 * 60 * 1000; // 90天
        //     minTime = time.minDate.getTime() - timeRange; // 最小时间
        //     maxTime = time.minDate.getTime() + timeRange; // 最大时间
        //     // 选完两个时间，就清空本次范围判断数据
        //   } else {
        //     minTime = maxTime = null;
        //   }
        // },
      timersArr: [],
      reportTime: [],
      timers: [],
      timeType: 1,
      tabCard: [
        { name: "近3天", type: 1 },
        { name: "近7天", type: 2 },
        { name: "近15天", type: 3 },
        { name: "近30天", type: 4 },
        { name: "近3月", type: 5 },
      ],
      cardActive: null,
      index: 0,
      options: [],
      value2: null,
      ToAnalyze: false,
      ToAnalyzeType: 0,
      download: {
        dialogFormVisible: false,
        percentage: 0,
        wite: true,
      },
      minTime:null,
      maxTime:null,
      startAnalysis: false, //判断切换类型后 是否点击了开始分析，否则无法导出分析
      suspend: false,
    };
  },
  created() {
    if (this.$store.state.user.scope[0]&&this.$store.state.user.scope[3]) {
      this.options.push({
        value: 1,
        label: "境内分析",
      });
    }
    // 根据用户返回是否有查看境外和跟评权限
    if (this.$store.state.user.scope[1]) {
      this.options.push({
        value: 2,
        label: "境外分析",
      });
    }
    // if (this.$store.state.user.scope[2]) {
    //   this.options.push({
    //     value: 3,
    //     label: "跟评分析",
    //   });
    // }
    this.options.push({
      value: 4,
      label: "实时分析",
    });
    this.value2 = this.options[0].value;
  },
  methods: {
    // disabledDate(time) {
    //   console.log(1);
    //   // onPick后触发
    //   // 该方法会轮询，返回false表示该日期禁选
    //   if (this.minTime && this.maxTime) {
    //     console.log(this.minTime,this.maxTime)
    //     return time.getTime() < this.minTime || time.getTime() > this.maxTime;
    //   }
    // },
    pickerOptionsObj() {
      let _this = this;
      return {
        disabledDate(time) {
          if (_this.minTime && _this.maxTime) {
            return time.getTime() < _this.minTime || time.getTime() > _this.maxTime;
          }else if(!_this.minTime && !_this.maxTime){
            return false;
          }
        },
      };
    },
    changeAnalysis() {
      this.index = 0;
      this.startAnalysis = false;
    },
    analyseSearch(type) {
      this.cardActive = type;
      this.reportTime = [];
      let startTime;
      let endtime =
        this.$calcu.timeCycle(new Date(), "yyyy-MM-dd") + " " + "23:59:59"; //当前时间
      if (type == 1) {
        startTime = this.$calcu.calcuTime(2) + " " + "00:00:00"; //近三天
      }
      if (type == 2) {
        startTime = this.$calcu.calcuTime(6) + " " + "00:00:00"; //近七天
      }
      if (type == 3) {
        startTime = this.$calcu.calcuTime(14) + " " + "00:00:00"; //近15天
      }
      if (type == 4) {
        startTime = this.$calcu.calcuTime(29) + " " + "00:00:00"; //近30天
      }
      if (type == 5) {
        startTime = this.$calcu.calcuTime(89) + " " + "00:00:00"; //近3月
      }
      this.timersArr = [startTime, endtime];
      this.reportTime = this.timersArr;
    },
    analyzeGo() {
      this.startAnalysis = true;
      // 不建议用!this.cardActive 因为选择3天时 它为0 0等同于false
      if (!this.reportTime[0]||!this.reportTime[1]||this.reportTime[0]>=this.reportTime[1]) {
        this.$message("请选择时间才可以进行分析");
        return;
      }
      this.index++;
      this.ToAnalyzeType = this.value2; //默认选择境内
      this.ToAnalyze = true; // 显示页面
      this.timeType = this.cardActive ? this.cardActive : 0;
      this.$nextTick(() => {
        if (this.ToAnalyzeType == 1) {
          this.$refs.InAnalysis.timersFn();
        } else if (this.ToAnalyzeType == 2) {
          this.$refs.OutsideAnalysis.timersFn();
        } else if (this.ToAnalyzeType == 3) {
          this.$refs.EvaluationAnalysis.timersFn();
        } else if (this.ToAnalyzeType == 4) {
          this.$refs.RealTime.refresh();
        }
        if (this.index > 1 && this.ToAnalyzeType == 1) {
          this.$refs.InAnalysis.allFunction();
          this.$refs.InAnalysis.activeTab = 0;
        } else if (this.index > 1 && this.ToAnalyzeType == 2) {
          //切换境外第一次不调用
          this.$refs.OutsideAnalysis.allFunction();
          this.$refs.OutsideAnalysis.activeTab = 0;
        } else if (this.index > 1 && this.ToAnalyzeType == 3) {
          this.$refs.EvaluationAnalysis.allFunction();
        }
      });
    },
    downloadYes() {
      this.download.dialogFormVisible = false;
      this.download.percentage = 0;
    },
    getPDF() {
      if(this.suspend){
        return;
      }else{
        this.suspend = true;
        setTimeout(() => {
          this.suspend = false;
        }, 1000);
      }
      if (!this.startAnalysis) {
        return this.$message.warning("请分析后再导出！");
      }
      if (this.ToAnalyzeType == 1) {
        this.$refs.InAnalysis.getPdf();
      } else if (this.ToAnalyzeType == 2) {
        this.$refs.OutsideAnalysis.getPdf();
      } else if (this.ToAnalyzeType == 3) {
        this.$refs.EvaluationAnalysis.getPdf();
      } else if (this.ToAnalyzeType == 4) {
        this.$refs.RealTime.exportData();
      }
    },
    clearcardActive() {
      this.cardActive = null;
      if (this.reportTime[0]&&this.reportTime[1]&&this.reportTime[0]<this.reportTime[1]) {
        let startTime = this.reportTime[0];
        let endtTime = this.reportTime[1];
        this.timersArr = [startTime, endtTime];
      }
    },
    focusDate(type){
      const timeRange = 90 * 24 * 60 * 60 * 1000; // 90天
      if(type==1){
        if(this.reportTime[1]){
          this.minTime = new Date(this.reportTime[1]).getTime()-timeRange;
          this.maxTime = new Date(this.reportTime[1]).getTime();
        }else{
          this.minTime = null;
          this.maxTime = null;
        }
      }
      if(type==2){
        if(this.reportTime[0]){
          this.minTime = new Date(this.reportTime[0]).getTime();
          this.maxTime = new Date(this.reportTime[0]).getTime()+timeRange;
        }else{
          this.minTime = null;
          this.maxTime = null;
        }
      }
    }
  },
};
</script>

<style scoped>
.analyse {
  padding: 24px;
}
.analyse-search {
  width: 100%;
  height: 68px;
  padding: 24px;
  background: #ffffff;
  border-radius: 4px;
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.analyse-search-left {
  display: flex;
  align-items: center;
}
.el-range-editor.el-input__inner {
  width: 356px;
}
.analyse-search-title {
  font-size: 14px;
  color: #333333;
  margin-right: 12px;
}
.analyse-search-card {
  padding: 7px 24px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #e1e1e1;
  font-size: 16px;
  color: #333333;
  margin-left: 24px;
  cursor: pointer;
}
.analyse-search-card.active {
  background: #e4f1ff;
  color: #2e59ec;
  border-color: #e4f1ff;
}
.analyse-container {
  width: 100%;
  height: 775px;
  background: #ffffff;
  border-radius: 4px;
  margin-top: 16px;
  text-align: center;
}
.analyse-container-img {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.analyse-container-img p {
  font-size: 14px;
  color: #999999;
}
.el-button + .el-button {
  margin-left: 24px;
}
.download-Wait {
  display: inline-block;
  margin-bottom: 15px;
}
.prohibit{
  cursor: not-allowed;
}
</style>
