import { render, staticRenderFns } from "./RealTime.vue?vue&type=template&id=0d5c8214&scoped=true"
import script from "./RealTime.vue?vue&type=script&lang=js"
export * from "./RealTime.vue?vue&type=script&lang=js"
import style0 from "./RealTime.vue?vue&type=style&index=0&id=0d5c8214&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d5c8214",
  null
  
)

export default component.exports