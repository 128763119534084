<template>
  <div class="InAnalysis jwYq">
    <div class="InAnalysis-top">
      <div class="InAnalysis-title">
        <p class="p1">境外敏感信息 综合分析</p>
        <p class="p2">{{ timersArr[0] }} 至 {{ timersArr[1] }}</p>
      </div>
      <!-- 综合数据统计表 -->
      <div class="InAnalysis-statistical-table">
        <div class="InAnalysis-top-title">
          <img src="../../assets/img/views/topqq.png" />
          <span>综合数据统计表</span>
        </div>
        <div class="flex" style="justify-content: space-between">
          <div style="width: 60%">
            <!-- 切换 -->
            <div class="InAnalysis-tab">
              <div
                :class="{
                  'InAnalysis-tab-card': true,
                  active: activeTab == val.type,
                }"
                v-for="(val, index) in tab"
                :key="index"
                @click="tabCard(index), jwAnalysisDataStatistics(index + 1)"
              >
                {{ val.name }}
              </div>
            </div>
            <!-- 环节 -->
            <p class="InAnalysis-link">
              <span v-if="activeTab == 0">全部类型：</span>
              <span v-if="activeTab == 1">全部归属：</span>
              <span v-if="activeTab == 2">全部阵营：</span>
              <span v-if="activeTab == 3">全部语言：</span>
              <span
                class="InAnalysis-link-span pointer"
                v-for="(val, index) in slectAddTableArr"
                @click="
                  slectremoveTable(
                    slectAddTableArr[index - 1]
                      ? slectAddTableArr[index - 1]
                      : { activeTab },
                    index
                  )
                "
                :key="index"
              >
                {{ val.name }}
                <span
                  v-if="
                    slectAddTableArr.length > 0 &&
                    index < slectAddTableArr.length - 1
                  "
                  >/</span
                >
              </span>
            </p>
            <!-- tab 表格 -->
            <div class="InAnalysis-table">
              <table class="table" ref="table">
                <thead>
                  <tr>
                    <!-- 类型开头 -->
                    <template v-if="activeTab == 0">
                      <th style="width: 100px">媒体类型</th>
                      <th
                        style="width: 100px"
                        v-if="slectAddTableArr.length > 0"
                      >
                        媒体归属
                      </th>
                      <th
                        style="width: 100px"
                        v-if="slectAddTableArr.length > 1"
                      >
                        媒体阵营
                      </th>
                      <th
                        style="width: 100px"
                        v-if="slectAddTableArr.length > 2"
                      >
                        语言类型
                      </th>
                    </template>
                    <!-- 归属开头 -->
                    <template v-if="activeTab == 1">
                      <th style="width: 100px">媒体归属</th>
                      <th
                        style="width: 100px"
                        v-if="slectAddTableArr.length > 0"
                      >
                        媒体阵营
                      </th>
                      <th
                        style="width: 100px"
                        v-if="slectAddTableArr.length > 1"
                      >
                        语言类型
                      </th>
                      <th
                        style="width: 100px"
                        v-if="slectAddTableArr.length > 2"
                      >
                        媒体类型
                      </th>
                    </template>
                    <!-- 归属开头 -->
                    <template v-if="activeTab == 2">
                      <th style="width: 100px">媒体阵营</th>
                      <th
                        style="width: 100px"
                        v-if="slectAddTableArr.length > 0"
                      >
                        语言类型
                      </th>
                      <th
                        style="width: 100px"
                        v-if="slectAddTableArr.length > 1"
                      >
                        媒体类型
                      </th>
                      <th
                        style="width: 100px"
                        v-if="slectAddTableArr.length > 2"
                      >
                        媒体归属
                      </th>
                    </template>
                    <!-- 语言开头 -->
                    <template v-if="activeTab == 3">
                      <th style="width: 100px">语言类型</th>
                      <th
                        style="width: 100px"
                        v-if="slectAddTableArr.length > 0"
                      >
                        媒体类型
                      </th>
                      <th
                        style="width: 100px"
                        v-if="slectAddTableArr.length > 1"
                      >
                        媒体归属
                      </th>
                      <th
                        style="width: 100px"
                        v-if="slectAddTableArr.length > 2"
                      >
                        媒体阵营
                      </th>
                    </template>
                    <th>舆情数据</th>
                    <th>预警数据</th>
                  </tr>
                </thead>
                <tbody>
                  <!-- 新增的表头 -->
                  <template v-if="slectAddTableArr.length > 0">
                    <th
                      :rowspan="tableDatacom.length + 1"
                      v-for="(item, index) in slectAddTableArr"
                      :key="item.name + index"
                      class="pointer"
                      @click="
                        slectremoveTable(
                          slectAddTableArr[index - 1]
                            ? slectAddTableArr[index - 1]
                            : { activeTab },
                          index
                        )
                      "
                    >
                      {{ item.name }}
                    </th>
                  </template>
                  <tr
                    v-for="(item, index) in tableDatacom"
                    :key="index"
                    :class="{ tr: index % 2 != 0 }"
                  >
                    <!-- 类型开头 -->
                    <template v-if="activeTab == 0">
                      <th
                        class="pointer"
                        @click="slectAddTable(item)"
                        v-if="slectAddTableArr.length == 0"
                      >
                        {{ item.mediaType }}
                      </th>
                      <th
                        class="pointer"
                        @click="slectAddTable(item)"
                        v-if="slectAddTableArr.length == 1"
                      >
                        {{ item.mediaOwnership }}
                      </th>
                      <th
                        class="pointer"
                        @click="slectAddTable(item)"
                        v-if="slectAddTableArr.length == 2"
                      >
                        {{ item.mediaCamp }}
                      </th>
                      <th class="pointer" v-if="slectAddTableArr.length == 3">
                        {{ item.languageType }}
                      </th>
                    </template>
                    <!-- 归属开头 -->
                    <template v-if="activeTab == 1">
                      <th
                        class="pointer"
                        @click="slectAddTable(item)"
                        v-if="slectAddTableArr.length == 0"
                      >
                        {{ item.mediaOwnership }}
                      </th>
                      <th
                        class="pointer"
                        @click="slectAddTable(item)"
                        v-if="slectAddTableArr.length == 1"
                      >
                        {{ item.mediaCamp }}
                      </th>
                      <th
                        class="pointer"
                        @click="slectAddTable(item)"
                        v-if="slectAddTableArr.length == 2"
                      >
                        {{ item.languageType }}
                      </th>
                      <th class="pointer" v-if="slectAddTableArr.length == 3">
                        {{ item.mediaType }}
                      </th>
                    </template>
                    <!-- 阵营开头 -->
                    <template v-if="activeTab == 2">
                      <th
                        class="pointer"
                        @click="slectAddTable(item)"
                        v-if="slectAddTableArr.length == 0"
                      >
                        {{ item.mediaCamp }}
                      </th>
                      <th
                        class="pointer"
                        @click="slectAddTable(item)"
                        v-if="slectAddTableArr.length == 1"
                      >
                        {{ item.languageType }}
                      </th>
                      <th
                        class="pointer"
                        @click="slectAddTable(item)"
                        v-if="slectAddTableArr.length == 2"
                      >
                        {{ item.mediaType }}
                      </th>
                      <th class="pointer" v-if="slectAddTableArr.length == 3">
                        {{ item.mediaOwnership }}
                      </th>
                    </template>
                    <!-- 阵营开头 -->
                    <template v-if="activeTab == 3">
                      <th
                        class="pointer"
                        @click="slectAddTable(item)"
                        v-if="slectAddTableArr.length == 0"
                      >
                        {{ item.languageType }}
                      </th>
                      <th
                        class="pointer"
                        @click="slectAddTable(item)"
                        v-if="slectAddTableArr.length == 1"
                      >
                        {{ item.mediaType }}
                      </th>
                      <th
                        class="pointer"
                        @click="slectAddTable(item)"
                        v-if="slectAddTableArr.length == 2"
                      >
                        {{ item.mediaOwnership }}
                      </th>
                      <th class="pointer" v-if="slectAddTableArr.length == 3">
                        {{ item.mediaCamp }}
                      </th>
                    </template>
                    <td class="pointer" @click="clickOutput(item, false)">
                      {{ item.yqNum }}
                    </td>
                    <td class="pointer" @click="clickOutput(item, true)">
                      {{ item.yjNum }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <!-- echarts 饼图 -->
          <div v-if="echarts1Data" id="echarts1"></div>
          <div class="analyse-container comprehensive" v-else>
            <div class="analyse-container-img dataShowImg">
              <img src="../../assets/img/views/analyse-img.png" />
              <p>综合数据统计图</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 舆情走势 -->
    <div class="InAnalysis-trend">
      <div class="InAnalysis-top-title">
        <img src="../../assets/img/views/topqq.png" />
        <span>敏感信息走势</span>
      </div>
      <div id="echarts2"></div>
      <div class="echarts-legend">
        <div class="echarts-legend-line"></div>

        <div style="display: flex; flex-direction: column">
          <div class="legend-top">
            <span class="noYujing">预警</span>
            <span class="noYujing" style="margin-left: 8px">非预警</span>
          </div>

          <div style="display: flex; text-align: right; margin-top: 7px">
            <div
              style="
                display: flex;
                flex-direction: column;
                color: #5a6474;
                font-size: 12px;
              "
            >
              <span>非敏感</span>
              <span>敏感</span>
            </div>
            <div
              style="display: flex; flex-direction: column; margin-left: 13px"
            >
              <span
                style="width: 16px; height: 16px; background: #ffc2dd"
              ></span>
              <span
                style="width: 16px; height: 16px; background: #ff70af"
              ></span>
            </div>
            <div
              style="display: flex; flex-direction: column; margin-left: 22px"
            >
              <span
                style="width: 16px; height: 16px; background: #b0cbff"
              ></span>
              <span
                style="width: 16px; height: 16px; background: #6398ff"
              ></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 各环节负面数据占比 各环节非负面数据占比 -->
    <div class="InAnalysis-echarts">
      <div class="InAnalysis-echarts-div">
        <div class="InAnalysis-top-title">
          <img src="../../assets/img/views/topqq.png" />
          <span>各环节数据占比</span>
        </div>
        <div class="InAnalysis-echarts-grey">
          <div
            v-if="echarts3Data.length != 0"
            class="InAnalysis-echarts-grey-div"
            id="echarts3"
          ></div>
          <div class="analyse-container" v-else>
            <div style="margin-top: 50px" class="analyse-container-img">
              <img src="../../assets/img/views/analyse-img.png" />
              <p>各环节数据占总数据饼图</p>
            </div>
          </div>
          <div
            v-if="echarts3twoData.length > 1"
            class="InAnalysis-echarts-grey-div"
            id="echarts3two"
          ></div>
          <div class="analyse-container" v-if="echarts3twoData.length == 0">
            <div
              style="margin-top: 50px"
              class="analyse-container-img"
              v-if="echarts3twoData.length == 0"
            >
              <img src="../../assets/img/views/analyse-img.png" />
              <p>各环节预警数据占总预警数据饼图</p>
            </div>
          </div>
        </div>
      </div>
      <div class="InAnalysis-echarts-div">
        <div class="InAnalysis-top-title">
          <img src="../../assets/img/views/topqq.png" />
          <span>各媒体归属数据占比</span>
        </div>
        <div class="InAnalysis-echarts-grey">
          <div
            v-if="echarts4Data.length != 0"
            class="InAnalysis-echarts-grey-div"
            id="echarts4"
          ></div>
          <div class="analyse-container" v-if="echarts4Data.length == 0">
            <div style="margin-top: 50px" class="analyse-container-img">
              <img src="../../assets/img/views/analyse-img.png" />
              <p>各媒体归属数据占总数据饼图</p>
            </div>
          </div>
          <div
            v-if="echarts4twoData.length != 0"
            class="InAnalysis-echarts-grey-div"
            id="echarts4two"
          ></div>
          <div class="analyse-container" v-if="echarts4twoData.length == 0">
            <div style="margin-top: 50px" class="analyse-container-img">
              <img src="../../assets/img/views/analyse-img.png" />
              <p>各媒体归属预警数据占总预警数据饼图</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="InAnalysis-echarts">
      <div class="InAnalysis-echarts-div">
        <div class="InAnalysis-top-title">
          <img src="../../assets/img/views/topqq.png" />
          <span>各语种数据占比</span>
        </div>
        <div class="InAnalysis-echarts-grey">
          <div
            v-if="echarts5Data.length != 0"
            class="InAnalysis-echarts-grey-div"
            id="echarts5"
          ></div>
          <div class="analyse-container" v-if="echarts5Data.length == 0">
            <div style="margin-top: 50px" class="analyse-container-img">
              <img src="../../assets/img/views/analyse-img.png" />
              <p>负面各环节数据占总数据饼图</p>
            </div>
          </div>
          <div
            v-if="echarts5twoData.length != 0"
            class="InAnalysis-echarts-grey-div"
            id="echarts5two"
          ></div>
          <div class="analyse-container" v-if="echarts5twoData.length == 0">
            <div style="margin-top: 50px" class="analyse-container-img">
              <img src="../../assets/img/views/analyse-img.png" />
              <p>各环节负面预警数据占总预警数据饼图</p>
            </div>
          </div>
        </div>
      </div>
      <div class="InAnalysis-echarts-div">
        <div class="InAnalysis-top-title">
          <img src="../../assets/img/views/topqq.png" />
          <span>各媒体阵营数据占比</span>
        </div>
        <div class="InAnalysis-echarts-grey">
          <div
            v-if="echarts6Data.length != 0"
            class="InAnalysis-echarts-grey-div"
            id="echarts6"
          ></div>
          <div class="analyse-container" v-if="echarts6Data.length == 0">
            <div style="margin-top: 50px" class="analyse-container-img">
              <img src="../../assets/img/views/analyse-img.png" />
              <p>负面各环节数据占总数据饼图</p>
            </div>
          </div>
          <div
            v-if="echarts6twoData.length > 1"
            class="InAnalysis-echarts-grey-div"
            id="echarts6two"
          ></div>
          <div class="analyse-container" v-if="echarts6twoData.length == 0">
            <div style="margin-top: 50px" class="analyse-container-img">
              <img src="../../assets/img/views/analyse-img.png" />
              <p>各环节负面预警数据占总预警数据饼图</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import html2canvas from "html2canvas";
// import { jsPDF } from "jspdf"; //pdf插件
import { jwYuQTrend, jwAnalysisDataStatistics } from "../../api/analyse/index";
export default {
  props: {
    timetype: {
      //时间类型  近三天/近七天..
      type: Number,
    },
    timers: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      timeThird: [], // 时间范围数组
      picInfoArr: [], // 导出PDF的数组
      timersArr: [],
      organId: this.$store.state.user.organizationId,
      headerStyle: {
        background: "#F5F6FA",
        color: "#333333",
      },
      tab: [
        {
          name: "媒体类型",
          type: 0,
        },
        {
          name: "媒体归属",
          type: 1,
        },
        {
          name: "媒体阵营",
          type: 2,
        },
        {
          name: "语言类型",
          type: 3,
        },
      ],
      tableDatacom: [], //综合数据表
      slectAddTableArr: [], // 综合数据表新增的表头
      activeTab: 0,
      InAnalysisLink: ["新闻网站"],
      tableData: [
        {
          value1: "新闻网站",
          value2: "美",
          value3: "213",
          value4: "3213",
        },
        {
          value1: "新闻网站",
          value2: "日",
          value3: "1231",
          value4: "12312",
        },
        {
          value1: "新闻网站",
          value2: "英",
          value3: "1231",
          value4: "1231",
        },
        {
          value1: "新闻网站",
          value2: "其他",
          value3: "12312",
          value4: "1231",
        },
      ],
      echarts3Data: [],
      echarts3twoData: [],
      echarts4Data: [],
      echarts4twoData: [],
      echarts5Data: [],
      echarts5twoData: [],
      echarts6Data: [],
      echarts6twoData: [],
      // 综合数据统计表是否显示
      echarts1Data: false,
      mediaTypeList: [
        //自定义综合数据---媒体类型
        {
          mediaType: "新闻网站",
          yqNum: 0,
          yjNum: 0,
        },
        {
          mediaType: "社区论坛",
          yqNum: 0,
          yjNum: 0,
        },
        {
          mediaType: "社交媒体",
          yqNum: 0,
          yjNum: 0,
        },
        {
          mediaType: "机构官网",
          yqNum: 0,
          yjNum: 0,
        },
        {
          mediaType: "资讯平台",
          yqNum: 0,
          yjNum: 0,
        },
        {
          mediaType: "其他",
          yqNum: 0,
          yjNum: 0,
        },
        {
          mediaType: "合计",
          yqNum: 0,
          yjNum: 0,
        },
      ],
      // 自定义综合数据表---媒体归属
      mediaOwnershipList: [
        {
          mediaOwnership: "美国",
          yqNum: 0,
          yjNum: 0,
        },
        {
          mediaOwnership: "日本",
          yqNum: 0,
          yjNum: 0,
        },
        {
          mediaOwnership: "英国",
          yqNum: 0,
          yjNum: 0,
        },
        {
          mediaOwnership: "印度",
          yqNum: 0,
          yjNum: 0,
        },
        {
          mediaOwnership: "澳大利亚",
          yqNum: 0,
          yjNum: 0,
        },
        {
          mediaOwnership: "中东",
          yqNum: 0,
          yjNum: 0,
        },
        {
          mediaOwnership: "加拿大",
          yqNum: 0,
          yjNum: 0,
        },
        {
          mediaOwnership: "其他",
          yqNum: 0,
          yjNum: 0,
        },
        {
          mediaOwnership: "合计",
          yqNum: 0,
          yjNum: 0,
        },
      ],
      //自定义综合数据表---媒体阵营
      mediaCampList: [
        {
          mediaCamp: "邪教",
          yqNum: 0,
          yjNum: 0,
        },
        {
          mediaCamp: "台独",
          yqNum: 0,
          yjNum: 0,
        },
        {
          mediaCamp: "疆独",
          yqNum: 0,
          yjNum: 0,
        },
        {
          mediaCamp: "藏独",
          yqNum: 0,
          yjNum: 0,
        },
        {
          mediaCamp: "民主",
          yqNum: 0,
          yjNum: 0,
        },
        {
          mediaCamp: "港独",
          yqNum: 0,
          yjNum: 0,
        },
        {
          mediaCamp: "反华",
          yqNum: 0,
          yjNum: 0,
        },
        {
          mediaCamp: "其他",
          yqNum: 0,
          yjNum: 0,
        },
        {
          mediaCamp: "合计",
          yqNum: 0,
          yjNum: 0,
        },
      ],
      //自定义综合数据表---语言类型
      languageTypeList: [
        {
          languageType: "汉语",
          yqNum: 0,
          yjNum: 0,
        },
        {
          languageType: "英语",
          yqNum: 0,
          yjNum: 0,
        },
        {
          languageType: "藏语",
          yqNum: 0,
          yjNum: 0,
        },
        {
          languageType: "维语",
          yqNum: 0,
          yjNum: 0,
        },
        {
          languageType: "越南语",
          yqNum: 0,
          yjNum: 0,
        },
        {
          languageType: "土耳其语",
          yqNum: 0,
          yjNum: 0,
        },
        {
          languageType: "俄语",
          yqNum: 0,
          yjNum: 0,
        },
        {
          languageType: "马来西亚",
          yqNum: 0,
          yjNum: 0,
        },
        {
          languageType: "印度尼西亚语",
          yqNum: 0,
          yjNum: 0,
        },
        {
          languageType: "其他",
          yqNum: 0,
          yjNum: 0,
        },
        {
          languageType: "合计",
          yqNum: 0,
          yjNum: 0,
        },
      ],
    };
  },
  methods: {
    allFunction() {
      this.thirdData();
      this.jwAnalysisDataStatistics();
      this.echarts3();
      this.echarts4();
      this.echarts5();
      this.echarts6();
    },
    // 时间函数
    timersFn() {
      this.timersArr = this.timers;
    },
    xxxTableRowClassName({ rowIndex }) {
      if (rowIndex % 2 == 0) {
        return "statistics-warning-row";
      } else {
        return "";
      }
    },
    tabCard(index) {
      this.activeTab = index;
    },
    objectSpanMethod({ rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (rowIndex % 4 === 0) {
          return {
            rowspan: 4,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      }
    },

    async echarts1(value) {
      if (document.getElementById("echarts1")) {
        echarts.dispose(document.getElementById("echarts1"));
      }
      let yjTotal = [];
      let yqTotal = [];
      if (value.length > 0) {
        if (value[0].mediaType) {
          value.forEach((e) => {
            if (e.yjNum != 0) {
              yjTotal.push({
                value: e.yjNum,
                name: e.mediaType,
              });
            }
            if (e.yqNum != 0) {
              yqTotal.push({
                value: e.yqNum,
                name: e.mediaType,
              });
            }
          });
        } else if (value[0].mediaOwnership) {
          value.forEach((e) => {
            if (e.yjNum != 0) {
              yjTotal.push({
                value: e.yjNum,
                name: e.mediaOwnership,
              });
            }
            if (e.yqNum != 0) {
              yqTotal.push({
                value: e.yqNum,
                name: e.mediaOwnership,
              });
            }
          });
        } else if (value[0].mediaCamp) {
          value.forEach((e) => {
            if (e.yjNum != 0) {
              yjTotal.push({
                value: e.yjNum,
                name: e.mediaCamp,
              });
            }
            if (e.yqNum != 0) {
              yqTotal.push({
                value: e.yqNum,
                name: e.mediaCamp,
              });
            }
          });
        } else if (value[0].languageType) {
          value.forEach((e) => {
            if (e.yjNum != 0) {
              yjTotal.push({
                value: e.yjNum,
                name: e.languageType,
              });
            }
            if (e.yqNum != 0) {
              yqTotal.push({
                value: e.yqNum,
                name: e.languageType,
              });
            }
          });
        }
      }
      if (yjTotal.length != 0 || yqTotal.length != 0) {
        this.echarts1Data = true;
        setTimeout(() => {
          let myChart = echarts.init(document.getElementById("echarts1"));
          let option = {
            tooltip: {
              formatter: "{b}:\n{d}%",
            },
            title: [
              {
                text: "综合数据统计图",
                left: "50%",
                top: "97%",
                textAlign: "center",
                textStyle: {
                  color: "#999999",
                  fontSize: 12,
                  fontWeight: "normal",
                },
              },
            ],
            series: [
              {
                name: "Access From",
                type: "pie",
                selectedMode: "single",
                radius: ["0%", "40%"],
                label: {
                  position: "inner",
                  fontSize: 12,
                },

                data: yqTotal,
              },
              {
                name: "牛逼",
                type: "pie",
                radius: ["45%", "60%"],
                labelLine: {
                  length: 30,
                },
                label: {
                  formatter: "{b}:\n{d}%",
                },
                data: yjTotal,
              },
            ],
          };
          myChart.setOption(option);
          let picInfo;
          let _this = this;
          myChart.on("finished", function () {
            picInfo = myChart.getDataURL();
            _this.picInfoArr[0] = picInfo;
          });
        }, 500);
      } else {
        this.echarts1Data = false;
      }
    },
    // 判断时间 公共函数
    addObjtime(data) {
      data.startTimeStr = this.timers[0];
      data.endTimeStr = this.timers[1];
      return data;
    },
    // 表格 自调/tab 表格切换
    async jwAnalysisDataStatistics(type) {
      this.tableDatacom = [];
      let item = {
        organId: this.organId,
        analysisType: type ? type : 1,
        // 新加
        type: this.timetype,
      };
      let res = await jwAnalysisDataStatistics(this.addObjtime(item));
      if (!type || type == 1) {
        this.tableDatacom = JSON.parse(JSON.stringify(this.mediaTypeList));
      } else if (type == 2) {
        this.tableDatacom = JSON.parse(JSON.stringify(this.mediaOwnershipList));
      } else if (type == 3) {
        this.tableDatacom = JSON.parse(JSON.stringify(this.mediaCampList));
      } else {
        this.tableDatacom = JSON.parse(JSON.stringify(this.languageTypeList));
      }
      if (res.data.data && res.data.data.length != 0) {
        this.tableDatacom = this.$calcu.comprehensiveData(
          this.tableDatacom,
          res.data.data,
          2
        );
        this.echarts1(res.data.data);
      } else {
        if (document.getElementById("echarts1")) {
          echarts.dispose(document.getElementById("echarts1"));
        }
        this.echarts1Data = false;
      }
      this.slectAddTableArr = [];
    },
    // 点击标头 扩展它的数据
    async slectAddTable(item) {
      this.tableDatacom = [];
      let data = {
        organId: this.organId,
        analysisType: 1,
        // 新加
        type: this.timetype
      };
      data.startTimeStr = this.timers[0];
      data.endTimeStr = this.timers[1];
      if (item.mediaType) {
        // mediaOwnership
        this.tableDatacom = JSON.parse(JSON.stringify(this.mediaOwnershipList));
        data.analysisType = 2;
        data.mediaType = item.mediaType;
        this.slectAddTableArr.push({
          mediaType: item.mediaType,
          name: item.mediaType,
          keys: "mediaType",
        });
      } else if (item.mediaOwnership) {
        this.tableDatacom = JSON.parse(JSON.stringify(this.mediaCampList));
        // mediaCamp
        data.analysisType = 3;
        data.mediaOwnership = item.mediaOwnership;
        this.slectAddTableArr.push({
          mediaOwnership: item.mediaOwnership,
          name: item.mediaOwnership,
          keys: "mediaOwnership",
        });
      } else if (item.mediaCamp) {
        this.tableDatacom = JSON.parse(JSON.stringify(this.languageTypeList));
        // languageType
        data.analysisType = 4;
        data.mediaCamp = item.mediaCamp;
        this.slectAddTableArr.push({
          mediaCamp: item.mediaCamp,
          name: item.mediaCamp,
          keys: "mediaCamp",
        });
      } else if (item.languageType) {
        // mediaType
        this.tableDatacom = JSON.parse(JSON.stringify(this.mediaTypeList));
        data.analysisType = 1;
        data.languageType = item.languageType;
        this.slectAddTableArr.push({
          languageType: item.languageType,
          name: item.languageType,
          keys: "languageType",
        });
      }
      if (this.slectAddTableArr.length >= 1) {
        this.slectAddTableArr.forEach((item) => {
          data[item.keys] = item.name;
        });
      }
      let res = await jwAnalysisDataStatistics(data);
      if (res.data.data && res.data.data.length != 0) {
        this.tableDatacom = this.$calcu.comprehensiveData(
          this.tableDatacom,
          res.data.data,
          2
        );
        this.echarts1(res.data.data);
      }
    },
    // 点击标头 收起它的数据
    async slectremoveTable(item, index) {
      this.tableDatacom = [];
      let data = {
        organId: this.organId,
        analysisType: 1,
        // 新加
        type: this.timetype
      };
      data.startTimeStr = this.timers[0];
      data.endTimeStr = this.timers[1];
      if (item.activeTab == undefined) {
        //非第一个
        if (item.mediaType) {
          // mediaOwnership
          this.tableDatacom = JSON.parse(
            JSON.stringify(this.mediaOwnershipList)
          );
          data.mediaType = item.mediaType;
          data.analysisType = 2;
        } else if (item.mediaOwnership) {
          // mediaCamp
          this.tableDatacom = JSON.parse(JSON.stringify(this.mediaCampList));
          data.mediaOwnership = item.mediaOwnership;
          data.analysisType = 3;
        } else if (item.languageType) {
          // mediaType
          this.tableDatacom = JSON.parse(JSON.stringify(this.mediaTypeList));
          data.languageType = item.languageType;
          data.analysisType = 1;
        } else if (item.mediaCamp) {
          // languageType
          this.tableDatacom = JSON.parse(JSON.stringify(this.languageTypeList));
          data.mediaCamp = item.mediaCamp;
          data.analysisType = 4;
        }
      } else {
        if (item.activeTab == 0) {
          // mediaType
          this.tableDatacom = JSON.parse(JSON.stringify(this.mediaTypeList));
          data.analysisType = 1;
        } else if (item.activeTab == 1) {
          // mediaOwnership
          this.tableDatacom = JSON.parse(
            JSON.stringify(this.mediaOwnershipList)
          );
          data.analysisType = 2;
        } else if (item.activeTab == 2) {
          // mediaCamp
          this.tableDatacom = JSON.parse(JSON.stringify(this.mediaCampList));
          data.analysisType = 3;
        } else {
          // languageType
          this.tableDatacom = JSON.parse(JSON.stringify(this.languageTypeList));
          data.analysisType = 4;
        }
      }
      if (this.slectAddTableArr.length > 2) {
        this.slectAddTableArr.forEach((item, index) => {
          if (index != this.slectAddTableArr.length - 1) {
            data[item.keys] = item.name;
          }
        });
      }
      let res = await jwAnalysisDataStatistics(data);
      if (res.data.data && res.data.data.length != 0) {
        this.tableDatacom = this.$calcu.comprehensiveData(
          this.tableDatacom,
          res.data.data,
          2
        );
        this.echarts1(res.data.data);
      }
      this.slectAddTableArr.splice(index);
    },
    echarts2(timeThird) {
      if (document.getElementById("echarts2")) {
        echarts.dispose(document.getElementById("echarts2"));
      }
      let myChart = echarts.init(document.getElementById("echarts2"));
      let option = {
        grid: {
          left: 16,
          right: "1%",
          bottom: "15%",
          top: "25%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
        },
        legend: [
          {
            orient: "horizontal",
            right: "10%",
            align: "left",
            top: "-5",
            itemWidth: 27,
            itemHeight: 16,
            data: [
              {
                name: "整体走势",
              },
            ],
          },
          {
            right: "10%",
            align: "left",
            top: "25",
            itemWidth: 27,
            itemHeight: 16,
            data: [
              {
                name: "舆警走势",
              },
            ],
          },
        ],
        dataZoom: [
          {
            type: "inside",
          },
          {
            left: "5%",
            right: "5%",
          },
        ],
        xAxis: {
          type: "category",
          data: timeThird.map((e) => e.time),
          borderColor: "#D8D8D8",
          axisLine: {
            lineStyle: {
              color: "#D8D8D8",
            },
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: [
          {
            name: "总量",
            type: "value",
            axisLine: {
              lineStyle: {
                color: "#CCCCCC",
                fontSize: 12,
              },
            },
            splitLine: {
              lineStyle: {
                color: "#E5E5E5",
                type: "dashed",
              },
            },
            minInterval: 1, //将刻度的最小间距设置为1
            boundaryGap: ["0%", "50%"],
          },
          {
            name: "增量",
            type: "value",
            axisLine: {
              lineStyle: {
                color: "#CCCCCC",
                fontSize: 12,
              },
            },
            splitLine: {
              lineStyle: {
                color: "#E5E5E5",
                type: "dashed",
              },
            },
            minInterval: 1, //将刻度的最小间距设置为1
          },
        ],
        series: [
          {
            name: "整体走势",
            type: "line",
            symbol: "none",
            itemStyle: {
              color: "#FF5670",
            },
            yAxisIndex: 1,
            data: timeThird.map((e) => e.ztzs),
          },
          {
            name: "舆警走势",
            type: "line",
            symbol: "none",
            itemStyle: {
              color: "#34CAFF",
            },
            data: timeThird.map((e) => e.yjzs),
          },
          {
            name: "非预警敏感",
            type: "bar",
            symbol: "none",
            stack: "中",
            itemStyle: {
              color: "#6398FF",
            },
            barWidth: 6,
            data: timeThird.map((e) => e.fyjmgNum),
          },
          {
            name: "非预警非敏感",
            type: "bar",
            symbol: "none",
            stack: "中",
            itemStyle: {
              color: "#B0CBFF",
            },
            barWidth: 6,
            data: timeThird.map((e) => e.fyjfmgNum),
          },
          {
            name: "非敏感",
            type: "bar",
            symbol: "none",
            stack: "负",
            itemStyle: {
              color: "#FF70AF",
            },
            barWidth: 6,
            data: timeThird.map((v) => v.yjfmgNum),
          },
          {
            name: "敏感",
            type: "bar",
            symbol: "none",
            stack: "负",
            itemStyle: {
              color: "#FFC2DD",
            },
            barWidth: 6,
            data: timeThird.map((v) => v.yjmgNum),
          },
        ],
      };
      myChart.setOption(option);
    },
    echarts3tu1(source) {
      let myChart = echarts.init(document.getElementById("echarts3"));
      let option = {
        color: [
          "#92F5A8",
          "#FEBC3F",
          "#FFD88F",
          "#184CFF",
          "#6F90FF",
          "#0098FF",
          "#77C8FF",
          "#00D0A3",
        ],
        tooltip: {},
        title: [
          {
            text: "各环节数据占总数据饼图",
            left: "50%",
            top: "85%",
            textAlign: "center",
            textStyle: {
              color: "#999999",
              fontSize: 12,
              fontWeight: "normal",
            },
          },
        ],
        dataset: [
          {
            source: source,
          },
          {
            transform: {
              type: "filter",
              config: {
                dimension: "Price",
                value: 1,
              },
            },
          },
          {
            transform: {
              type: "filter",
              config: {
                dimension: "Price",
                value: 2,
              },
            },
          },
        ],
        label: {
          formatter: "{b}:\n{d}%",
        },
        series: [
          {
            name: "全部",
            type: "pie",
            center: ["50%", "45%"],
            radius: 80,
            minAngle: 10,
            datasetIndex: 1,
            itemStyle: {
              borderWidth: 1,
              borderColor: "#fff",
            },
          },
        ],
      };
      myChart.setOption(option);
      let picInfo;
      let _this = this;
      myChart.on("finished", function () {
        picInfo = myChart.getDataURL();
        _this.picInfoArr[2] = picInfo;
      });
    },
    //各环节负面数据占比
    async echarts3() {
      let data = {
        organId: this.organId,
        analysisType: 1,
        // 新加
        type: this.timetype,
      };
      let res = await jwAnalysisDataStatistics(this.addObjtime(data));
      let source = [["Product", "Sales", "Price"]];
      let sourceData = [["Product", "Sales", "Price"]];
      if (res.data.data) {
        res.data.data.forEach((v) => {
          // 舆情数据
          if (v.yqNum != 0) {
            source.push([v.mediaType, v.yqNum, 1]);
          }
          // 预警数据
          if (v.yjNum != 0) {
            sourceData.push([v.mediaType, v.yjNum, 2]);
          }
        });
        this.echarts3Data = source;
        this.echarts3twoData = sourceData;
        if (source.length > 1) {
          setTimeout(() => {
            this.echarts3tu1(source);
          }, 500);
        } else {
          if (document.getElementById("echarts3")) {
            echarts.dispose(document.getElementById("echarts3"));
          }
          this.echarts3Data = [];
        }
        if (this.echarts3twoData.length > 1) {
          setTimeout(() => {
            this.echarts3tu2(sourceData);
          }, 500);
        } else {
          if (document.getElementById("echarts3two")) {
            echarts.dispose(document.getElementById("echarts3two"));
          }
          this.echarts3twoData = [];
        }
      }
    },
    echarts3tu2(source) {
      let myChart = echarts.init(document.getElementById("echarts3two"));
      let option = {
        color: [
          "#92F5A8",
          "#FEBC3F",
          "#FFD88F",
          "#184CFF",
          "#6F90FF",
          "#0098FF",
          "#77C8FF",
          "#00D0A3",
        ],
        tooltip: {},
        title: [
          {
            text: "各环节预警数据占总预警数据饼图",
            left: "50%",
            top: "85%",
            textAlign: "center",
            textStyle: {
              color: "#999999",
              fontSize: 12,
              fontWeight: "normal",
            },
          },
        ],
        dataset: [
          {
            source: source,
          },
          {
            transform: {
              type: "filter",
              config: {
                dimension: "Price",
                value: 1,
              },
            },
          },
          {
            transform: {
              type: "filter",
              config: {
                dimension: "Price",
                value: 2,
              },
            },
          },
        ],
        label: {
          formatter: "{b}:\n{d}%",
        },
        series: [
          {
            name: "负面",
            type: "pie",
            center: ["50%", "45%"],
            radius: 80,
            minAngle: 10,
            datasetIndex: 2,
            itemStyle: {
              borderWidth: 1,
              borderColor: "#fff",
            },
          },
        ],
      };
      myChart.setOption(option);
      let picInfo;
      let _this = this;
      myChart.on("finished", function () {
        picInfo = myChart.getDataURL();
        _this.picInfoArr[3] = picInfo;
      });
    },
    echarts4tu1(source) {
      let myChart = echarts.init(document.getElementById("echarts4"));
      let option = {
        color: [
          "#92F5A8",
          "#FEBC3F",
          "#FFD88F",
          "#184CFF",
          "#6F90FF",
          "#0098FF",
          "#77C8FF",
          "#00D0A3",
        ],
        tooltip: {},
        title: [
          {
            text: "各媒体归属数据占总数据饼图",
            left: "50%",
            top: "85%",
            textAlign: "center",
            textStyle: {
              color: "#999999",
              fontSize: 12,
              fontWeight: "normal",
            },
          },
        ],
        dataset: [
          {
            source: source,
          },
          {
            transform: {
              type: "filter",
              config: {
                dimension: "Price",
                value: 1,
              },
            },
          },
          {
            transform: {
              type: "filter",
              config: {
                dimension: "Price",
                value: 2,
              },
            },
          },
        ],
        label: {
          formatter: "{b}:\n{d}%",
        },
        series: [
          {
            name: "全部",
            type: "pie",
            center: ["50%", "45%"],
            radius: 80,
            minAngle: 10,
            datasetIndex: 1,
            itemStyle: {
              borderWidth: 1,
              borderColor: "#fff",
            },
          },
        ],
      };
      myChart.setOption(option);
      let picInfo;
      let _this = this;
      myChart.on("finished", function () {
        picInfo = myChart.getDataURL();
        _this.picInfoArr[4] = picInfo;
      });
    },
    //各环节非负面数据占比
    async echarts4() {
      let data = {
        organId: this.organId,
        analysisType: 2,
        // 新加
        type: this.timetype,
      };
      let res = await jwAnalysisDataStatistics(this.addObjtime(data));
      let source = [["Product", "Sales", "Price"]];
      let sourceData = [["Product", "Sales", "Price"]];

      res.data.data.forEach((v) => {
        if (v.yqNum != 0) {
          source.push([v.mediaOwnership, v.yqNum, 1]);
        }
        if (v.yjNum != 0) {
          sourceData.push([v.mediaOwnership, v.yjNum, 2]);
        }
      });

      this.echarts4Data = source;
      this.echarts4twoData = sourceData;

      if (source.length > 1) {
        setTimeout(() => {
          this.echarts4tu1(source);
        }, 500);
      } else {
        if (document.getElementById("echarts4")) {
          echarts.dispose(document.getElementById("echarts4"));
        }
        this.echarts4Data = [];
      }
      if (sourceData.length > 1) {
        setTimeout(() => {
          this.echarts4tu2(sourceData);
        }, 500);
      } else {
        if (document.getElementById("echarts4two")) {
          echarts.dispose(document.getElementById("echarts4two"));
        }
        this.echarts4twoData = [];
      }
    },
    echarts4tu2(source) {
      let myChart = echarts.init(document.getElementById("echarts4two"));
      let option = {
        color: [
          "#92F5A8",
          "#FEBC3F",
          "#FFD88F",
          "#184CFF",
          "#6F90FF",
          "#0098FF",
          "#77C8FF",
          "#00D0A3",
        ],
        tooltip: {},
        title: [
          {
            text: "各媒体归属预警数据占总预警数据饼图",
            left: "50%",
            top: "85%",
            textAlign: "center",
            textStyle: {
              color: "#999999",
              fontSize: 12,
              fontWeight: "normal",
            },
          },
        ],
        dataset: [
          {
            source: source,
          },
          {
            transform: {
              type: "filter",
              config: {
                dimension: "Price",
                value: 1,
              },
            },
          },
          {
            transform: {
              type: "filter",
              config: {
                dimension: "Price",
                value: 2,
              },
            },
          },
        ],
        label: {
          formatter: "{b}:\n{d}%",
        },
        series: [
          {
            name: "负面",
            type: "pie",
            center: ["50%", "45%"],
            radius: 80,
            minAngle: 10,
            datasetIndex: 2,
            itemStyle: {
              borderWidth: 1,
              borderColor: "#fff",
            },
          },
        ],
      };
      myChart.setOption(option);
      let picInfo;
      let _this = this;
      myChart.on("finished", function () {
        picInfo = myChart.getDataURL();
        _this.picInfoArr[5] = picInfo;
      });
    },
    echarts5tu1(source) {
      let myChart = echarts.init(document.getElementById("echarts5"));
      let option = {
        color: [
          "#92F5A8",
          "#FEBC3F",
          "#FFD88F",
          "#184CFF",
          "#6F90FF",
          "#0098FF",
          "#77C8FF",
          "#00D0A3",
        ],
        tooltip: {},
        title: [
          {
            text: "负面各环节数据占总数据饼图",
            left: "50%",
            top: "85%",
            textAlign: "center",
            textStyle: {
              color: "#999999",
              fontSize: 12,
              fontWeight: "normal",
            },
          },
        ],
        dataset: [
          {
            source: source,
          },
          {
            transform: {
              type: "filter",
              config: {
                dimension: "Price",
                value: 1,
              },
            },
          },
          {
            transform: {
              type: "filter",
              config: {
                dimension: "Price",
                value: 2,
              },
            },
          },
        ],
        label: {
          formatter: "{b}:\n{d}%",
        },
        series: [
          {
            name: "全部",
            type: "pie",
            center: ["50%", "45%"],
            radius: 80,
            minAngle: 10,
            datasetIndex: 1,
            itemStyle: {
              borderWidth: 1,
              borderColor: "#fff",
            },
          },
        ],
      };
      myChart.setOption(option);
      let picInfo;
      let _this = this;
      myChart.on("finished", function () {
        picInfo = myChart.getDataURL();
        _this.picInfoArr[6] = picInfo;
      });
    },
    //各环节负面数据占比
    async echarts5() {
      let data = {
        organId: this.organId,
        analysisType: 4,
        // 新加
        type: this.timetype,
      };
      let res = await jwAnalysisDataStatistics(this.addObjtime(data));
      let source = [["Product", "Sales", "Price"]];
      let sourceData = [["Product", "Sales", "Price"]];
      res.data.data.forEach((v) => {
        if (v.yqNum != 0) {
          source.push([v.languageType, v.yqNum, 1]);
        }
        if (v.yjNum != 0) {
          sourceData.push([v.languageType, v.yjNum, 2]);
        }
      });
      this.echarts5Data = source;
      this.echarts5twoData = sourceData;
      if (source.length > 1) {
        setTimeout(() => {
          this.echarts5tu1(source);
        }, 500);
      } else {
        if (document.getElementById("echarts5")) {
          echarts.dispose(document.getElementById("echarts5"));
        }
        this.echarts5Data = [];
      }
      if (sourceData.length > 1) {
        setTimeout(() => {
          this.echarts5tu2(sourceData);
        }, 500);
      } else {
        if (document.getElementById("echarts5two")) {
          echarts.dispose(document.getElementById("echarts5two"));
        }
        this.echarts5twoData = [];
      }
    },
    echarts5tu2(source) {
      let myChart = echarts.init(document.getElementById("echarts5two"));
      let option = {
        color: [
          "#92F5A8",
          "#FEBC3F",
          "#FFD88F",
          "#184CFF",
          "#6F90FF",
          "#0098FF",
          "#77C8FF",
          "#00D0A3",
        ],
        tooltip: {},
        title: [
          {
            text: "各环节负面预警数据占总预警数据饼图",
            left: "50%",
            top: "85%",
            textAlign: "center",
            textStyle: {
              color: "#999999",
              fontSize: 12,
              fontWeight: "normal",
            },
          },
        ],
        dataset: [
          {
            source: source,
          },
          {
            transform: {
              type: "filter",
              config: {
                dimension: "Price",
                value: 1,
              },
            },
          },
          {
            transform: {
              type: "filter",
              config: {
                dimension: "Price",
                value: 2,
              },
            },
          },
        ],
        label: {
          formatter: "{b}:\n{d}%",
        },
        series: [
          {
            name: "负面",
            type: "pie",
            center: ["50%", "45%"],
            radius: 80,
            minAngle: 10,
            datasetIndex: 2,
            itemStyle: {
              borderWidth: 1,
              borderColor: "#fff",
            },
          },
        ],
      };
      myChart.setOption(option);
      let picInfo;
      let _this = this;
      myChart.on("finished", function () {
        picInfo = myChart.getDataURL();
        _this.picInfoArr[7] = picInfo;
      });
    },
    echarts6tu1(source) {
      let myChart = echarts.init(document.getElementById("echarts6"));
      let option = {
        color: [
          "#92F5A8",
          "#FEBC3F",
          "#FFD88F",
          "#184CFF",
          "#6F90FF",
          "#0098FF",
          "#77C8FF",
          "#00D0A3",
        ],
        tooltip: {},
        title: [
          {
            text: "负面各环节数据占总数据饼图",
            left: "50%",
            top: "85%",
            textAlign: "center",
            textStyle: {
              color: "#999999",
              fontSize: 12,
              fontWeight: "normal",
            },
          },
        ],
        dataset: [
          {
            source: source,
          },
          {
            transform: {
              type: "filter",
              config: {
                dimension: "Price",
                value: 1,
              },
            },
          },
          {
            transform: {
              type: "filter",
              config: {
                dimension: "Price",
                value: 2,
              },
            },
          },
        ],
        label: {
          formatter: "{b}:\n{d}%",
        },
        series: [
          {
            name: "全部",
            type: "pie",
            center: ["50%", "45%"],
            radius: 80,
            minAngle: 10,
            datasetIndex: 1,
            itemStyle: {
              borderWidth: 1,
              borderColor: "#fff",
            },
          },
        ],
      };
      myChart.setOption(option);
      let picInfo;
      let _this = this;
      myChart.on("finished", function () {
        picInfo = myChart.getDataURL();
        _this.picInfoArr[8] = picInfo;
      });
    },
    //各环节非负面数据占比
    async echarts6() {
      let data = {
        organId: this.organId,
        analysisType: 3,
        // 新加
        type: this.timetype,
      };
      let res = await jwAnalysisDataStatistics(this.addObjtime(data));
      let source = [["Product", "Sales", "Price"]];
      let sourceData = [["Product", "Sales", "Price"]];
      res.data.data.forEach((v) => {
        if (v.yqNum != 0) {
          source.push([v.mediaCamp, v.yqNum, 1]);
        }
        if (v.yjNum != 0) {
          sourceData.push([v.mediaCamp, v.yjNum, 2]);
        }
      });
      this.echarts6Data = source;
      this.echarts6twoData = sourceData;

      if (source.length > 1) {
        setTimeout(() => {
          this.echarts6tu1(source);
        }, 500);
      } else {
        if (document.getElementById("echarts6")) {
          echarts.dispose(document.getElementById("echarts6"));
        }
        this.echarts6Data = [];
      }
      if (sourceData.length > 1) {
        setTimeout(() => {
          this.echarts6tu2(sourceData);
        }, 500);
      } else {
        if (document.getElementById("echarts6two")) {
          echarts.dispose(document.getElementById("echarts6two"));
        }
        this.echarts6twoData = [];
      }
    },
    echarts6tu2(source) {
      let myChart = echarts.init(document.getElementById("echarts6two"));
      let option = {
        color: [
          "#92F5A8",
          "#FEBC3F",
          "#FFD88F",
          "#184CFF",
          "#6F90FF",
          "#0098FF",
          "#77C8FF",
          "#00D0A3",
        ],
        tooltip: {},
        title: [
          {
            text: "各环节负面预警数据占总预警数据饼图",
            left: "50%",
            top: "85%",
            textAlign: "center",
            textStyle: {
              color: "#999999",
              fontSize: 12,
              fontWeight: "normal",
            },
          },
        ],
        dataset: [
          {
            source: source,
          },
          {
            transform: {
              type: "filter",
              config: {
                dimension: "Price",
                value: 1,
              },
            },
          },
          {
            transform: {
              type: "filter",
              config: {
                dimension: "Price",
                value: 2,
              },
            },
          },
        ],
        label: {
          formatter: "{b}:\n{d}%",
        },
        series: [
          {
            name: "负面",
            type: "pie",
            center: ["50%", "45%"],
            radius: 80,
            minAngle: 10,
            datasetIndex: 2,
            itemStyle: {
              borderWidth: 1,
              borderColor: "#fff",
            },
          },
        ],
      };
      myChart.setOption(option);
      let picInfo;
      let _this = this;
      myChart.on("finished", function () {
        picInfo = myChart.getDataURL();
        _this.picInfoArr[9] = picInfo;
      });
    },
    // 把图片导出为PDF
    getPdf() {
      let obj = {
        url: "api/msgquery/analysisBySynthesis/generateOverseasReportStream",
        method: "post",
        data: {
          organizationId: this.$store.state.user.organizationId, //机构id
          organizationName: this.$store.state.user.mechanism, //机构名称
          startTime: this.timersArr[0], //开始时间
          endTime: this.timersArr[1], //结束时间
          reportType: "境外",
          reportName: "境外报告",
          // 综合数据统计表
          analysisJwDataStatisticsVo: {
            organId: this.organId,
            analysisType: 1,
            endTimeStr: this.timersArr[1],
            startTimeStr: this.timersArr[0],
          },
        },
        responseType: "blob",
      };
      if (this.picInfoArr[0]) {
        obj.data.viewPicture1 = this.picInfoArr[0];
      }
      if (this.picInfoArr[2]) {
        obj.data.viewPicture3 = this.picInfoArr[2];
      }
      if (this.picInfoArr[3]) {
        obj.data.viewPicture4 = this.picInfoArr[3];
      }
      if (this.picInfoArr[4]) {
        obj.data.viewPicture5 = this.picInfoArr[4];
      }
      if (this.picInfoArr[5]) {
        obj.data.viewPicture6 = this.picInfoArr[5];
      }
      if (this.picInfoArr[6]) {
        obj.data.viewPicture7 = this.picInfoArr[6];
      }
      if (this.picInfoArr[7]) {
        obj.data.viewPicture8 = this.picInfoArr[7];
      }
      if (this.picInfoArr[8]) {
        obj.data.viewPicture9 = this.picInfoArr[8];
      }
      if (this.picInfoArr[9]) {
        obj.data.viewPicture10 = this.picInfoArr[9];
      }
      let textName = "境外报告.docx";
      // 敏感信息走势
      this.$nextTick(() => {
        html2canvas(document.querySelector(".InAnalysis-trend")).then(
          (resolve) => {
            let imgUrl = resolve.toDataURL("image/png");
            obj.data.viewPicture2 = imgUrl;
            this.$parent.$parent.downloadFun(obj, textName);
          }
        );
      });
    },
    thirdData() {
      let timeEnd = this.timers[1].split(" ")[0];
      let timeStar = this.timers[0].split(" ")[0];
      let numDate = this.$calcu.getDays(timeEnd, timeStar);
      let data = new Date(timeEnd + " " + new Date().getHours() + ":00:00");
      this.timeThird = this.$calcu.getTimeSentiment(
        numDate,
        numDate + 1,
        2,
        data
      );
      this.sentmentTrend();
    },
    async sentmentTrend() {
      let dataList = []; //存放后台返回的数据
      let obj = {
        organId: this.organId,
        // 新加
        type: this.timetype,
      };
      let res = await jwYuQTrend(this.addObjtime(obj));
      if (res.data.data && res.data.data.length != 0) {
        dataList = res.data.data;
        this.timeThird = this.$calcu.getDateList(dataList, this.timeThird, "2");
        this.timeThird.forEach((item) => {
          item.yjzs = item.yjfmgNum + item.yjmgNum;
          item.ztzs =
            item.fyjfmgNum + item.fyjmgNum + item.yjfmgNum + item.yjmgNum;
        });
        this.timeThird = this.$calcu.getNumSentiment(this.timeThird, 2);
      }
      this.echarts2(this.timeThird);
    },
    clickOutput(data, warn) {
      let output = {
        languageType: null,
        mediaOwnership: null,
        mediaType: null,
        mediaCamp: null,
        warn: false,
        value: 0,
      };
      if (this.slectAddTableArr) {
        this.slectAddTableArr.forEach((x) => {
          switch (x.keys) {
            case "languageType":
              if (x.name != "合计") {
                output.languageType = x.name;
              }
              break;
            case "mediaOwnership":
              if (x.name != "合计") {
                output.mediaOwnership = x.name;
              }
              break;
            case "mediaType":
              if (x.name != "合计") {
                output.mediaType = x.name;
              }
              break;
            case "mediaCamp":
              if (x.name != "合计") {
                output.mediaCamp = x.name;
              }
              break;
          }
        });
      }
      for (let i in data) {
        if (i != "yjNum" && i != "yqNum" && data[i] != "合计") {
          output[i] = data[i];
        }
      }
      if (warn) {
        output.warn = true;
        output.value = data.yjNum;
      } else {
        output.value = data.yqNum;
      }
      let startTimeArr = this.timersArr[0].split(" ");
      let endTimeArr = this.timersArr[1].split(" ");
      output.startTimeStr = startTimeArr[0] + "T" + startTimeArr[1] + "+08:00";
      output.endTimeStr = endTimeArr[0] + "T" + endTimeArr[1] + "+08:00";
      if (output.value) {
        let routeUrl = this.$router.resolve({
          path: "/sensitiveMessage",
          query: {
            info: encodeURIComponent(JSON.stringify(output)),
          },
        });
        window.open(routeUrl.href, "_blank");
      }
    },
  },
  mounted() {
    this.allFunction();
  },
};
</script>

<style lang="scss" scoped>
.InAnalysis {
  margin-top: 16px;
}

.InAnalysis-top {
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
  padding: 24px;
}

.InAnalysis-title {
  width: 100%;
  height: 122px;
  background: #f6fafe;
  border-radius: 4px;
  padding: 24px;
}

.InAnalysis-title .p1 {
  font-size: 24px;
  font-weight: bold;
  color: #333333;
  text-align: center;
}

.InAnalysis-title .p2 {
  font-size: 18px;
  color: #666;
  text-align: center;
  margin-top: 16px;
  font-weight: normal;
}

.InAnalysis-statistical-table {
  width: 100%;
  margin-top: 24px;
}

.InAnalysis-top-title {
  display: flex;
  align-items: center;
}

.InAnalysis-top-title img {
  position: relative;
  top: 2px;
  margin-right: 5px;
}

.InAnalysis-top-title span {
  font-size: 16px;
  color: #333333;
  font-weight: bold;
}

.InAnalysis-tab {
  width: 100%;
  padding: 20px;
  display: flex;
  cursor: pointer;
  font-weight: bold;
}

.InAnalysis-tab-card {
  padding: 7px 19px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #e1e1e1;
  font-size: 16px;
  color: #333333;
  margin-right: 24px;
}

.InAnalysis-tab-card.active {
  background: #e9f4ff;
  border-radius: 4px;
  border-color: transparent;
  color: #2e59ec;
}

.InAnalysis-link {
  padding-left: 20px;
  font-size: 14px;
  color: #666666;
}

.InAnalysis-link-span {
  font-size: 14px;
  color: #2e59ec;
}

.InAnalysis-table {
  font-size: 14px;
  font-weight: bold;
  color: #333333;
  margin-top: 16px;
  margin-left: 20px;
  width: 840px;
  // height: 202px;
  // border: 1px solid #d8d8d8;
}

.InAnalysis-table-head {
  display: flex;
  height: 40px;
}

.InAnalysis-table-1-1 {
  text-align: center;
  line-height: 40px;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  width: 119px;
  height: 40px;
}

.InAnalysis-table-1-2 {
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  height: 40px;
  width: 300px;
  line-height: 40px;
  text-align: center;
}

.InAnalysis-table-1-3 {
  width: 120px;
  height: 160px;
  line-height: 160px;
  text-align: center;

  border-right: 2px solid #fff;
}

.InAnalysis-table-bgc {
  background: #f5f6fa;
}

.table-body-box {
  display: flex;
  flex-direction: column;
}

.InAnalysis-table-body {
  display: flex;
}

.InAnalysis-row {
  width: 100%;
  height: 42px;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  text-align: center;
  line-height: 40px;
  display: flex;
}

#echarts1 {
  width: 550px;
  height: 500px;
  margin-top: 16px;
}

.InAnalysis-trend {
  position: relative;
  width: 100%;
  height: 434px;
  background: #ffffff;
  border-radius: 4px;
  margin-top: 16px;
  padding: 24px;
}

#echarts2 {
  width: 100%;
  height: 360px;
  position: relative;
}

.InAnalysis-echarts {
  width: 100%;
  height: 490px;
  margin-top: 16px;
  padding: 24px;
  display: flex;
  background: #ffffff;
  border-radius: 4px;
}

.InAnalysis-echarts-div {
  width: 50%;
  height: 100%;
}

.analyse-container {
  width: 50%;
}

.analyse-container {
  background-color: #fff;
}

.analyse-container-img p {
  font-size: 14px;
  text-align: center;
  color: #999999;
}

.InAnalysis-echarts-grey {
  width: 760px;
  height: 380px;
  padding: 16px;
  background: #f5f6fa;
  border-radius: 4px;
  margin-top: 32px;
  margin-left: 20px;
  display: flex;
}

.InAnalysis-echarts-grey-div {
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-radius: 4px;
}

.InAnalysis-fenlei {
  width: 100%;
  /* height: 551px; */
  background: #ffffff;
  border-radius: 4px;
  padding: 24px;
  margin-top: 24px;
}

.InAnalysis-fenlei-div {
  width: 100%;
  display: flex;
}

.InAnalysis-fenlei-small {
  width: 50%;
  height: 100%;
}

.InAnalysis-fenlei-content {
  display: flex;
  justify-content: space-between;
}

.InAnalysis-fenlei-content-left {
  width: 49%;
}

.InAnalysis-fenlei-content-left-top {
  margin-top: 32px;
  display: flex;
  margin-bottom: 25px;
}

.InAnalysis-fenlei-content-left-top li {
  padding: 0 24px;
  height: 36px;
  border-radius: 4px;
  text-align: center;
  line-height: 36px;
  border-radius: 4px;
  border: 1px solid #e1e1e1;
  margin-right: 24px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.item-Li {
  background: #e9f4ff;
  color: #2e59ec;
}

.InAnalysis-fenlei-content-right {
  width: 49%;
  margin-top: 52px;
}

.echarts-legend {
  padding-right: 16px;
  justify-content: space-between;
  display: flex;
  position: absolute;
  right: 2.5%;
  top: 6%;
  width: 245px;
  height: 72px;
  border: 1px solid #e4e6ec;
}

.echarts-legend-line {
  right: -47%;
  top: 26%;
  position: relative;
  width: 1px;
  height: 40px;
  background: #e4e6ec;
}

.noYujing {
  font-size: 12px;
  color: #5a6474;
}

.table {
  width: 100%;
  border: 1px solid #d8d8d8;
}

.table th {
  height: 40px;
  background: #f5f6fa;
  text-align: center;
}

.table td {
  text-align: center;
}

.table tbody .tr {
  background: #f5f6fa;
}

.pointer {
  cursor: pointer;
}

.legend-top {
  margin-left: 42px;
}
.dataShowImg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.comprehensive {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
